import * as React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";

import {
  ClinicalIndication,
  clinicalIndicationName,
  radiotracerName,
  ScanType,
  scanTypeName,
  Radiotracer,
  ImagingModality,
} from "../../constants/enums";
import BootstrapIcon from "../BootstrapIcon";
import { scanLabel } from "../../utils/patientScanUtils";
import PatientTypeahead from "./PatientTypeahead";
import PatientScanSelector from "./PatientScanSelect";
import { useAuthContext } from "../../contexts/AuthContext";
import LoadPatientScanModal from "../LoadPatientScanModal";
import { PatientScan } from "../../api/patientScans";
import {
  calculateDiscrepancies,
  manualDiscrepancyCalculation,
} from "../../utils/researchDiscrepancyUtils";
import { useLogContext } from "../LogToast/LogContext";
import { useStudyParticipationContext } from "../../contexts/StudyParticipationContext";
import { useNavigate } from "react-router-dom";

function ReadOnlyLabel({ value }: { value: string }) {
  return <Form.Control plaintext readOnly tabIndex={-1} value={value} />;
}

interface PatientDataProps {
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

function PatientData({ editMode, setEditMode }: PatientDataProps) {
  const { log } = useLogContext();

  const { currentUserStudyParticipation } = useStudyParticipationContext();

  const { currentUser } = useAuthContext();

  const { currentPatient } = usePatientsContext();

  const { patientScans, currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const navigate = useNavigate();

  const { firstScanId, secondScanId } = currentPatientScan.metadata;

  const firstDiscrepancyScan =
    firstScanId === undefined
      ? undefined
      : patientScans.find((s) => s.id === firstScanId);
  const secondDiscrepancyScan =
    secondScanId === undefined
      ? undefined
      : patientScans.find((s) => s.id === secondScanId);

  const automaticDiscrepancyCalculationButton = (
    <Button
      disabled={!firstDiscrepancyScan || !secondDiscrepancyScan}
      className="w-100"
      variant="outline-primary"
      type="submit"
      onClick={(e) => {
        e.preventDefault();
        if (
          !currentPatientScan.id &&
          firstDiscrepancyScan &&
          secondDiscrepancyScan
        ) {
          calculateDiscrepancies(
            currentPatientScan,
            setCurrentPatientScan,
            firstDiscrepancyScan,
            secondDiscrepancyScan
          );
        }
        setEditMode(false);
        navigate("/home/imaging_findings/tnm_classification/summary");
      }}
    >
      Automatic discrepancy calculation
    </Button>
  );

  const manualDiscrepancyCalculationButton = (
    <Button
      disabled={
        !firstDiscrepancyScan ||
        !currentPatientScan.metadata.secondScanRadiotracer
      }
      className="w-100"
      variant="outline-secondary"
      onClick={(e) => {
        e.preventDefault();
        if (firstDiscrepancyScan) {
          manualDiscrepancyCalculation(
            currentPatientScan,
            setCurrentPatientScan,
            firstDiscrepancyScan,
            Radiotracer[
              currentPatientScan.metadata
                .secondScanRadiotracer as keyof typeof Radiotracer
            ],
            log
          );
          setEditMode(false);
        }
      }}
    >
      Manual discrepancy calculation
    </Button>
  );

  const editButton = (
    <Button
      className="float-end"
      variant="link"
      size="sm"
      onClick={() => {
        setEditMode(true);
      }}
    >
      <BootstrapIcon name="pencil" size={12} /> Edit
    </Button>
  );

  const [showLoadScanModal, setShowLoadScanModal] = React.useState(false);

  return (
    <>
      <LoadPatientScanModal
        showModal={showLoadScanModal}
        onModalHide={() => setShowLoadScanModal(false)}
      />
      <Form className="mt-4">
        <Row className="mb-3 d-flex align-items-center">
          <Col xs="9">
            <h5 style={{ marginBlockEnd: 0 }}>Study Information</h5>
          </Col>
          <Col xs="3">{!editMode && editButton}</Col>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} md={6} controlId="reader">
            <Form.Label className="mb-0">Reader</Form.Label>
            <ReadOnlyLabel
              value={`${currentUser.first_name} ${currentUser.last_name}`}
            />
          </Form.Group>
          {currentUserStudyParticipation && (
            <Form.Group as={Col} md={6} controlId="reader">
              <Form.Label className="mb-0">Group</Form.Label>
              <ReadOnlyLabel
                value={`Group ${currentUserStudyParticipation.group}`}
              />
            </Form.Group>
          )}
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} md={6} controlId="patientId">
            <Form.Label className="mb-0">Patient ID</Form.Label>
            {editMode && !currentUserStudyParticipation ? (
              <PatientTypeahead />
            ) : (
              <ReadOnlyLabel
                value={currentPatient.patient_id || "Not selected"}
              />
            )}
          </Form.Group>
          {currentUserStudyParticipation && (
            <Form.Group as={Col} md={6} controlId="reader">
              <Form.Label className="mb-0">Step</Form.Label>
              <ReadOnlyLabel
                value={`Step ${currentUserStudyParticipation.current_step}`}
              />
            </Form.Group>
          )}
        </Row>
        <Row className="mb-3">
          <Col>
            {editMode ? (
              <Button
                disabled={!currentPatient.id}
                className="w-100"
                variant="outline-secondary"
                onClick={(e) => {
                  setShowLoadScanModal(true);
                }}
              >
                Load existing scan
              </Button>
            ) : (
              <div style={{ height: 38 }}></div>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="9">
            <h5 style={{ marginBlockEnd: 0 }}>Scan Information</h5>
          </Col>
          <Col xs="3">{!editMode && editButton}</Col>
        </Row>
        <Row className="mb-4">
          <Form.Group as={Col} controlId="scanType">
            <Form.Label className="mb-0">Scan Type</Form.Label>
            {editMode && !currentUserStudyParticipation ? (
              <Form.Select
                disabled={
                  currentPatient.id === null ||
                  currentPatientScan.id !== null ||
                  (currentPatientScan.scan_type === ScanType.DISCREPANCIES &&
                    (firstDiscrepancyScan !== undefined ||
                      secondDiscrepancyScan !== undefined))
                }
                aira-label="Scan Type"
                value={currentPatientScan.scan_type.toString()}
                onChange={(e) => {
                  // Clean up metadata when the UI switch is used
                  setCurrentPatientScan({
                    ...currentPatientScan,
                    scan_type: e.target.value as ScanType,
                    metadata: {},
                  });
                }}
              >
                {Object.values(ScanType).map((scanType, index) => (
                  <option value={scanType} key={`ScanType-${index}`}>
                    {scanTypeName(scanType)}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <ReadOnlyLabel
                value={scanTypeName(currentPatientScan.scan_type)}
              />
            )}
          </Form.Group>
        </Row>
        {currentPatientScan.scan_type === ScanType.REGULAR && (
          <Row className="mb-4">
            <Form.Group as={Col} xl={{ span: 6 }} controlId="modality">
              <Form.Label className="mb-0">Modality</Form.Label>
              {editMode && !currentUserStudyParticipation ? (
                <Form.Select
                  disabled={!currentPatient.id}
                  aira-label="Imaging Modality"
                  value={currentPatientScan.modality || undefined}
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      modality: e.target.value as ImagingModality,
                    });
                  }}
                >
                  {Object.values(ImagingModality).map((imod, index) => (
                    <option value={imod} key={`ImagingModality-${index}`}>
                      {imod}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <ReadOnlyLabel
                  value={currentPatientScan.modality || "Not set"}
                />
              )}
            </Form.Group>
            <Form.Group as={Col} xl={{ span: 6 }} controlId="radiotracer">
              <Form.Label className="mb-0">Radiotracer</Form.Label>
              {editMode && !currentUserStudyParticipation ? (
                <Form.Select
                  disabled={!currentPatient.id}
                  aira-label="Radiotracer"
                  value={currentPatientScan.radiotracer || "Not set"}
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      radiotracer: e.target.value as Radiotracer,
                    });
                  }}
                >
                  {Object.values(Radiotracer).map((radiotracer, index) => (
                    <option value={radiotracer} key={`Radiotracer-${index}`}>
                      {radiotracerName(radiotracer)}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <ReadOnlyLabel
                  value={radiotracerName(
                    currentPatientScan.radiotracer || Radiotracer.PSMA
                  )}
                />
              )}
            </Form.Group>
          </Row>
        )}
        {currentPatientScan.scan_type === ScanType.DISCREPANCIES && (
          <>
            <Row className="mb-4">
              <Form.Group
                as={Col}
                lg={{ span: 12 }}
                className="mb-3"
                controlId="firstPatientScan"
              >
                <Form.Label className="mb-0">
                  {editMode && !currentUserStudyParticipation
                    ? "Select First Scan"
                    : "First Scan"}
                </Form.Label>
                {editMode && !currentUserStudyParticipation ? (
                  <PatientScanSelector
                    disabled={!currentPatient.id}
                    patientScans={patientScans}
                    value={(firstScanId || -1).toString()}
                    onChange={(e) => {
                      const newFirstScanId = parseInt(e.target.value);
                      const newFirstScan = patientScans.find(
                        (scan) => scan.id === newFirstScanId
                      );
                      if (
                        newFirstScanId !== -1 &&
                        newFirstScan !== undefined &&
                        newFirstScan.radiotracer !== null
                      ) {
                        const newFirstScanMetadata = newFirstScan.metadata;
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          metadata: {
                            ...currentPatientScan.metadata,
                            firstScanId: newFirstScanId,
                            firstScanRadiotracer:
                              newFirstScan.scan_type === ScanType.REGULAR
                                ? newFirstScan.radiotracer.toString()
                                : `[${newFirstScanMetadata.firstScanRadiotracer}-${newFirstScanMetadata.secondScanRadiotracer}]`,
                          },
                        });
                      } else {
                        const newMetadata = currentPatientScan.metadata;
                        delete newMetadata.firstScanId;
                        delete newMetadata.firstScanRadiotracer;
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          metadata: newMetadata,
                        });
                      }
                    }}
                    placeholder={"Select First Scan"}
                    scanLabel={scanLabel}
                  />
                ) : (
                  <ReadOnlyLabel
                    value={
                      firstDiscrepancyScan === undefined
                        ? "Not selected"
                        : scanLabel(firstDiscrepancyScan)
                    }
                  />
                )}
              </Form.Group>
              {(firstDiscrepancyScan === undefined ||
                firstDiscrepancyScan.scan_type === ScanType.REGULAR) && (
                <Form.Group
                  as={Col}
                  lg={{ span: 12 }}
                  className="mb-3"
                  controlId="patientScanId"
                >
                  <Form.Label className="mb-0">
                    {editMode && !currentUserStudyParticipation
                      ? "Select Second Scan"
                      : "Second Scan"}
                  </Form.Label>
                  {editMode && !currentUserStudyParticipation ? (
                    <PatientScanSelector
                      disabled={
                        currentPatient.id === null ||
                        firstDiscrepancyScan === undefined
                      }
                      patientScans={patientScans}
                      filter={(scan: PatientScan): boolean =>
                        scan.scan_type === ScanType.REGULAR
                      }
                      value={(secondScanId || -1).toString()}
                      onChange={(e) => {
                        const newSecondScanId = parseInt(e.target.value);
                        const newSecondScan = patientScans.find(
                          (scan) => scan.id === newSecondScanId
                        );
                        if (
                          newSecondScanId !== -1 &&
                          newSecondScan !== undefined &&
                          newSecondScan.radiotracer !== null
                        ) {
                          setCurrentPatientScan({
                            ...currentPatientScan,
                            metadata: {
                              ...currentPatientScan.metadata,
                              secondScanId: newSecondScanId,
                              secondScanRadiotracer:
                                newSecondScan.radiotracer.toString(),
                            },
                          });
                        } else {
                          const newMetadata = currentPatientScan.metadata;
                          delete newMetadata.secondScanId;
                          delete newMetadata.secondScanRadiotracer;
                          setCurrentPatientScan({
                            ...currentPatientScan,
                            metadata: newMetadata,
                          });
                        }
                      }}
                      placeholder={"Select Second Scan"}
                      scanLabel={scanLabel}
                    />
                  ) : (
                    <ReadOnlyLabel
                      value={
                        secondDiscrepancyScan === undefined
                          ? "Not selected"
                          : scanLabel(secondDiscrepancyScan)
                      }
                    />
                  )}
                </Form.Group>
              )}
              {firstDiscrepancyScan !== undefined &&
                firstDiscrepancyScan.scan_type === ScanType.DISCREPANCIES && (
                  <Form.Group
                    as={Col}
                    lg={{ span: 12 }}
                    className="mb-3"
                    controlId="discrepancyScanModality"
                  >
                    <Form.Label className="mb-0">
                      Second Scan Radiotracer
                    </Form.Label>
                    {/* Scan modality selector */}
                    {editMode && !currentUserStudyParticipation ? (
                      <Form.Select
                        disabled={!currentPatient.id}
                        aira-label="Radiotracer"
                        value={
                          currentPatientScan.metadata.secondScanRadiotracer ||
                          Radiotracer.PSMA
                        }
                        onChange={(e) => {
                          setCurrentPatientScan({
                            ...currentPatientScan,
                            metadata: {
                              ...currentPatientScan.metadata,
                              secondScanRadiotracer: radiotracerName(
                                e.target.value as Radiotracer
                              ),
                            },
                          });
                        }}
                      >
                        {Object.values(Radiotracer).map(
                          (radiotracer, index) => (
                            <option
                              value={radiotracer}
                              key={`Radiotracer-${index}`}
                            >
                              {radiotracerName(radiotracer)}
                            </option>
                          )
                        )}
                      </Form.Select>
                    ) : (
                      <ReadOnlyLabel
                        value={
                          currentPatientScan.metadata.secondScanRadiotracer ===
                          undefined
                            ? "Not selected"
                            : currentPatientScan.metadata.secondScanRadiotracer
                        }
                      />
                    )}
                  </Form.Group>
                )}
            </Row>
            {editMode && (
              <Row>
                <Col>
                  {firstDiscrepancyScan === undefined ||
                  firstDiscrepancyScan.scan_type === ScanType.REGULAR
                    ? automaticDiscrepancyCalculationButton
                    : manualDiscrepancyCalculationButton}
                </Col>
              </Row>
            )}
          </>
        )}
        {currentPatientScan.scan_type === ScanType.REGULAR && (
          <>
            <Row className="mb-3">
              <Col xs="9">
                <h5 style={{ marginBlockEnd: 0 }}>Clinical History</h5>
              </Col>
              <Col xs="3">{!editMode && editButton}</Col>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="clinicalIndication">
                <Form.Label className="mb-0">Clinical indication</Form.Label>
                {editMode && !currentUserStudyParticipation ? (
                  <Form.Select
                    // Disable for DISCHOMET study
                    disabled={true}
                    aria-label="Clinical indication"
                    value={currentPatientScan.clinical_indication.toString()}
                    onChange={(e) => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        clinical_indication: e.target
                          .value as ClinicalIndication,
                      });
                    }}
                  >
                    {Object.values(ClinicalIndication).map(
                      (clinicalIndication, index) => (
                        <option
                          value={clinicalIndication}
                          key={`ClinicalIndication-${index}`}
                        >
                          {clinicalIndicationName(clinicalIndication)}
                        </option>
                      )
                    )}
                  </Form.Select>
                ) : (
                  <ReadOnlyLabel
                    value={clinicalIndicationName(
                      currentPatientScan.clinical_indication
                    )}
                  />
                )}
              </Form.Group>
            </Row>
            <Row className="mb-3">
              {currentPatientScan.clinical_indication ===
                ClinicalIndication.RESTAGING && (
                <Form.Group as={Col} controlId="priorRadicalProstatectomy">
                  {editMode ? (
                    <Form.Check
                      disabled={!currentPatient.id}
                      checked={currentPatientScan.prostate_removed}
                      onChange={(e) => {
                        setCurrentPatientScan({
                          ...currentPatientScan,
                          prostate_removed: e.target.checked,
                        });
                      }}
                      label="Prior Radical Prostatectomy"
                      style={{
                        minHeight: "2.25rem",
                        lineHeight: 1.5,
                        paddingTop: "0.5rem",
                      }}
                    />
                  ) : (
                    <ReadOnlyLabel
                      value={`Prior Radical Prostatectomy: ${
                        currentPatientScan.prostate_removed ? "Yes" : "No"
                      }`}
                    />
                  )}
                </Form.Group>
              )}
            </Row>
          </>
        )}
        {currentPatientScan.scan_type === ScanType.REGULAR && editMode && (
          <Row className="mb-4">
            <Col>
              <Button
                className="w-100"
                variant="outline-primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setEditMode(false);
                }}
              >
                Save changes
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}

export default PatientData;
