import {
  BoneMarkerInfo,
  initialMarkerInfo,
  OtherMarkerInfo,
  PatientScan,
  PatientScanMarkerInfo,
  PelvicMarkerInfo,
  TumorMarkerInfo,
} from "../api/patientScans";
import { BONE_EQUVALENT_REGIONS } from "../constants/regionNames";

export const NEW_LESION_COLOR = "#fb6e6e";
export const DISAPPEARED_LESION_COLOR = "#c1e49a";
export const REMAINING_LESION_COLOR = "#6faedb";

// NB: Discrepancy is an element which
// DOES NOT exist in fhe FIRST SCAN
// but EXISTS in the SECOND SCAN
export function calculateDiscrepancies(
  firstScan: PatientScan,
  secondScan: PatientScan
): PatientScanMarkerInfo {
  // Find discrepancies in both scans
  const firstScanMarkerInfo = firstScan.marker_info;
  const secondScanMarkerInfo = secondScan.marker_info;

  return {
    prostate_tumor: findTumorDiscrepancies(
      firstScanMarkerInfo.prostate_tumor,
      secondScanMarkerInfo.prostate_tumor
    ),
    pelvic_lymph_node_metastases: findPelvicDiscrepancies(
      firstScanMarkerInfo.pelvic_lymph_node_metastases,
      secondScanMarkerInfo.pelvic_lymph_node_metastases
    ),
    bone_metastases: findBoneDiscrepancies(
      firstScanMarkerInfo.bone_metastases,
      secondScanMarkerInfo.bone_metastases
    ),
    other_organ_metastases: findOtherDiscrepancies(
      firstScanMarkerInfo.other_organ_metastases,
      secondScanMarkerInfo.other_organ_metastases
    ),
  };
}

function markTumorDiscrepancy(
  tumorDiscrepancies: TumorMarkerInfo,
  sourceTumorMarkerInfo: TumorMarkerInfo,
  regionName: string,
  color: string
) {
  // Mark the region as remained
  const markerKey = Object.keys(sourceTumorMarkerInfo.markers).find((key) =>
    key.startsWith(regionName)
  );
  tumorDiscrepancies.markers[markerKey!] = {
    ...sourceTumorMarkerInfo.markers[markerKey!],
    color,
  };

  tumorDiscrepancies.marker_count_by_region[regionName] =
    tumorDiscrepancies.marker_count_by_region.hasOwnProperty(regionName)
      ? tumorDiscrepancies.marker_count_by_region[regionName] + 1
      : 1;
}

function findTumorDiscrepancies(
  firstTumorMarkerInfo: TumorMarkerInfo,
  secondTumorMarkerInfo: TumorMarkerInfo
): TumorMarkerInfo {
  const tumorDiscrepancies: TumorMarkerInfo =
    initialMarkerInfo().prostate_tumor;

  for (let regionName in firstTumorMarkerInfo.marker_count_by_region) {
    if (
      secondTumorMarkerInfo.marker_count_by_region.hasOwnProperty(regionName)
    ) {
      // Mark the region as remained
      markTumorDiscrepancy(
        tumorDiscrepancies,
        firstTumorMarkerInfo,
        regionName,
        REMAINING_LESION_COLOR
      );
    } else {
      // Mark the region as disappeared
      markTumorDiscrepancy(
        tumorDiscrepancies,
        firstTumorMarkerInfo,
        regionName,
        DISAPPEARED_LESION_COLOR
      );
    }
  }

  for (let regionName in secondTumorMarkerInfo.marker_count_by_region) {
    if (
      !firstTumorMarkerInfo.marker_count_by_region.hasOwnProperty(regionName)
    ) {
      // Mark the region as new
      markTumorDiscrepancy(
        tumorDiscrepancies,
        secondTumorMarkerInfo,
        regionName,
        NEW_LESION_COLOR
      );
    }
  }

  return tumorDiscrepancies;
}

function markPelvicDiscrepancy(
  pelvicDiscrepancies: PelvicMarkerInfo,
  sourcePelvicMarkerInfo: PelvicMarkerInfo,
  regionName: string,
  color: string
) {
  const markerKey = Object.keys(sourcePelvicMarkerInfo.markers).find((key) =>
    key.startsWith(regionName)
  );
  pelvicDiscrepancies.markers[markerKey!] = {
    ...sourcePelvicMarkerInfo.markers[markerKey!],
    color,
  };
  pelvicDiscrepancies.marker_count_by_region[regionName] =
    pelvicDiscrepancies.marker_count_by_region.hasOwnProperty(regionName)
      ? pelvicDiscrepancies.marker_count_by_region[regionName] + 1
      : 1;
}

function findPelvicDiscrepancies(
  firstPelvicMarkerInfo: PelvicMarkerInfo,
  secondPelvicMarkerInfo: PelvicMarkerInfo
): PelvicMarkerInfo {
  const pelvicDiscrepancies: PelvicMarkerInfo =
    initialMarkerInfo().pelvic_lymph_node_metastases;

  for (let regionName in firstPelvicMarkerInfo.marker_count_by_region) {
    if (
      secondPelvicMarkerInfo.marker_count_by_region.hasOwnProperty(regionName)
    ) {
      // Mark tumor as remained
      // Mark the region as remained
      markPelvicDiscrepancy(
        pelvicDiscrepancies,
        firstPelvicMarkerInfo,
        regionName,
        REMAINING_LESION_COLOR
      );
    } else {
      // Mark the region as disappeared
      markPelvicDiscrepancy(
        pelvicDiscrepancies,
        firstPelvicMarkerInfo,
        regionName,
        DISAPPEARED_LESION_COLOR
      );
    }
  }

  for (let regionName in secondPelvicMarkerInfo.marker_count_by_region) {
    if (
      !firstPelvicMarkerInfo.marker_count_by_region.hasOwnProperty(regionName)
    ) {
      // Mark the region as new
      markPelvicDiscrepancy(
        pelvicDiscrepancies,
        secondPelvicMarkerInfo,
        regionName,
        NEW_LESION_COLOR
      );
    }
  }
  return pelvicDiscrepancies;
}

function markOtherOrganDiscrepancies(
  otherDiscrepancies: OtherMarkerInfo,
  sourceOtherMarkerInfo: OtherMarkerInfo,
  regionName: string,
  color: string
) {
  const markerKey = Object.keys(sourceOtherMarkerInfo.markers).find((key) =>
    key.startsWith(regionName)
  );
  otherDiscrepancies.markers[markerKey!] = {
    ...sourceOtherMarkerInfo.markers[markerKey!],
    color,
  };
  otherDiscrepancies.marker_count_by_organ[regionName] =
    otherDiscrepancies.marker_count_by_organ.hasOwnProperty(regionName)
      ? otherDiscrepancies.marker_count_by_organ[regionName] + 1
      : 1;
}

function markOtherLymphNodeDiscrepancies(
  otherDiscrepancies: OtherMarkerInfo,
  sourceOtherMarkerInfo: OtherMarkerInfo,
  regionName: string,
  color: string
) {
  const markerKey = Object.keys(sourceOtherMarkerInfo.markers).find((key) =>
    key.startsWith(regionName)
  );
  otherDiscrepancies.markers[markerKey!] = {
    ...sourceOtherMarkerInfo.markers[markerKey!],
    color,
  };
  otherDiscrepancies.marker_count_by_lymph_node[regionName] =
    otherDiscrepancies.marker_count_by_lymph_node.hasOwnProperty(regionName)
      ? otherDiscrepancies.marker_count_by_lymph_node[regionName] + 1
      : 1;
}

function findOtherDiscrepancies(
  firstOtherMarkerInfo: OtherMarkerInfo,
  secondOtherMarkerInfo: OtherMarkerInfo
): OtherMarkerInfo {
  const otherDiscrepancies: OtherMarkerInfo =
    initialMarkerInfo().other_organ_metastases;
  // We need to repeat the same loop twice: for other lymph nodes and other organs
  for (let regionName in firstOtherMarkerInfo.marker_count_by_lymph_node) {
    if (
      secondOtherMarkerInfo.marker_count_by_lymph_node.hasOwnProperty(
        regionName
      )
    ) {
      markOtherLymphNodeDiscrepancies(
        otherDiscrepancies,
        firstOtherMarkerInfo,
        regionName,
        REMAINING_LESION_COLOR
      );
    } else {
      markOtherLymphNodeDiscrepancies(
        otherDiscrepancies,
        firstOtherMarkerInfo,
        regionName,
        DISAPPEARED_LESION_COLOR
      );
    }
  }

  for (let regionName in secondOtherMarkerInfo.marker_count_by_lymph_node) {
    if (
      !firstOtherMarkerInfo.marker_count_by_lymph_node.hasOwnProperty(
        regionName
      )
    ) {
      markOtherLymphNodeDiscrepancies(
        otherDiscrepancies,
        secondOtherMarkerInfo,
        regionName,
        NEW_LESION_COLOR
      );
    }
  }

  for (let regionName in firstOtherMarkerInfo.marker_count_by_organ) {
    if (
      secondOtherMarkerInfo.marker_count_by_organ.hasOwnProperty(regionName)
    ) {
      markOtherOrganDiscrepancies(
        otherDiscrepancies,
        firstOtherMarkerInfo,
        regionName,
        REMAINING_LESION_COLOR
      );
    } else {
      markOtherOrganDiscrepancies(
        otherDiscrepancies,
        firstOtherMarkerInfo,
        regionName,
        DISAPPEARED_LESION_COLOR
      );
    }
  }

  for (let regionName in secondOtherMarkerInfo.marker_count_by_organ) {
    if (
      !firstOtherMarkerInfo.marker_count_by_organ.hasOwnProperty(regionName)
    ) {
      markOtherOrganDiscrepancies(
        otherDiscrepancies,
        secondOtherMarkerInfo,
        regionName,
        NEW_LESION_COLOR
      );
    }
  }

  return otherDiscrepancies;
}

function markBoneDiscrepancies(
  boneDiscrepancies: BoneMarkerInfo,
  sourceBoneMarkerInfo: BoneMarkerInfo,
  regionName: string,
  color: string
) {
  const markerKey = Object.keys(sourceBoneMarkerInfo.markers).find((key) =>
    key.startsWith(regionName)
  );
  boneDiscrepancies.markers[markerKey!] = {
    ...sourceBoneMarkerInfo.markers[markerKey!],
    color,
  };
  boneDiscrepancies.marker_count_by_region[regionName] =
    boneDiscrepancies.marker_count_by_region.hasOwnProperty(regionName)
      ? boneDiscrepancies.marker_count_by_region[regionName] + 1
      : 1;
  boneDiscrepancies.marker_count += 1;
}

function hasEquivalentRegion(
  boneMarkerInfo: BoneMarkerInfo,
  regionName: string
) {
  const equivalentRegions = BONE_EQUVALENT_REGIONS.find(
    (elem) => elem.indexOf(regionName) !== -1
  ) || [regionName];
  for (let equivalentRegionName of equivalentRegions) {
    if (
      boneMarkerInfo.marker_count_by_region.hasOwnProperty(equivalentRegionName)
    ) {
      return true;
    }
  }
  return false;
}

function findBoneDiscrepancies(
  firstBoneMarkerInfo: BoneMarkerInfo,
  secondBoneMarkerInfo: BoneMarkerInfo
): BoneMarkerInfo {
  // Handle DMI difference
  const boneDiscrepancies = initialMarkerInfo().bone_metastases;
  if (
    (firstBoneMarkerInfo.is_dmi && !secondBoneMarkerInfo.is_dmi) ||
    (!firstBoneMarkerInfo.is_dmi && secondBoneMarkerInfo.is_dmi)
  ) {
    boneDiscrepancies.is_dmi = true;
    return boneDiscrepancies;
  }

  for (let regionName in firstBoneMarkerInfo.marker_count_by_region) {
    if (hasEquivalentRegion(secondBoneMarkerInfo, regionName)) {
      // Mark as remaining
      markBoneDiscrepancies(
        boneDiscrepancies,
        firstBoneMarkerInfo,
        regionName,
        REMAINING_LESION_COLOR
      );
    } else {
      // Mark as disappeared
      markBoneDiscrepancies(
        boneDiscrepancies,
        firstBoneMarkerInfo,
        regionName,
        DISAPPEARED_LESION_COLOR
      );
    }
  }

  for (let regionName in secondBoneMarkerInfo.marker_count_by_region) {
    if (!hasEquivalentRegion(firstBoneMarkerInfo, regionName)) {
      // Mark as new
      markBoneDiscrepancies(
        boneDiscrepancies,
        secondBoneMarkerInfo,
        regionName,
        NEW_LESION_COLOR
      );
    }
  }

  return boneDiscrepancies;
}
