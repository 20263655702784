import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";

export interface PatientTreatment {
  id: number | null;
  patient_id: number;
  treatment_name: string;
  started_at: DateTime;
  ended_at: DateTime | null;
  created_at: DateTime | null;
  updated_at: DateTime | null;
}

export interface PatientTreatmentOption {
  label: string;
  category: "Chemotherapy" | "Hormonal Therapy" | "Other" | "Custom";
}

export const PatientTreatmentOptions: PatientTreatmentOption[] = [
  { label: "Docetaxel", category: "Chemotherapy" },
  { label: "Cabazitaxel", category: "Chemotherapy" },
  { label: "ADT", category: "Hormonal Therapy" },
  { label: "Abiraterone", category: "Hormonal Therapy" },
  { label: "Enzalutamide", category: "Hormonal Therapy" },
  { label: "Apalalutamide", category: "Hormonal Therapy" },
  { label: "Darolutamide", category: "Hormonal Therapy" },
  { label: "Radical Prostatectomy", category: "Other" },
  { label: "Radiation Therapy", category: "Other" },
];

export function initialPatientTreatment(
  patient_id: number,
  treatment_name: string,
  started_at: DateTime
): PatientTreatment {
  return {
    id: null,
    patient_id,
    treatment_name,
    started_at,
    ended_at: null,
    created_at: null,
    updated_at: null,
  };
}

export function serializeToPatientTreatment(
  jsonResponse: any
): PatientTreatment {
  return {
    ...initialPatientTreatment(0, "", DateTime.now()),
    ...jsonResponse,
    started_at: parseDate(jsonResponse.started_at),
    ended_at: parseDate(jsonResponse.ended_at),
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serializeFromPatientTreatment(pt: PatientTreatment): string {
  return JSON.stringify({
    ...pt,
    started_at: pt.started_at?.toISO(),
    ended_at: pt.ended_at?.toISO(),
    created_at: pt.created_at?.toISO(),
    updated_at: pt.updated_at?.toISO(),
  });
}

export async function createPatientTreatment(
  accessToken: string,
  patientId: number,
  pt: PatientTreatment
) {
  const response = await fetch(`/api/patients/${patientId}/treatments`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromPatientTreatment(pt),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function updatePatientTreatment(
  accessToken: string,
  patientId: number,
  patientTreatmentId: number,
  pt: PatientTreatment
) {
  const response = await fetch(
    `/api/patients/${patientId}/treatments/${patientTreatmentId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: serializeFromPatientTreatment(pt),
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }
}

export async function deletePatientTreatment(
  accessToken: string,
  patientId: number,
  patientTreatmentId: number
) {
  const response = await fetch(
    `/api/patients/${patientId}/treatments/${patientTreatmentId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function getTreatmentsForPatient(
  accessToken: string,
  patientId: number
): Promise<PatientTreatment[]> {
  const response = await fetch(`/api/patients/${patientId}/treatments`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatientTreatment);
}
