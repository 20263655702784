import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { TestResult } from "../../api/testResults";
import { DateTime } from "luxon";
import BootstrapIcon from "../BootstrapIcon";

interface TestResultRowProps {
  testResult: TestResult;
  showTestName: boolean;
  highlighted?: boolean;
  onDeleteTestResult: () => void;
  onEditTestResult: () => void;
}

export default function TestResultRow({
  testResult,
  showTestName,
  highlighted,
  onDeleteTestResult,
  onEditTestResult,
}: TestResultRowProps): React.ReactElement {
  let buttonGroupOffest = 0;
  if (highlighted == null || !highlighted) {
    buttonGroupOffest = showTestName ? 1 : 2;
  }
  return (
    <Row>
      {showTestName && (
        <Form.Group as={Col} xs="3">
          <Form.Control
            plaintext
            readOnly
            tabIndex={-1}
            value={testResult.test_name}
          />
        </Form.Group>
      )}
      <Form.Group as={Col} xs={showTestName ? "3" : "6"}>
        <Form.Control
          plaintext
          readOnly
          tabIndex={-1}
          value={testResult.value || ""}
        />
      </Form.Group>
      <Form.Group as={Col} xs={2}>
        <Form.Control
          plaintext
          readOnly
          value={testResult.taken_at.toLocaleString(DateTime.DATE_SHORT)}
        />
      </Form.Group>
      {highlighted && (
        <Form.Group as={Col} xs={{ span: 2 }}>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-highlight">
                This test is relevant to the current patient scan
              </Tooltip>
            }
          >
            <span style={{ marginTop: 7, color: "#ffc107" }}>
              <BootstrapIcon name="star-fill" size={25} />
            </span>
          </OverlayTrigger>
        </Form.Group>
      )}
      <Form.Group as={Col} xs={{ span: 2, offset: buttonGroupOffest }}>
        <ButtonGroup size="sm" style={{ marginTop: 2 }}>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-edit">Edit test result</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                onEditTestResult();
              }}
            >
              <BootstrapIcon name="pencil" size={16} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-delete">Delete test result</Tooltip>}
          >
            <Button
              variant="outline-secondary"
              onClick={() => {
                if (
                  window.confirm("Are you sure? This action cannot be undone!")
                ) {
                  onDeleteTestResult();
                }
              }}
            >
              <BootstrapIcon name="trash3" size={16} />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Form.Group>
    </Row>
  );
}
