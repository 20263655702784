import * as React from "react";
import { GlobalHotKeys } from "react-hotkeys";

import Button from "react-bootstrap/Button";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { useStudyParticipationContext } from "../../contexts/StudyParticipationContext";
import { nextStep as nextStudyStep } from "../../utils/researchAutomatedWorkflowUtils";
import { useOrgContext } from "../../contexts/OrgContext";
import { useNavigate } from "react-router-dom";

interface SaveButtonProps {
  style?: React.CSSProperties;
  label: string;
  disabled?: boolean;
  beforeSave?: () => void;
}

export default function SaveButton({
  style,
  label,
  disabled = false,
  beforeSave = undefined,
}: SaveButtonProps): React.ReactElement {
  const { getAccessToken } = useAuthContext();

  const { currentOrg } = useOrgContext();

  const {
    currentPatient,
    changesSaved: patientChangesSaved,
    savePatient,
  } = usePatientsContext();

  const {
    currentPatientScan,
    changesSaved: patientScanChangesSaved,
    savePatientScan,
  } = usePatientScansContext();

  const {
    currentUserStudyParticipation,
    setCurrentUserStudyParticipation,
    setShowFeedbackModal,
  } = useStudyParticipationContext();

  async function onClick() {
    if (currentPatient.id == null) {
      return;
    }

    if (beforeSave !== undefined) {
      beforeSave();
    }

    const accessToken = await getAccessToken();
    // setCurrentSectionKey(0);
    await savePatient(currentPatient);
    await savePatientScan(currentPatient.id, currentPatientScan);

    // Move on to the next study step for the research product
    if (currentOrg && currentOrg.product === "research") {
      // We SHOULD NOT increase the current step if the user is editing an existing patient scan
      // This can be detected by checking for the current patient scan ID
      if (!currentPatientScan.id) {
        await nextStudyStep(
          accessToken,
          currentPatientScan,
          currentUserStudyParticipation,
          setCurrentUserStudyParticipation,
          () => {
            setShowFeedbackModal(true);
          }
        );
      } else {
        console.log(
          "Editing an existing patient scan - redirecting to the current step instead"
        );
        setCurrentUserStudyParticipation({ ...currentUserStudyParticipation! });
        return;
      }
    }
  }

  return (
    <GlobalHotKeys
      handlers={{
        NEXT_PATIENT: onClick,
      }}
      allowChanges={true}
    >
      <Button
        style={style || {}}
        disabled={disabled}
        variant="outline-primary"
        onClick={onClick}
      >
        {label}
      </Button>
    </GlobalHotKeys>
  );
}
