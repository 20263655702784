import { BoneMarkerInfo } from "../../../api/patientScans";
import { shortRegionName } from "../../../constants/regionNames";
import { handleOrientation } from "../../../utils/regionNameUtils";

export default function getBoneTNMCode(
  markerInfo: BoneMarkerInfo
): [string, string] {
  const { marker_count, marker_count_by_region, is_dmi } = markerInfo;

  if (is_dmi) {
    return ["M1b(dmi)", "M1b(dmi)"];
  }

  const affectedBones = Object.keys(marker_count_by_region).map(
    shortRegionName
  );

  const bonesTNMCode =
    affectedBones.length > 0 ? `(${handleOrientation(affectedBones)})` : "";

  if (marker_count === 0) {
    return ["", ""];
  } else if (marker_count === 1) {
    return ["M1b(uni)", `M1b(uni) ${bonesTNMCode}`];
  } else if (marker_count <= 3) {
    return ["M1b(oligo)", `M1b(oligo) ${bonesTNMCode}`];
  } else {
    return ["M1b(diss)", `M1b(diss) ${bonesTNMCode}`];
  }
}
