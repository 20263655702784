import * as React from "react";
import { Row, Col } from "react-bootstrap";

import MiTNMHeader from "../MiTNMHeader";
import { Outlet } from "react-router-dom";
import ClinicSidebar from "../Sidebar/ClinicSidebar";
import ContentFooter from "../ContentFooter";

function HomePageClinicLayout() {
  return (
    <Row className="px-3" style={{ height: "100vh" }}>
      <Col lg="3" className="Sidebar d-flex flex-column">
        <MiTNMHeader />
        <ClinicSidebar />
      </Col>
      <Col lg="9" className="Content">
        <Outlet />
        <ContentFooter />
      </Col>
    </Row>
  );
}

export default HomePageClinicLayout;
