import * as React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import BoneMetastasesImage from "./BoneMetastasesImage";
import BoneMetastasesDMIImage from "./BoneMetastasesDMIImage";
import { useRegionOverlay } from "../../RegionOverlay";
import regionName from "../../../constants/regionNames";
import findAffectedAreaNode from "../../../utils/svgUtils";
import {
  BoneMarkerInfo,
  Coordinates,
  EllipseCoordinates,
} from "../../../api/patientScans";
import useSUVmaxOverlay from "../../SUVmaxOverlay/useSUVmaxOverlay";

export interface BoneMetastasesProps {
  markerInfo: BoneMarkerInfo;
  updateBoneMarkers: (markers: BoneMarkerInfo) => void;
  markerColor: string;
}

function BoneMetastases({
  markerInfo,
  updateBoneMarkers,
  markerColor,
}: BoneMetastasesProps) {
  const ref = React.useRef<SVGSVGElement>(null);
  const [svgPoint, setSvgPoint] = React.useState<SVGPoint>();

  const { markers, marker_count_by_region, marker_count, is_dmi } = markerInfo;

  const {
    overlay: regionOverlay,
    setShowOverlay,
    updateOverlayOnMouseMove,
  } = useRegionOverlay(ref, regionName);

  const updateMarkers = (newMarkers: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) => {
    updateBoneMarkers({
      ...markerInfo,
      markers: newMarkers as { [key: string]: Coordinates },
    });
  };

  const { overlay: suvMaxOverlay, onContextMenu } = useSUVmaxOverlay({
    parentRef: ref,
    markers,
    updateMarkers,
  });

  // Set the SVG point after the initial render
  React.useEffect(() => {
    if (ref.current) {
      setSvgPoint(ref.current.createSVGPoint());
    }
  }, []);

  const onBoneClick = (event: React.MouseEvent<SVGSVGElement>) => {
    const [found, boneId] = findAffectedAreaNode(4, event.target);

    if (!found || !svgPoint) {
      event.preventDefault();
      return;
    }

    // Convert screen coords to SVG coords
    svgPoint.x = event.clientX;
    svgPoint.y = event.clientY;
    const metastasisCentre = svgPoint.matrixTransform(
      ref.current!.getScreenCTM()!.inverse()
    );

    const newMetastases = structuredClone(markers);
    const newMetastasesCountByNode = structuredClone(marker_count_by_region);

    newMetastases[`${boneId}*${Date.now()}`] = {
      x: metastasisCentre.x,
      y: metastasisCentre.y,
    };

    newMetastasesCountByNode[boneId] = newMetastasesCountByNode.hasOwnProperty(
      boneId
    )
      ? newMetastasesCountByNode[boneId] + 1
      : 1;

    updateBoneMarkers({
      ...markerInfo,
      markers: newMetastases,
      marker_count_by_region: newMetastasesCountByNode,
      marker_count: marker_count + 1,
    });
    // log("Selected " + regionName(boneId));
  };

  const onMetastasisClick = (event: React.MouseEvent<SVGCircleElement>) => {
    const metastasisId = event.currentTarget.id;
    const boneId = event.currentTarget.id.split("*")[0];

    const newMetastases = structuredClone(markers);
    const newMetastasesCountByNode = structuredClone(marker_count_by_region);

    delete newMetastases[metastasisId];

    if (newMetastasesCountByNode[boneId] === 1) {
      delete newMetastasesCountByNode[boneId];
    } else {
      newMetastasesCountByNode[boneId] -= 1;
    }

    updateBoneMarkers({
      ...markerInfo,
      markers: newMetastases,
      marker_count_by_region: newMetastasesCountByNode,
      marker_count: marker_count - 1,
    });
    // log("Deselected " + regionName(boneId));
  };

  return (
    <Card border="light">
      <div className="card-img-top">
        {regionOverlay}
        {suvMaxOverlay}
        {is_dmi ? (
          <BoneMetastasesDMIImage ref={ref} />
        ) : (
          <BoneMetastasesImage
            ref={ref}
            metastases={markers}
            onMetastasisClick={onMetastasisClick}
            onBoneClick={onBoneClick}
            onContextMenu={onContextMenu}
            onPointerMove={(event: React.PointerEvent) => {
              const [showOverlay, targetId] = findAffectedAreaNode(
                4,
                event.target
              );
              setShowOverlay(showOverlay);
              if (showOverlay) {
                updateOverlayOnMouseMove(event, targetId);
              }
            }}
            onPointerLeave={() => {
              setShowOverlay(false);
            }}
            markerColor={markerColor}
          />
        )}
      </div>
      <Card.Text as="div">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row
            className="align-items-center gx-1 mx-3"
            style={{ minHeight: 40 }}
          >
            <Col xs="auto" className="me-auto">
              <Form.Check
                type="checkbox"
                label="Diffuse bone marrow involvement"
                checked={is_dmi}
                onChange={() => {
                  updateBoneMarkers({
                    ...markerInfo,
                    is_dmi: !is_dmi,
                  });
                }}
              />
            </Col>
          </Row>
        </Form>
      </Card.Text>
    </Card>
  );
}

export default BoneMetastases;
