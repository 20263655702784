import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";

export interface TestResult {
  id: number | null;
  patient_id: number | null;
  patient_scan_id: number | null;
  test_name: string;
  value: number | null;
  taken_at: DateTime;
  created_at?: DateTime | null;
  updated_at?: DateTime | null;
}

export function initialTestResult(
  test_name: string,
  value: number,
  taken_at: DateTime
) {
  return {
    id: null,
    patient_id: null,
    patient_scan_id: null,
    test_name,
    value,
    taken_at,
    created_at: null,
    updated_at: null,
  };
}

export function serializeToTestResult(jsonResponse: any): TestResult {
  return {
    ...initialTestResult("", 0, DateTime.now()),
    ...jsonResponse,
    taken_at: parseDate(jsonResponse.taken_at) || DateTime.now(),
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serializeFromTestResult(tr: TestResult): string {
  return JSON.stringify({
    ...tr,
    taken_at: tr.taken_at.toISO(),
    created_at: tr.created_at?.toISO(),
    updated_at: tr.updated_at?.toISO(),
  });
}

export async function createTestResult(
  accessToken: string,
  patientId: number,
  tr: TestResult
) {
  const response = await fetch(`/api/patients/${patientId}/test_results`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromTestResult(tr),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function updateTestResult(
  accessToken: string,
  patientId: number,
  testResultId: number,
  tr: TestResult
) {
  const response = await fetch(
    `/api/patients/${patientId}/test_results/${testResultId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: serializeFromTestResult(tr),
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }
}

export async function deleteTestResult(
  accessToken: string,
  patientId: number,
  testResultId: number
) {
  const response = await fetch(
    `/api/patients/${patientId}/test_results/${testResultId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }
}

export async function getTestResultsForPatient(
  accessToken: string,
  patientId: number
): Promise<TestResult[]> {
  const response = await fetch(`/api/patients/${patientId}/test_results`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToTestResult);
}
