import * as React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { Container } from "react-bootstrap";

import MiTNM from "../MiTNM";
import LogToast from "../LogToast";

import { useOrgContext } from "../../contexts/OrgContext";

import "./MiTNMTransitions.css";
import HomePageClinicLayout from "./HomePageClinicLayout";
import HomePageResearchLayout from "./HomePageResearchLayout";

const KEY_MAP = {
  PREVIOUS_STEP: {
    name: "Previous image",
    sequence: "left",
  },
  NEXT_STEP: {
    name: "Next image",
    sequence: "right",
  },
  LOCAL_LESIONS_STEP: {
    name: "Local Lesions image",
    sequence: "f1",
  },
  PELVIC_LN_STEP: {
    name: "Pelvic and Inguinal Lymph Nodes image",
    sequence: "f2",
  },
  OSSEOUS_METASTASES_STEP: {
    name: "Osseous Metastases image",
    sequence: "f3",
  },
  DISTANT_LN_AND_ORGANS_STEP: {
    name: "Distant Lymph Nodes and Organs image",
    sequence: "f4",
  },
  REVIEW_STEP: {
    name: "Summary",
    sequences: ["f5", "r"],
  },
  COPY_CODELINE: {
    name: "Copy TNM Codeline",
    sequence: "m",
  },
  COPY_IMAGE: {
    name: "Copy image",
    sequence: "i",
  },
  COPY_ONCOLOGICAL_FINDINGS: {
    name: "Copy oncological findings",
    sequence: "o",
  },
  GET_PDF_REPORT: {
    name: "Get PDF report",
    sequence: "p",
  },
  NEXT_PATIENT: {
    name: "Next step",
    sequence: "n",
  },
  NEW_TUMOR_LABEL: {
    name: "New tumor label (Local Lesions image)",
    sequence: "l",
  },
};

function HomePage() {
  const { currentOrg } = useOrgContext();

  let layout = <></>;
  if (currentOrg) {
    switch (currentOrg.product) {
      case "basic":
        layout = <MiTNM />;
        break;
      case "clinic":
        layout = <HomePageClinicLayout />;
        break;
      case "research":
        layout = <HomePageResearchLayout />;
        break;
      default:
        layout = <></>;
    }
  }

  return (
    <GlobalHotKeys keyMap={KEY_MAP} allowChanges={true}>
      <Container fluid style={{ flexDirection: "column" }}>
        <LogToast />
        {layout}
      </Container>
    </GlobalHotKeys>
  );
}

export default HomePage;
