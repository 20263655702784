export class AuthError extends Error {
  constructor(message, details) {
    super(message);
    this.details = details;
  }
}

export class APIError extends Error {}

export class JWTVerificationError extends Error {}
