import { Nav } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  MiTNMSectionName,
  MiTNMSections,
  MiTNMSectionsArray,
} from "../../constants/enums";

interface MiTNMNavProps {
  baseURL: string;
}

export default function MiTNMNav({ baseURL }: MiTNMNavProps) {
  const { mitnmSection } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Nav
        variant="pills"
        activeKey={mitnmSection || MiTNMSections.PROSTATE_TUMOR}
        onSelect={(sectionKey) => {
          navigate(`${baseURL}/${sectionKey}`);
        }}
        className="justify-content-center d-none d-md-flex"
      >
        {MiTNMSectionsArray.map((section) => (
          <Nav.Item key={`nav-${section}`}>
            <Nav.Link eventKey={section} className="px-2 ms-1">
              {MiTNMSectionName(section)}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Nav
        variant="pills"
        activeKey={mitnmSection || MiTNMSections.PROSTATE_TUMOR}
        onSelect={(sectionKey) => {
          navigate(`${baseURL}/${sectionKey}`);
        }}
        className="justify-content-center d-md-none"
      >
        {
          <Nav.Item key={`nav-${mitnmSection || MiTNMSections.PROSTATE_TUMOR}`}>
            <Nav.Link
              eventKey={mitnmSection || MiTNMSections.PROSTATE_TUMOR}
              className="px-2"
            >
              {MiTNMSectionName(
                (mitnmSection as MiTNMSections) || MiTNMSections.PROSTATE_TUMOR
              )}
            </Nav.Link>
          </Nav.Item>
        }
      </Nav>
    </>
  );
}
