export const PROSTATE_STAGING_REGION_NAMES_SHORT = new Map(
  Object.entries({
    "Apex-TRI": "RA",
    "Apex-TLI": "LA",
    "Apex-BRI": "RA",
    "Apex-BLI": "LA",
    "Apex-TRM": "RA-CI",
    "Apex-TLM": "LA-CI",
    "Apex-BRM": "RA-CI",
    "Apex-BLM": "LA-CI",
    "Apex-TRO": "RA-EPE",
    "Apex-TLO": "LA-EPE",
    "Apex-BRO": "RA-EPE",
    "Apex-BLO": "LA-EPE",
    "Mid-TRI": "RM",
    "Mid-TLI": "LM",
    "Mid-BRI": "RM",
    "Mid-BLI": "LM",
    "Mid-TRM": "RM-CI",
    "Mid-TLM": "LM-CI",
    "Mid-BRM": "RM-CI",
    "Mid-BLM": "LM-CI",
    "Mid-TRO": "RM-EPE",
    "Mid-TLO": "LM-EPE",
    "Mid-BRO": "RM-EPE",
    "Mid-BLO": "LM-EPE",
    "Base-TRI": "RB",
    "Base-TLI": "LB",
    "Base-BRI": "RB",
    "Base-BLI": "LB",
    "Base-TRM": "RB-CI",
    "Base-TLM": "LB-CI",
    "Base-BRM": "RB-CI",
    "Base-BLM": "LB-CI",
    "Base-TRO": "RB-EPE",
    "Base-TLO": "LB-EPE",
    "Base-BRO": "RB-EPE",
    "Base-BLO": "LB-EPE",
    EPE: "EPE",
    Bladder: "Bladder",
    RSV: "RSV",
    LSV: "LSV",
  })
);

export const PROSTATE_STAGING_TNM_REGIONS = [
  "RA",
  "LA",
  "RA-CI",
  "LA-CI",
  "RA-EPE",
  "LA-EPE",
  "RM",
  "LM",
  "RM-CI",
  "LM-CI",
  "RM-EPE",
  "LM-EPE",
  "RB",
  "LB",
  "RB-CI",
  "LB-CI",
  "RB-EPE",
  "LB-EPE",
  "EPE",
  "Bladder",
  "RSV",
  "LSV",
];

export const PROSTATE_STAGING_REGION_NAMES_LONG = new Map(
  Object.entries({
    "Apex-TRI": "Right Apex",
    "Apex-TLI": "Left Apex",
    "Apex-BRI": "Right Apex",
    "Apex-BLI": "Left Apex",
    "Apex-TRM": "Right Apex (capsular invasion)",
    "Apex-TLM": "Left Apex (capsular invasion)",
    "Apex-BRM": "Right Apex (capsular invasion)",
    "Apex-BLM": "Left Apex (capsular invasion)",
    "Apex-TRO": "Right Apex (extraprostatic extension)",
    "Apex-TLO": "Left Apex (extraprostatic extension)",
    "Apex-BRO": "Right Apex (extraprostatic extension)",
    "Apex-BLO": "Left Apex (extraprostatic extension)",
    "Mid-TRI": "Right Mid-Gland",
    "Mid-TLI": "Left Mid-Gland",
    "Mid-BRI": "Right Mid-Gland",
    "Mid-BLI": "Left Mid-Gland",
    "Mid-TRM": "Right Mid-Gland (capsular invasion)",
    "Mid-TLM": "Left Mid-Gland (capsular invasion)",
    "Mid-BRM": "Right Mid-Gland (capsular invasion)",
    "Mid-BLM": "Left Mid-Gland (capsular invasion)",
    "Mid-TRO": "Right Mid-Gland (extraprostatic extension)",
    "Mid-TLO": "Left Mid-Gland (extraprostatic extension)",
    "Mid-BRO": "Right Mid-Gland (extraprostatic extension)",
    "Mid-BLO": "Left Mid-Gland (extraprostatic extension)",
    "Base-TRI": "Right Base",
    "Base-TLI": "Left Base",
    "Base-BRI": "Right Base",
    "Base-BLI": "Left Base",
    "Base-TRM": "Right Base (capsular invasion)",
    "Base-TLM": "Left Base (capsular invasion)",
    "Base-BRM": "Right Base (capsular invasion)",
    "Base-BLM": "Left Base (capsular invasion)",
    "Base-TRO": "Right Base (extraprostatic extension)",
    "Base-TLO": "Left Base (extraprostatic extension)",
    "Base-BRO": "Right Base (extraprostatic extension)",
    "Base-BLO": "Left Base (extraprostatic extension)",
    EPE: "Extraprostatic Extension",
    Bladder: "Bladder",
    RSV: "Right Seminal Vesicule",
    LSV: "Left Seminal Vesicule",
  })
);

export const PROSTATE_STAGING_EQUIVALENT_REGIONS = [
  ["Apex-TRI", "Apex-BRI"],
  ["Apex-TLI", "Apex-BLI"],
  ["Apex-TRM", "Apex-BRM"],
  ["Apex-TLM", "Apex-BLM"],
  ["Apex-TRO", "Apex-BRO"],
  ["Apex-TLO", "Apex-BLO"],
  ["Mid-TRI", "Mid-BRI"],
  ["Mid-TLI", "Mid-BLI"],
  ["Mid-TRM", "Mid-BRM"],
  ["Mid-TLM", "Mid-BLM"],
  ["Mid-TRO", "Mid-BRO"],
  ["Mid-TLO", "Mid-BLO"],
  ["Base-TRI", "Base-BRI"],
  ["Base-TLI", "Base-BLI"],
  ["Base-TRM", "Base-BRM"],
  ["Base-TLM", "Base-BLM"],
  ["Base-TRO", "Base-BRO"],
  ["Base-TLO", "Base-BLO"],
];

export const PROSTATE_RESTAGING_REGION_NAMES_SHORT = new Map(
  Object.entries({
    RSV: "RSV",
    LSV: "LSV",
    Prostate: "Prostate",
    "Prostate-Capsule": "Prostate Capsule",
    ES: "ES",
    "PFM-Left": "PFM (L)",
    "PFM-Right": "PFM (R)",
    Rectum: "Rectum",
  })
);

export const PROSTATE_RESTAGING_TNM_REGIONS = [
  "RSV",
  "LSV",
  "Prostate",
  "Prostate Capsule",
  "ES",
  "PFM (L)",
  "PFM (R)",
  "Rectum",
];

export const PROSTATE_RESTAGING_REGION_NAMES_LONG = new Map(
  Object.entries({
    RSV: "Right Seminal Vesicule",
    LSV: "Left Seminal Vesicule",
    Prostate: "Prostate",
    "Prostate-Capsule": "Prostate Capsule",
    ES: "External Sphincter",
    "PFM-Left": "Pelvic Floor Muscles (Left)",
    "PFM-Right": "Pelvic Floor Muscles (Right)",
    Rectum: "Rectum",
  })
);

export const PROSTATE_REMOVED_REGION_NAMES_SHORT = new Map(
  Object.entries({
    "SVB-Left": "SVB (L)",
    "SVB-Right": "SVB (R)",
    RV: "RV",
    VTA: "VTA",
    Rectum: "Rectum",
  })
);

export const PROSTATE_REMOVED_TNM_REGIONS = [
  "SVB (L)",
  "SVB (R)",
  "RV",
  "VTA",
  "Rectum",
];

export const PROSTATE_REMOVED_REGION_NAMES_LONG = new Map(
  Object.entries({
    "SVB-Left": "Seminal Vesicle Bed (Left)",
    "SVB-Right": "Seminal Vesicle Bed (Right)",
    RV: "Retrovesical Space/Tissue",
    VTA: "Vesicourethral Anastomosis",
    Rectum: "Rectum",
  })
);

export const SHORT_PELVIC_LYMPH_NODE_NAMES = new Map(
  Object.entries({
    EIR: "EIR",
    EIL: "EIL",
    IIR: "IIR",
    IIL: "IIL",
    PS: "PS",
    OBR: "OBR",
    OBL: "OBL",
  })
);

export const PELVIC_LYMPH_NODES_TNM_REGIONS = [
  "EIR",
  "EIL",
  "IIR",
  "IIL",
  "PS",
  "OBR",
  "OBL",
];

export const PELVIC_LYMPH_NODE_NAMES = new Map(
  Object.entries({
    EIR: "Right External Iliac",
    EIL: "Left External Iliac",
    IIR: "Right Internal Iliac",
    IIL: "Left Internal Iliac",
    PS: "Presacral",
    OBR: "Right Obturator",
    OBL: "Left Obturator",
  })
);

export const BONE_NAMES = new Map(
  Object.entries({
    Skull: "Skull",
    "Skull-Front": "Skull",
    "Skull-Back": "Skull",
    "Clavicula-Right": "Clavicula (R)",
    "Clavicula-Left": "Clavicula (L)",
    "Scapula-Right": "Scapula (R)",
    "Scapula-Left": "Scapula (L)",
    "Humerus-Right": "Humerus (R)",
    "Humerus-Left": "Humerus (L)",
    "Ilium-Right": "Ilium (R)",
    "Ilium-Left": "Ilium (L)",
    "Iscium-Right": "Iscium (R)",
    "Iscium-Left": "Iscium (L)",
    "Pubic-Bone-Right": "Pubic (R)",
    "Pubic-Bone-Left": "Pubic (L)",
    "Femur-Right": "Femur (R)",
    "Femur-Left": "Femur (L)",
    "Clavicula-Right-Front": "Clavicula (R)",
    "Clavicula-Left-Front": "Clavicula (L)",
    "Humerus-Right-Front": "Humerus (R)",
    "Humerus-Left-Front": "Humerus (L)",
    "Ilium-Right-Front": "Ilium (R)",
    "Ilium-Left-Front": "Ilium (L)",
    "Iscium-Right-Front": "Iscium (R)",
    "Iscium-Left-Front": "Iscium (L)",
    "Pubic-Bone-Right-Front": "Pubic (R)",
    "Pubic-Bone-Left-Front": "Pubic (L)",
    "Femur-Right-Front": "Femur (R)",
    "Femur-Left-Front": "Femur (L)",
    "Scapula-Right-Back": "Scapula (R)",
    "Scapula-Left-Back": "Scapula (L)",
    "Humerus-Right-Back": "Humerus (R)",
    "Humerus-Left-Back": "Humerus (L)",
    "Ilium-Right-Back": "Ilium (R)",
    "Ilium-Left-Back": "Ilium (L)",
    "Iscium-Right-Back": "Iscium (R)",
    "Iscium-Left-Back": "Iscium (L)",
    "Pubic-Bone-Right-Back": "Pubic (R)",
    "Pubic-Bone-Left-Back": "Pubic (L)",
    "Femur-Right-Back": "Femur (R)",
    "Femur-Left-Back": "Femur (L)",
    Sternum: "Sternum",
    "R1-Right": "R1 (R)",
    "R1-Left": "R1 (L)",
    "R2-Right": "R2 (R)",
    "R2-Left": "R2 (L)",
    "R3-Right": "R3 (R)",
    "R3-Left": "R3 (L)",
    "R4-Right": "R4 (R)",
    "R4-Left": "R4 (L)",
    "R5-Right": "R5 (R)",
    "R5-Left": "R5 (L)",
    "R6-Right": "R6 (R)",
    "R6-Left": "R6 (L)",
    "R7-Right": "R7 (R)",
    "R7-Left": "R7 (L)",
    "R8-Right": "R8 (R)",
    "R8-Left": "R8 (L)",
    "R9-Right": "R9 (R)",
    "R9-Left": "R9 (L)",
    "R10-Right": "R10 (R)",
    "R10-Left": "R10 (L)",
    "R11-Right": "R11 (R)",
    "R11-Left": "R11 (L)",
    "R12-Right": "R12 (R)",
    "R12-Left": "R12 (L)",
    "R1-Right-Front": "R1 (R)",
    "R1-Left-Front": "R1 (L)",
    "R2-Right-Front": "R2 (R)",
    "R2-Left-Front": "R2 (L)",
    "R3-Right-Front": "R3 (R)",
    "R3-Left-Front": "R3 (L)",
    "R4-Right-Front": "R4 (R)",
    "R4-Left-Front": "R4 (L)",
    "R5-Right-Front": "R5 (R)",
    "R5-Left-Front": "R5 (L)",
    "R6-Right-Front": "R6 (R)",
    "R6-Left-Front": "R6 (L)",
    "R7-Right-Front": "R7 (R)",
    "R7-Left-Front": "R7 (L)",
    "R8-Right-Front": "R8 (R)",
    "R8-Left-Front": "R8 (L)",
    "R9-Right-Front": "R9 (R)",
    "R9-Left-Front": "R9 (L)",
    "R10-Right-Front": "R10 (R)",
    "R10-Left-Front": "R10 (L)",
    "R11-Right-Front": "R11 (R)",
    "R11-Left-Front": "R11 (L)",
    "R12-Right-Front": "R12 (R)",
    "R12-Left-Front": "R12 (L)",
    "R8-Right-Back": "R8 (R)",
    "R8-Left-Back": "R8 (L)",
    "R9-Right-Back": "R9 (R)",
    "R9-Left-Back": "R9 (L)",
    "R10-Right-Back": "R10 (R)",
    "R10-Left-Back": "R10 (L)",
    "R11-Right-Back": "R11 (R)",
    "R11-Left-Back": "R11 (L)",
    "R12-Right-Back": "R12 (R)",
    "R12-Left-Back": "R12 (L)",
    C1: "C1",
    C2: "C2",
    C3: "C3",
    C4: "C4",
    C5: "C5",
    C6: "C6",
    C7: "C7",
    T1: "T1",
    T2: "T2",
    T3: "T3",
    T4: "T4",
    T5: "T5",
    T6: "T6",
    T7: "T7",
    T8: "T8",
    T9: "T9",
    T10: "T10",
    T11: "T11",
    T12: "T12",
    L1: "L1",
    L2: "L2",
    L3: "L3",
    L4: "L4",
    L5: "L5",
    Sacrum: "Sacrum",
  })
);

export const BONE_TNM_REGIONS = [
  "Skull",
  "Clavicula (R)",
  "Clavicula (L)",
  "Scapula (R)",
  "Scapula (L)",
  "Humerus (R)",
  "Humerus (L)",
  "Ilium (R)",
  "Ilium (L)",
  "Iscium (R)",
  "Iscium (L)",
  "Pubic (R)",
  "Pubic (L)",
  "Femur (R)",
  "Femur (L)",
  "Sternum",
  "R1 (R)",
  "R1 (L)",
  "R2 (R)",
  "R2 (L)",
  "R3 (R)",
  "R3 (L)",
  "R4 (R)",
  "R4 (L)",
  "R5 (R)",
  "R5 (L)",
  "R6 (R)",
  "R6 (L)",
  "R7 (R)",
  "R7 (L)",
  "R8 (R)",
  "R8 (L)",
  "R9 (R)",
  "R9 (L)",
  "R10 (R)",
  "R10 (L)",
  "R11 (R)",
  "R11 (L)",
  "R12 (R)",
  "R12 (L)",
  "C1",
  "C2",
  "C3",
  "C4",
  "C5",
  "C6",
  "C7",
  "T1",
  "T2",
  "T3",
  "T4",
  "T5",
  "T6",
  "T7",
  "T8",
  "T9",
  "T10",
  "T11",
  "T12",
  "L1",
  "L2",
  "L3",
  "L4",
  "L5",
  "Sacrum",
];

export const BONE_EQUVALENT_REGIONS = [
  ["Skull", "Skull-Front", "Skull-Back"],
  ["Clavicula-Right", "Clavicula-Right-Front"],
  ["Clavicula-Left", "Clavicula-Left-Front"],
  ["Scapula-Right", "Scapula-Right-Back"],
  ["Scapula-Left", "Scapula-Left-Back"],
  ["Humerus-Right", "Humerus-Right-Front", "Humerus-Right-Back"],
  ["Humerus-Left", "Humerus-Left-Front", "Humerus-Left-Back"],
  ["Ilium-Right", "Ilium-Right-Front", "Ilium-Right-Back"],
  ["Ilium-Left", "Ilium-Left-Front", "Ilium-Left-Back"],
  ["Iscium-Right", "Iscium-Right-Front", "Iscium-Right-Back"],
  ["Iscium-Left", "Iscium-Left-Front", "Iscium-Left-Back"],
  ["Pubic-Bone-Right", "Pubic-Bone-Right-Front", "Pubic-Bone-Right-Back"],
  ["Pubic-Bone-Left", "Pubic-Bone-Left-Front", "Pubic-Bone-Left-Back"],
  ["Femur-Right", "Femur-Right-Front", "Femur-Right-Back"],
  ["Femur-Left", "Femur-Left-Front", "Femur-Left-Back"],
  ["R1-Right", "R1-Right-Front"],
  ["R2-Right", "R2-Right-Front"],
  ["R3-Right", "R3-Right-Front"],
  ["R4-Right", "R4-Right-Front"],
  ["R5-Right", "R5-Right-Front"],
  ["R6-Right", "R6-Right-Front"],
  ["R7-Right", "R7-Right-Front"],
  ["R8-Right", "R8-Right-Front", "R8-Right-Back"],
  ["R9-Right", "R9-Right-Front", "R9-Right-Back"],
  ["R10-Right", "R10-Right-Front", "R10-Right-Back"],
  ["R11-Right", "R11-Right-Back"],
  ["R12-Right", "R12-Right-Back"],
  ["R1-Left", "R1-Left-Front"],
  ["R2-Left", "R2-Left-Front"],
  ["R3-Left", "R3-Left-Front"],
  ["R4-Left", "R4-Left-Front"],
  ["R5-Left", "R5-Left-Front"],
  ["R6-Left", "R6-Left-Front"],
  ["R7-Left", "R7-Left-Front"],
  ["R8-Left", "R8-Left-Front", "R8-Left-Back"],
  ["R9-Left", "R9-Left-Front", "R9-Left-Back"],
  ["R10-Left", "R10-Left-Front", "R10-Left-Back"],
  ["R11-Left", "R11-Left-Back"],
  ["R12-Left", "R12-Left-Back"],
];

export const SHORT_OTHER_ORGAN_NAMES = new Map(
  Object.entries({
    "Lung-Left": "Lungs (L)",
    "Lung-Right": "Lungs (R)",
    Liver: "Liver",
    "Brain-Left": "Brain (L)",
    "Brain-Right": "Brain (R)",
    "Adrenal-Gland-Right": "Adrenal Gland (R)",
    "Adrenal-Gland-Left": "Adrenal Gland (L)",
  })
);

export const OTHER_ORGAN_TNM_REGIONS = [
  "Lungs (L)",
  "Lungs (R)",
  "Liver",
  "Brain (L)",
  "Brain (R)",
  "Adrenal Gland (R)",
  "Adrenal Gland (L)",
];

export const OTHER_ORGAN_NAMES = new Map(
  Object.entries({
    "Lung-Left": "Lungs (L)",
    "Lung-Right": "Lungs (R)",
    Liver: "Liver",
    "Brain-Left": "Brain (L)",
    "Brain-Right": "Brain (R)",
    "Adrenal-Gland-Right": "Adrenal Gland (R)",
    "Adrenal-Gland-Left": "Adrenal Gland (L)",
  })
);

export const SHORT_OTHER_LYMPH_NODE_NAMES = new Map(
  Object.entries({
    CR: "CR",
    CL: "CL",
    SCR: "SCR",
    SCL: "SCL",
    AXR: "AXR",
    AXL: "AXL",
    "MED/HIL": "MED/HIL",
    RC: "RC",
    RP: "RP",
    IR: "IR",
    IL: "IL",
    CIR: "CIR",
    CIL: "CIL",
  })
);

export const OTHER_LYMPH_NODE_TNM_REGIONS = [
  "CR",
  "CL",
  "SCR",
  "SCL",
  "AXR",
  "AXL",
  "MED/HIL",
  "RC",
  "RP",
  "IR",
  "IL",
  "CIR",
  "CIL",
];

export const OTHER_LYMPH_NODE_NAMES = new Map(
  Object.entries({
    CR: "Right Cervical",
    CL: "Left Cervical",
    SCR: "Right Supraclavicular",
    SCL: "Left Supraclavicular",
    AXR: "Right Axillary",
    AXL: "Left Axillary",
    "MED/HIL": "Mediastinal / Hilar",
    RC: "Retrocrural",
    RP: "Retroperitoneal",
    IR: "Right Inguinal",
    IL: "Left Inguinal",
    CIR: "Right Common Iliac",
    CIL: "Left Common Iliac",
  })
);

export const REGION_NAMES = new Map([
  ...PROSTATE_STAGING_REGION_NAMES_LONG,
  ...PROSTATE_RESTAGING_REGION_NAMES_LONG,
  ...PROSTATE_REMOVED_REGION_NAMES_LONG,
  ...PELVIC_LYMPH_NODE_NAMES,
  ...BONE_NAMES,
  ...OTHER_ORGAN_NAMES,
  ...OTHER_LYMPH_NODE_NAMES,
]);

export const SHORT_REGION_NAMES = new Map([
  ...PROSTATE_STAGING_REGION_NAMES_SHORT,
  ...PROSTATE_RESTAGING_REGION_NAMES_SHORT,
  ...PROSTATE_REMOVED_REGION_NAMES_SHORT,
  ...SHORT_PELVIC_LYMPH_NODE_NAMES,
  ...BONE_NAMES,
  ...SHORT_OTHER_ORGAN_NAMES,
  ...SHORT_OTHER_LYMPH_NODE_NAMES,
]);

export const EQUIVALENT_REGIONS = [
  ...PROSTATE_STAGING_EQUIVALENT_REGIONS,
  ...BONE_EQUVALENT_REGIONS,
];

export const ALL_TNM_REGIONS = [
  ...new Set([
    ...PROSTATE_STAGING_TNM_REGIONS,
    ...PROSTATE_RESTAGING_TNM_REGIONS,
    ...PROSTATE_REMOVED_TNM_REGIONS,
    ...PELVIC_LYMPH_NODES_TNM_REGIONS,
    ...BONE_TNM_REGIONS,
    ...OTHER_ORGAN_TNM_REGIONS,
    ...OTHER_LYMPH_NODE_TNM_REGIONS,
  ]),
];

export default function regionName(regionId) {
  if (regionId.includes("*")) {
    return "Click to remove marker";
  }
  return REGION_NAMES.get(regionId);
}

export function shortRegionName(regionId) {
  return SHORT_REGION_NAMES.get(regionId);
}
