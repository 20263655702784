import * as React from "react";
import ProstateTumorStaging from "./ProstateTumorStaging";
import ProstateTumorRemoved from "./ProstateTumorRemoved";
import { ClinicalIndication } from "../../../constants/enums";
import ProstateTumorRestaging from "./ProstateTumorRestaging";
import { TumorMarkerInfo } from "../../../api/patientScans";

interface ProstateTumorWrapperProps {
  markerInfo: TumorMarkerInfo;
  updateTumorMarkers: (markers: TumorMarkerInfo) => void;
  clinicalIndication: ClinicalIndication;
  prostateRemoved: boolean;
  markerColor: string;
}

export interface ProstateTumorProps {
  markerInfo: TumorMarkerInfo;
  updateTumorMarkers: (markers: TumorMarkerInfo) => void;
  markerColor: string;
}

function ProstateTumorWrapper({
  markerInfo,
  updateTumorMarkers,
  clinicalIndication,
  prostateRemoved,
  markerColor,
}: ProstateTumorWrapperProps) {
  switch (clinicalIndication) {
    case ClinicalIndication.STAGING:
      return (
        <ProstateTumorStaging
          markerInfo={markerInfo}
          updateTumorMarkers={updateTumorMarkers}
          markerColor={markerColor}
        />
      );
    case ClinicalIndication.RESTAGING:
      return prostateRemoved ? (
        <ProstateTumorRemoved
          markerInfo={markerInfo}
          updateTumorMarkers={updateTumorMarkers}
          markerColor={markerColor}
        />
      ) : (
        <ProstateTumorRestaging
          markerInfo={markerInfo}
          updateTumorMarkers={updateTumorMarkers}
          markerColor={markerColor}
        />
      );
    default:
      console.error("Unknown clinical indication");
      return <div></div>;
  }
}

export default ProstateTumorWrapper;
