/**
 * Loads svg image from a <svg> tag, serialises it to
 * XML and converts it to an ObjectURL that can be later
 * imported to <img> tag
 * @param {*} imageId id of the <svg> tag
 * @returns ObjectURL for the image
 */

const IMG_WIDTH = 1024;
const IMG_HEIGHT = 768;
const LABEL_FONT_SIZE = 24;

export function updateImageSnapshot(
  setImageSnapshot,
  svgImage,
  substitutions = []
) {
  if (svgImage == null) {
    return Promise.resolve();
  }
  return createImageFromSVGObjectURL(
    loadSVGImage(svgImage, IMG_WIDTH, IMG_HEIGHT, substitutions)
  )
    .then((img) => {
      return drawSVGOnCanvas(img);
    })
    .then((pngBlob) => {
      setImageSnapshot(pngBlob);
    });
}

export function dataURLToBlob(dataURL) {
  const [metadata, base64String] = dataURL.split(",");
  const mimeType = metadata.match(/:(.*?);/)[1];
  const byteString = atob(base64String);
  let n = byteString.length;
  const byteArray = new Uint8Array(n);
  while (n--) {
    byteArray[n] = byteString.charCodeAt(n);
  }
  return new Blob([byteArray], { type: mimeType });
}

export function blobToDataURL(blob) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      resolve(event.target.result);
    };
    fileReader.readAsDataURL(blob);
  });
}

export function fetchSVGImage(url) {
  return fetch(url)
    .then((r) => r.blob())
    .then((blob) => {
      const URL = window.URL || window.webkitURL || window;
      return URL.createObjectURL(blob);
    });
}

function loadSVGImage(
  svgImage,
  width = IMG_WIDTH,
  height = IMG_HEIGHT,
  substitutions = []
) {
  let svgXml = new XMLSerializer()
    .serializeToString(svgImage)
    .replace('width="100%"', `width="${width}"`)
    .replace('height="100%"', `height="${height}"`);

  svgXml = substitutions.reduce((xmlString, [searchValue, replaceValue]) => {
    return xmlString.replace(searchValue, replaceValue);
  }, svgXml);

  const blob = new Blob([svgXml], {
    type: "image/svg+xml;charset=utf-8",
  });

  const URL = window.URL || window.webkitURL || window;
  return URL.createObjectURL(blob);
}

function createImageFromSVGObjectURL(svgObjectURL) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = svgObjectURL;
  });
}

export function fetchAndDrawSVGOnCanvas(url, width, height) {
  return fetchSVGImage(url)
    .then((svgObjectURL) => {
      return createImageFromSVGObjectURL(svgObjectURL);
    })
    .then((img) => {
      return drawSVGOnCanvas(img, width, height);
    });
}

function drawSVGOnCanvas(img, width = IMG_WIDTH, height = IMG_HEIGHT) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext("2d");
  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.drawImage(img, 0, 0, canvas.width, canvas.height);
  return toPNGBlob(canvas);
}

export function drawSVGGridOnCanvas(
  imageBlobs,
  labels,
  width = IMG_WIDTH,
  height = IMG_HEIGHT
) {
  if (imageBlobs.length === 0) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context.font = `${LABEL_FONT_SIZE}px Poppins`;
    context.textAlign = "center";
    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#000000";
    context.fillText("No changes", canvas.width / 2, canvas.height / 2);
    return toPNGBlob(canvas);
  }
  return Promise.all(
    imageBlobs.map((blob) =>
      createImageFromSVGObjectURL(URL.createObjectURL(blob))
    )
  ).then((images) => {
    const canvas = document.createElement("canvas");
    const cols = Math.ceil(Math.sqrt(imageBlobs.length));
    const rows = Math.ceil(imageBlobs.length / cols);
    canvas.width = cols * width;
    const scaledFontSize = LABEL_FONT_SIZE * cols;
    canvas.height = rows * (height + scaledFontSize);
    const context = canvas.getContext("2d");
    context.font = `${scaledFontSize}px Poppins`;
    context.textAlign = "center";
    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = "#000000";
    let rowIndex = 0;
    let currentImageIndex = 0;
    // Draw whole rows while we can
    while (currentImageIndex <= images.length - cols) {
      for (let colIndex = 0; colIndex < cols; colIndex++) {
        context.drawImage(
          images[currentImageIndex],
          width * colIndex,
          height * rowIndex + scaledFontSize,
          width,
          height
        );
        context.fillText(
          labels[currentImageIndex],
          width * (colIndex + 0.5),
          height * rowIndex + scaledFontSize
        );
        currentImageIndex++;
      }
      rowIndex++;
    }
    if (currentImageIndex < images.length) {
      // This is the last row, and it's not full
      const offsetX =
        ((cols - (images.length - currentImageIndex)) * width) / 2;
      let colIndex = 0;
      while (currentImageIndex < images.length) {
        context.drawImage(
          images[currentImageIndex],
          offsetX + width * colIndex,
          height * rowIndex + scaledFontSize,
          width,
          height
        );
        context.fillText(
          labels[currentImageIndex],
          offsetX + width * (colIndex + 0.5),
          height * rowIndex + scaledFontSize
        );
        currentImageIndex++;
        colIndex++;
      }
    }
    return toPNGBlob(canvas);
  });
}

function toPNGBlob(canvas) {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob));
  });
}
