import * as React from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";

interface RequireAuthProps {
  role?: string;
  children: React.ReactNode;
}

function RequireAuth({ role, children }: RequireAuthProps): React.ReactElement {
  const { currentUser, loading, getAccessToken } = useAuthContext();
  const location = useLocation();

  // Init refresh token flow on first render if needed
  React.useEffect(() => {
    getAccessToken();
  }, []);

  if (loading) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        style={{
          position: "fixed",
          top: "50%",
          right: "50%",
        }}
      />
    );
  }

  const authCondition =
    currentUser && (role === undefined ? true : currentUser.role === role);

  return authCondition ? (
    <>{children}</>
  ) : (
    <Navigate to="/sign_in" state={{ from: location }} replace />
  );
}

export default RequireAuth;
