import * as React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import BootstrapIcon from "../../BootstrapIcon";
import { useLogContext } from "../../LogToast/LogContext";
import { Patient } from "../../../api/patients";
import { PatientScan } from "../../../api/patientScans";
import { useAuthContext } from "../../../contexts/AuthContext";
import useLuPSMAEligibility from "../../ImagingFindings/useLuPSMAEligibility";
import { getTreatmentsForPatient } from "../../../api/patientTreatments";
import { getTestResultsForPatient } from "../../../api/testResults";
import {
  getClinicalInformation,
  getDescriptiveFindings,
  getTechnicalInformation,
} from "./clinicalReportUtils";
import { Spinner } from "react-bootstrap";
import { PatientRecipCriteria } from "../../../api/patientRecipCriteria";
import useRecipCriteria from "../../RecipCriteria/useRecipCriteria";
import { getPatientScanDescriptiveFindings } from "../../../api/patientScanDescriptiveFindings";
import { blobToDataURL, drawSVGGridOnCanvas } from "../../../utils/imageUtils";
import { GlobalHotKeys } from "react-hotkeys";

interface CopyClinicalReportButtonProps {
  patient: Patient;
  patientScan: PatientScan;
  patientRecipCriteria: PatientRecipCriteria | null;
  tnmCodeLine: string;
  oncologicalFindings: [string, string, string];
  images: Blob[];
  labels: string[];
}

const CopyClinicalReportButton = ({
  patient,
  patientScan,
  patientRecipCriteria,
  tnmCodeLine,
  oncologicalFindings,
  images,
  labels,
}: CopyClinicalReportButtonProps) => {
  const { log } = useLogContext();
  const { getAccessToken } = useAuthContext();
  const { criteriaApplicable: luPSMACriteriaApplicable, reportText } =
    useLuPSMAEligibility(patient, patientScan);
  const [loading, setLoading] = React.useState(false);
  const {
    criteriaApplicable: recipCriteriaApplicable,
    reportText: recipCriteriaReportText,
    reference,
  } = useRecipCriteria(patientScan, patientRecipCriteria);

  async function generateRichText(contentType: string): Promise<Blob> {
    if (!patient.id || !patientScan.id || loading) {
      return new Blob();
    }
    setLoading(true);

    const accessToken = await getAccessToken();
    const patientTreatments = await getTreatmentsForPatient(
      accessToken,
      patient.id
    );
    const patientTestResults = await getTestResultsForPatient(
      accessToken,
      patient.id
    );
    const clinicalInformation = getClinicalInformation(
      patient,
      patientScan,
      patientTestResults.filter((result) => result.test_name === "PSA"),
      patientTreatments
    );
    const patientScanDescriptiveFindings =
      await getPatientScanDescriptiveFindings(
        accessToken,
        patient.id,
        patientScan.id
      );
    const descriptiveFindings = getDescriptiveFindings(
      patientScanDescriptiveFindings
    );

    const technicalInformation = getTechnicalInformation(patientScan);

    const combinedImageGrid = await drawSVGGridOnCanvas(images, labels);
    const combinedImageDataURL = await blobToDataURL(combinedImageGrid);

    const psmaEligibilityRichText = luPSMACriteriaApplicable
      ? `
      <h2>Eligibility for ${
        patientScan.therapeutic_radiopharmaceutical || "PSMA"
      } targeted therapy</h2>
      <p>${reportText}</p> 
      <p>      
        <small><br/>Reference: Sartor O, de Bono J, Chi KN, Fizazi K, Herrmann K, Rahbar K, et al. Lutetium-177-PSMA-617 for Metastatic Castration-Resistant Prostate Cancer. NEJM. 2021;385:1091-103.</small>
      </p>
    `
      : "";

    const recipCriteriaRichText =
      recipCriteriaApplicable && recipCriteriaReportText !== ""
        ? `
        <h2>Treatment response evaluation</h2>
        <p>${recipCriteriaReportText}</p>
        <p>
          <small><br/>${reference}</small>
        </p>
        `
        : "";

    const richText = `
      <style>
        * {
          font-family: Arial, sans-serif;
          font-size: 10pt;
        }
        h1 {
          font-size: 20pt;
          margin-bottom: 0;
        }
        h2 {
          font-size: 12pt;
          margin-top: 10pt;
          margin-bottom: 0;
        }
        h3 {
          font-size: 10pt;
          margin-top: 5pt;
          margin-bottom: 0;
        }
        p {
          text-align: justify;
          margin-bottom: 0;
          margin-top: 0;
        }
        ul.top-level > li:first-child {
          margin-top: 5pt;
        }
        ul.top-level > li:last-child {
          margin-bottom: 5pt;
        }
        p > small {
          font-size: 7pt;
          text-align: justify;
        }
      </style>
      <h1>TNM Stager Report</h1>
      <h2>Clinical history</h2>
      <p>${clinicalInformation}</p>
      <h2>Technical information</h2>
      <p>${technicalInformation}</p>
      <h2>Oncological findings</h2>
      ${oncologicalFindings.map((finding) => `<p>${finding}</p>`).join("\n")}
      <h2>Descriptive findings</h2>
      <p>Head and neck:</p>
      ${descriptiveFindings[0]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      <p style="margin-top: 12pt">Chest:</p>
      ${descriptiveFindings[1]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      <p style="margin-top: 12pt">Abdomen and Pelvis:</p>
      ${descriptiveFindings[2]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      <p>Muscoskeletal:</p> 
      ${descriptiveFindings[3]
        .split("\n")
        .map((finding) => `<p>${finding}</p>`)
        .join("\n")}
      ${psmaEligibilityRichText}
      ${recipCriteriaRichText}
      <h2>miTNM codeline</h2>
      <p>${tnmCodeLine}</p>
      <img style="width: 100%" src="${combinedImageDataURL}" />
      <p>
        <small><br/>TNM classification according to PROMISE V2 guideline: Seifert R, Emmett L, Rowe SP, et al. Second Version of the Prostate Cancer Molecular Imaging Standardized Evaluation Framework Including Response Evaluation for Clinical Trials (PROMISE V2). European Urology. 2023 May;83(5):405-412.</small>
      </p>`;

    return new Blob([richText], {
      type: contentType,
    });
  }

  async function onClick() {
    if (navigator.clipboard.write === undefined) {
      log("Copying to clipboard is not supported in your browser");
    }

    const clipboardItem = new ClipboardItem({
      "text/html": generateRichText("text/html"),
      "text/plain": generateRichText("text/plain"),
    });
    try {
      await navigator.clipboard.write([clipboardItem]);
      log("Clinical report copied to clipboard");
    } catch (e: any) {
      console.error(e);
      log(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <GlobalHotKeys
      handlers={{
        COPY_ONCOLOGICAL_FINDINGS: onClick,
      }}
      allowChanges={true}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="copy-oncological-findings-button-tooltip">
            Copy clinical report to clipboard
          </Tooltip>
        }
      >
        <Button
          variant="link"
          onClick={onClick}
          disabled={loading}
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          {loading ? (
            <Spinner
              as="span"
              className="me-2"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <BootstrapIcon name="clipboard-pulse" size={28} />
          )}
        </Button>
      </OverlayTrigger>
    </GlobalHotKeys>
  );
};

export default CopyClinicalReportButton;
