import * as React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/Tooltip";

const UpdatingTooltip = React.forwardRef(
  ({ popper, children, left, top, show: _, ...props }, ref) => {
    React.useEffect(() => {
      popper.scheduleUpdate();
    }, [children, popper, left, top]);

    return (
      <Tooltip ref={ref} {...props}>
        {children}
      </Tooltip>
    );
  }
);

UpdatingTooltip.propTypes = {
  popper: PropTypes.object,
  children: PropTypes.node.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  show: PropTypes.bool,
};

UpdatingTooltip.displayName = "UpdatingTooltip";
export default UpdatingTooltip;
