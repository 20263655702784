import * as React from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PelvicLymphNodeMetastasesImage from "./PelvicLymphNodeMetastasesImage";
import { useRegionOverlay } from "../../RegionOverlay";
import regionName from "../../../constants/regionNames";
import findAffectedAreaNode from "../../../utils/svgUtils";
import {
  Coordinates,
  EllipseCoordinates,
  PelvicMarkerInfo,
} from "../../../api/patientScans";
import useSUVmaxOverlay from "../../SUVmaxOverlay/useSUVmaxOverlay";

export interface PelvicLymphNodeMetastasesProps {
  markerInfo: PelvicMarkerInfo;
  updatePelvicMarkers: (markers: PelvicMarkerInfo) => void;
  markerColor: string;
}

function PelvicLymphNodeMetastases({
  markerInfo,
  updatePelvicMarkers,
  markerColor,
}: PelvicLymphNodeMetastasesProps) {
  const ref = React.useRef<SVGSVGElement>(null);
  const [svgPoint, setSvgPoint] = React.useState<SVGPoint>();

  const {
    markers,
    marker_count_by_region,
    are_other_nodes_involved,
    other_nodes_involvement,
  } = markerInfo;

  const {
    overlay: regionOverlay,
    setShowOverlay,
    updateOverlayOnMouseMove,
  } = useRegionOverlay(ref, regionName);

  const updateMarkers = (newMarkers: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) => {
    updatePelvicMarkers({
      ...markerInfo,
      markers: newMarkers as { [key: string]: Coordinates },
    });
  };

  const { overlay: suvMaxOverlay, onContextMenu } = useSUVmaxOverlay({
    parentRef: ref,
    markers,
    updateMarkers,
  });

  // Set the SVG point after the initial render
  React.useEffect(() => {
    if (ref.current) {
      setSvgPoint(ref.current.createSVGPoint());
    }
  }, []);

  const onLymphNodeClick = (event: React.MouseEvent<SVGSVGElement>) => {
    const [found, lymphNodeId] = findAffectedAreaNode(4, event.target);

    if (!found || !svgPoint) {
      event.preventDefault();
      return;
    }

    // Convert screen coords to SVG coords
    svgPoint.x = event.clientX;
    svgPoint.y = event.clientY;
    const metastasisCentre = svgPoint.matrixTransform(
      ref.current!.getScreenCTM()!.inverse()
    );

    const newMetastases = structuredClone(markers);
    const newMetastasesCountByNode = structuredClone(marker_count_by_region);

    newMetastases[`${lymphNodeId}*${Date.now()}`] = {
      x: metastasisCentre.x,
      y: metastasisCentre.y,
    };

    newMetastasesCountByNode[lymphNodeId] =
      newMetastasesCountByNode.hasOwnProperty(lymphNodeId)
        ? newMetastasesCountByNode[lymphNodeId] + 1
        : 1;

    updatePelvicMarkers({
      ...markerInfo,
      markers: newMetastases,
      marker_count_by_region: newMetastasesCountByNode,
    });
    // log("Selected " + regionName(lymphNodeId));
  };

  const onMetastasisClick = (event: React.MouseEvent<SVGCircleElement>) => {
    const metastasisId = event.currentTarget.id;
    const lymphNodeId = event.currentTarget.id.split("*")[0];

    const newMetastases = structuredClone(markers);
    const newMetastasesCountByNode = structuredClone(marker_count_by_region);

    delete newMetastases[metastasisId];

    if (newMetastasesCountByNode[lymphNodeId] === 1) {
      delete newMetastasesCountByNode[lymphNodeId];
    } else {
      newMetastasesCountByNode[lymphNodeId] -= 1;
    }

    updatePelvicMarkers({
      ...markerInfo,
      markers: newMetastases,
      marker_count_by_region: newMetastasesCountByNode,
    });
    // log("Deselected " + regionName(lymphNodeId));
  };

  return (
    <Card border="light">
      <div className="card-img-top">
        {regionOverlay}
        {suvMaxOverlay}
        <PelvicLymphNodeMetastasesImage
          ref={ref}
          metastases={markers}
          onMetastasisClick={onMetastasisClick}
          onLymphNodeClick={onLymphNodeClick}
          onPointerMove={(event: React.PointerEvent) => {
            const [showOverlay, targetId] = findAffectedAreaNode(
              4,
              event.target
            );
            setShowOverlay(showOverlay);
            if (showOverlay) {
              updateOverlayOnMouseMove(event, targetId);
            }
          }}
          onPointerLeave={() => {
            setShowOverlay(false);
          }}
          onContextMenu={onContextMenu}
          markerColor={markerColor}
        />
      </div>
      <Card.Text as="div">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row
            className="align-items-center gx-1 mx-3"
            style={{ minHeight: 40 }}
          >
            <Col
              xs="auto"
              className={are_other_nodes_involved ? "" : "me-auto"}
            >
              <Form.Check
                type="checkbox"
                label={
                  are_other_nodes_involved ? " " : "Other lymph nodes involved"
                }
                checked={are_other_nodes_involved}
                onChange={() => {
                  updatePelvicMarkers({
                    ...markerInfo,
                    are_other_nodes_involved: !are_other_nodes_involved,
                  });
                }}
              />
            </Col>
            {are_other_nodes_involved && (
              <Col className="me-auto">
                <Form.Label htmlFor="otherLymphNodes" visuallyHidden>
                  Other lymph nodes involved
                </Form.Label>
                <Form.Control
                  style={{ pointerEvents: "auto" }}
                  id="otherLymphNodes"
                  placeholder="Other lymph nodes"
                  value={other_nodes_involvement}
                  onChange={(event) => {
                    updatePelvicMarkers({
                      ...markerInfo,
                      other_nodes_involvement: event.target.value,
                    });
                  }}
                />
              </Col>
            )}
          </Row>
        </Form>
      </Card.Text>
    </Card>
  );
}

export default PelvicLymphNodeMetastases;
