import * as React from "react";
import PropTypes from "prop-types";
import { GlobalHotKeys } from "react-hotkeys";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import BootstrapIcon from "../BootstrapIcon";
import { copyImage } from "../../utils/clipboardUtils";

const CopyImageButton = ({ image, label, log }) => {
  function onClick() {
    copyImage(image, label)
      .then(() => {
        log("Image copied to clipboard");
      })
      .catch((error) => {
        console.error(error);
        log(error.message);
      });
  }

  return (
    <GlobalHotKeys
      handlers={{
        COPY_IMAGE: onClick,
      }}
      allowChanges={true}
    >
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="copy-image-button-tooltip">Copy current image</Tooltip>
        }
      >
        <Button
          variant="outline-secondary"
          onClick={onClick}
          disabled={image === null}
        >
          <BootstrapIcon name="file-earmark-image" size={18} />
        </Button>
      </OverlayTrigger>
    </GlobalHotKeys>
  );
};

CopyImageButton.propTypes = {
  image: PropTypes.instanceOf(Blob),
  label: PropTypes.string.isRequired,
  log: PropTypes.func.isRequired,
};

export default CopyImageButton;
