import * as React from "react";
import Card from "react-bootstrap/Card";

import findAffectedAreaNode from "../../../utils/svgUtils";
import ProstateTumorRemovedImage from "./ProstateTumorRemovedImage";
import regionName from "../../../constants/regionNames";
import { useRegionOverlay } from "../../RegionOverlay";
import { ProstateTumorProps } from "./ProstateTumorWrapper";
import useSUVmaxOverlay from "../../SUVmaxOverlay/useSUVmaxOverlay";
import { Coordinates, EllipseCoordinates } from "../../../api/patientScans";

function ProstateTumorRemoved({
  markerInfo,
  updateTumorMarkers,
  markerColor,
}: ProstateTumorProps) {
  const ref = React.useRef<SVGSVGElement>(null);
  const [svgPoint, setSvgPoint] = React.useState<SVGPoint>();

  const { markers, marker_count_by_region } = markerInfo;

  // Set the SVG point after the initial render
  React.useEffect(() => {
    if (ref.current) {
      setSvgPoint(ref.current.createSVGPoint());
    }
  }, []);

  const {
    overlay: regionOverlay,
    setShowOverlay,
    updateOverlayOnMouseMove,
  } = useRegionOverlay(ref, regionName);

  const updateMarkers = (newMarkers: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) => {
    updateTumorMarkers({
      ...markerInfo,
      markers: newMarkers,
    });
  };

  const { overlay: suvMaxOverlay, onContextMenu } = useSUVmaxOverlay({
    parentRef: ref,
    markers,
    updateMarkers,
  });

  function onTumorClick(event: React.MouseEvent<SVGCircleElement>) {
    const tumorId = event.currentTarget.id;
    const regionId = tumorId.split("*")[0];

    // Remove the tumor
    const newTumors = structuredClone(markers);
    const newTumorCountByRegion = structuredClone(marker_count_by_region);

    delete newTumors[tumorId];

    if (marker_count_by_region[regionId] === 1) {
      delete newTumorCountByRegion[regionId];
    } else {
      newTumorCountByRegion[regionId] -= 1;
    }

    updateTumorMarkers({
      ...markerInfo,
      markers: newTumors,
      marker_count_by_region: newTumorCountByRegion,
    });
    // log("Deselected " + regionName(regionId));
  }

  function onAffectedAreaClick(event: React.MouseEvent<SVGSVGElement>) {
    const [found, regionId] = findAffectedAreaNode(3, event.target);

    if (!found || !svgPoint) {
      event.preventDefault();
      return;
    }

    // Convert screen coords to SVG coords
    svgPoint.x = event.clientX;
    svgPoint.y = event.clientY;
    const tumorCentre = svgPoint.matrixTransform(
      ref.current!.getScreenCTM()!.inverse()
    );

    const newTumors = structuredClone(markers);
    const newTumorCountByRegion = structuredClone(marker_count_by_region);
    newTumors[`${regionId}*${Date.now()}`] = {
      x: tumorCentre.x,
      y: tumorCentre.y,
    };
    newTumorCountByRegion[regionId] = newTumorCountByRegion.hasOwnProperty(
      regionId
    )
      ? newTumorCountByRegion[regionId] + 1
      : 1;

    updateTumorMarkers({
      ...markerInfo,
      markers: newTumors,
      marker_count_by_region: newTumorCountByRegion,
    });
    // log("Selected " + regionName(regionId));
  }

  return (
    <Card border="light">
      <div className="card-img-top">
        {regionOverlay}
        {suvMaxOverlay}
        <ProstateTumorRemovedImage
          ref={ref}
          tumors={markers}
          onPointerMove={(event: React.PointerEvent) => {
            const [showOverlay, targetId] = findAffectedAreaNode(
              3,
              event.target
            );
            setShowOverlay(showOverlay);
            if (showOverlay) {
              updateOverlayOnMouseMove(event, targetId);
            }
          }}
          onPointerLeave={() => {
            setShowOverlay(false);
          }}
          onAffectedAreaClick={onAffectedAreaClick}
          onTumorClick={onTumorClick}
          onContextMenu={onContextMenu}
          markerColor={markerColor}
        />
      </div>
    </Card>
  );
}

export default ProstateTumorRemoved;
