import * as React from "react";
import PropTypes from "prop-types";

import { refreshTokenSignIn, verifyAccessToken } from "../api/auth";

const AuthContext = React.createContext();

export function AuthContextProvider({ children }) {
  const [loading, setLoading] = React.useState(true);
  const [signedIn, setSignedIn] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [accessToken, setAccessToken] = React.useState(null);
  const [refreshToken, setRefreshToken__internal] = React.useState(
    window.localStorage.getItem("refreshToken")
  );
  const [lastUserId, setLastUserId__internal] = React.useState(
    window.localStorage.getItem("lastUserId")
  );
  const [session, setSession] = React.useState(null);

  function setRefreshToken(refreshToken) {
    if (refreshToken) {
      window.localStorage.setItem("refreshToken", refreshToken);
    } else {
      window.localStorage.removeItem("refreshToken");
    }
    setRefreshToken__internal(refreshToken);
  }

  function setLastUserId(lastUserId) {
    if (lastUserId) {
      window.localStorage.setItem("lastUserId", lastUserId);
    } else {
      window.localStorage.removeItem("lastUserId");
    }
    setLastUserId__internal(lastUserId);
  }

  function onSignOut() {
    setCurrentUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    // DO NOT invalidate lastUserId here
    setSignedIn(false);
    setLoading(false);
  }

  function onSuccessfulAuth(response) {
    setCurrentUser(response.current_user);
    setAccessToken(response.access_token);
    // Only set the refresh token if it exists
    // In the refresh token flow, the new refresh token is not issued
    if (response.refresh_token) {
      setRefreshToken(response.refresh_token);
    }
    setLastUserId(response.current_user.username);
    setSignedIn(true);
    setLoading(false);
  }

  React.useEffect(() => {
    if (lastUserId && refreshToken) {
      refreshTokenSignIn(lastUserId, refreshToken);
    } else {
      onSignOut();
    }
  }, []);

  async function refreshAccessTokenIfNeeded() {
    try {
      await verifyAccessToken(accessToken);
      return accessToken;
    } catch {
      if (refreshToken === null) {
        onSignOut();
        return;
      }
      try {
        // Access token not verified - go with the refresh token flow
        // Throw here if the refresh token sign in is not successful
        const response = await refreshTokenSignIn(lastUserId, refreshToken);
        onSuccessfulAuth(response);
        return response["access_token"];
      } catch (e) {
        onSignOut();
        console.error(e);
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        signedIn,
        currentUser,
        getAccessToken: refreshAccessTokenIfNeeded,
        onSuccessfulAuth,
        onSignOut,
        lastUserId,
        setLastUserId,
        session,
        setSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContext.propTypes = {
  children: PropTypes.node,
};

export function useAuthContext() {
  return React.useContext(AuthContext);
}
