import React from "react";
import {
  PatientRecipCriteria,
  createPatientRecipCriteria,
  getPatientRecipCriteria,
  initialRecipCriteria,
  updatePatientRecipCriteria,
} from "../api/patientRecipCriteria";
import { useAuthContext } from "./AuthContext";
import { usePatientScansContext } from "./PatientScansContext";
import { usePatientsContext } from "./PatientsContext";

interface PatientRecipCriteriaContextProps {
  currentPatientScanRecipCriteria: PatientRecipCriteria;
  setCurrentPatientScanRecipCriteria: React.Dispatch<
    React.SetStateAction<PatientRecipCriteria>
  >;
  saveCurrentPatientScanRecipCriteria: () => Promise<void>;
}

const PatientRecipCriteriaContext =
  React.createContext<PatientRecipCriteriaContextProps>({
    currentPatientScanRecipCriteria: initialRecipCriteria(),
    setCurrentPatientScanRecipCriteria: async () => {},
    saveCurrentPatientScanRecipCriteria: async () => {},
  });

interface PatientRecipCriteriaContextProviderProps {
  children: React.ReactNode;
}

export function PatientRecipCriteriaContextProvider({
  children,
}: PatientRecipCriteriaContextProviderProps) {
  const { getAccessToken } = useAuthContext();
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const [currentPatientScanRecipCriteria, setCurrentPatientScanRecipCriteria] =
    React.useState<PatientRecipCriteria>(initialRecipCriteria());

  // Fetch the most recent data on every current patient scan ID change
  React.useEffect(() => {
    fetchData();
  }, [currentPatientScan.id]);

  async function fetchData() {
    if (!currentPatient.id) {
      return;
    }

    const accessToken = await getAccessToken();
    try {
      const recipCriteria = await getPatientRecipCriteria(
        accessToken,
        currentPatient.id
      );
      setCurrentPatientScanRecipCriteria(recipCriteria);
    } catch (e) {
      console.error(e);
    }
  }

  async function saveCurrentPatientScanRecipCriteria() {
    if (!currentPatient.id || !currentPatientScanRecipCriteria) {
      return;
    }

    const accessToken = await getAccessToken();
    if (currentPatientScanRecipCriteria.id) {
      await updatePatientRecipCriteria(
        accessToken,
        currentPatient.id,
        currentPatientScanRecipCriteria
      );
    } else {
      await createPatientRecipCriteria(
        accessToken,
        currentPatient.id,
        currentPatientScanRecipCriteria
      );
    }
  }

  return (
    <PatientRecipCriteriaContext.Provider
      value={{
        currentPatientScanRecipCriteria,
        setCurrentPatientScanRecipCriteria,
        saveCurrentPatientScanRecipCriteria,
      }}
    >
      {children}
    </PatientRecipCriteriaContext.Provider>
  );
}

export function usePatientRecipCriteriaContext() {
  return React.useContext(PatientRecipCriteriaContext);
}
