import regionName, {
  PELVIC_LYMPH_NODE_NAMES,
  OTHER_LYMPH_NODE_NAMES,
} from "../../../constants/regionNames";
import displayBoneRegionNames, {
  handleProstateRegions,
} from "../../../utils/regionNameUtils";
import {
  BoneMarkerInfo,
  Coordinates,
  EllipseCoordinates,
  OtherMarkerInfo,
  PatientScanMarkerInfo,
  PelvicMarkerInfo,
  TumorMarkerInfo,
} from "../../../api/patientScans";
import { suvMaxByRegion } from "../../../utils/svgUtils";

function tStageFindings(
  tumorMarkerInfo: TumorMarkerInfo,
  isProstateRemoved: boolean
) {
  const { markers, marker_count_by_region, extraprostatic_involvement } =
    tumorMarkerInfo;

  return isProstateRemoved
    ? prostateRemovedFindings(markers, marker_count_by_region)
    : prostateTumorFindings(
        markers,
        marker_count_by_region,
        extraprostatic_involvement
      );
}

function prostateTumorFindings(
  markers: { [key: string]: Coordinates | EllipseCoordinates },
  tumorCountByRegion: { [key: string]: number },
  extraprostaticInvolvement: string
) {
  if (Object.keys(tumorCountByRegion).length === 0) {
    return "No local lesions.";
  }

  let result = `${Object.keys(markers).length} local lesions`;

  const lesionSuvMax = [];
  for (let marker in markers) {
    if (markers[marker].suvMax) {
      lesionSuvMax.push(markers[marker].suvMax);
    }
  }

  if (lesionSuvMax.length > 0) {
    result += ` (SUVmax ${lesionSuvMax.join(", ")})`;
  }

  result += ". PSMA-positive focus ";

  result += Array.from(handleProstateRegions(Object.keys(tumorCountByRegion)))
    .map((area) => regionName(area))
    .join(", ");

  result += ".";

  if (extraprostaticInvolvement.length > 0) {
    result += ` Extraprostatic Involvement: ${extraprostaticInvolvement}.`;
  }

  return result;
}

function prostateRemovedFindings(
  markers: { [key: string]: Coordinates | EllipseCoordinates },
  tumorCountByRegion: {
    [key: string]: number;
  }
) {
  if (Object.keys(tumorCountByRegion).length === 0) {
    return "No local lesions after radial prostatectomy.";
  }

  let result = "Presence of local recurrence after radical prostatectomy: ";

  const suv_max_by_region = suvMaxByRegion(markers);

  result += Object.keys(tumorCountByRegion)
    .map(
      (area) =>
        `${regionName(area)} (${tumorCountByRegion[area]}${
          suv_max_by_region[area]
            ? `, SUVmax ${suv_max_by_region[area].join(", ")}`
            : ""
        })`
    )
    .join(", ");

  result += ".";
  return result;
}

function nStageFindings(pelvicMarkerInfo: PelvicMarkerInfo) {
  const {
    markers,
    marker_count_by_region,
    are_other_nodes_involved,
    other_nodes_involvement,
  } = pelvicMarkerInfo;

  const suv_max_by_region = suvMaxByRegion(markers);

  const filteredMetastasesCountByNode = Object.keys(
    marker_count_by_region
  ).filter((key) => PELVIC_LYMPH_NODE_NAMES.has(key));

  if (filteredMetastasesCountByNode.length === 0 && !are_other_nodes_involved) {
    return "No positive regional lymph nodes.";
  }

  let result = "PSMA positive";

  if (filteredMetastasesCountByNode.length > 0) {
    result += " ";
    result += filteredMetastasesCountByNode
      .map(
        (nodeId) =>
          `${regionName(nodeId)} (${marker_count_by_region[nodeId]}${
            suv_max_by_region[nodeId]
              ? `, SUVmax ${suv_max_by_region[nodeId].join(", ")}`
              : ""
          })`
      )
      .join(", ");
    result += " pelvic lymph nodes";
  }

  if (filteredMetastasesCountByNode.length > 0 && are_other_nodes_involved) {
    result += " and";
  }

  if (are_other_nodes_involved) {
    result += ` other (${other_nodes_involvement}) lymph nodes`;
  }

  result += ".";

  return result;
}

function mStageFindings(
  boneMarkerInfo: BoneMarkerInfo,
  otherMarkerInfo: OtherMarkerInfo,
  pelvicMarkerInfo: PelvicMarkerInfo
) {
  const otherOrganMetastasesResult = otherOrganMetastasesFindings(
    otherMarkerInfo,
    pelvicMarkerInfo
  );
  const boneMetastasesResult = boneMetastasesFindings(boneMarkerInfo);

  if (otherOrganMetastasesResult === "" && boneMetastasesResult === "") {
    return "No distant metastases.";
  }

  let result = "";

  if (otherOrganMetastasesResult !== "") {
    result += otherOrganMetastasesResult;
  }

  if (otherOrganMetastasesResult !== "" && boneMetastasesResult !== "") {
    result += " ";
  }

  if (boneMetastasesResult !== "") {
    result += boneMetastasesResult;
  }

  return result;
}

function otherOrganMetastasesFindings(
  otherMarkerInfo: OtherMarkerInfo,
  pelvicMarkerInfo: PelvicMarkerInfo
) {
  let result = "";

  const {
    markers: other_markers,
    marker_count_by_lymph_node,
    marker_count_by_organ,
    are_other_organs_involved,
    other_organ_involvement,
  } = otherMarkerInfo;

  const other_suv_max_by_region = suvMaxByRegion(other_markers);

  const {
    markers: pelvic_markers,
    marker_count_by_region: pelvic_marker_count_by_region,
  } = pelvicMarkerInfo;

  const pelvic_suv_max_by_region = suvMaxByRegion(pelvic_markers);
  const suv_max_by_region = {
    ...other_suv_max_by_region,
    ...pelvic_suv_max_by_region,
  };

  const filteredMetastasesCountByLymphNode = Object.keys(
    marker_count_by_lymph_node
  )
    .concat(Object.keys(pelvic_marker_count_by_region))
    .filter((key) => OTHER_LYMPH_NODE_NAMES.has(key));

  if (
    filteredMetastasesCountByLymphNode.length > 0 ||
    Object.keys(marker_count_by_organ).length > 0 ||
    are_other_organs_involved
  ) {
    result += "PSMA positive";
  } else {
    return result;
  }

  if (filteredMetastasesCountByLymphNode.length > 0) {
    result += " ";
    result += filteredMetastasesCountByLymphNode
      .map(
        (lymphNodeId) =>
          `${regionName(lymphNodeId)} (${
            marker_count_by_lymph_node[lymphNodeId] ||
            pelvic_marker_count_by_region[lymphNodeId]
          }${
            suv_max_by_region[lymphNodeId]
              ? `, SUVmax ${suv_max_by_region[lymphNodeId].join(", ")}`
              : ""
          })`
      )
      .join(", ");
    result += ` lymph node${
      filteredMetastasesCountByLymphNode.length === 1 ? "" : "s"
    }`;
  }

  if (
    filteredMetastasesCountByLymphNode.length > 0 &&
    (Object.keys(marker_count_by_organ).length > 0 || are_other_organs_involved)
  ) {
    result += ", ";
  }

  const displayOrganMetastases = Object.keys(marker_count_by_organ).map(
    (otherOrganId) =>
      `${regionName(otherOrganId)} (${marker_count_by_organ[otherOrganId]}${
        suv_max_by_region[otherOrganId]
          ? `, SUVmax ${suv_max_by_region[otherOrganId].join(", ")}`
          : ""
      })`
  );

  if (displayOrganMetastases.length > 0) {
    result += ` ${displayOrganMetastases}`;
  }

  if (
    are_other_organs_involved &&
    (filteredMetastasesCountByLymphNode.length > 0 ||
      Object.keys(marker_count_by_organ).length > 0)
  ) {
    result += " and";
  }

  if (are_other_organs_involved) {
    result += ` other (${other_organ_involvement}) organs and/or lymph nodes`;
  }

  result += ".";

  return result;
}

function boneMetastasesFindings(boneMarkerInfo: BoneMarkerInfo) {
  let result = "";

  const { markers, marker_count_by_region, marker_count, is_dmi } =
    boneMarkerInfo;

  if (is_dmi || marker_count > 0) {
    result += "PSMA positive";
  } else {
    return result;
  }

  if (is_dmi) {
    result += " diffuse bone marrow involvement.";
  }

  if (marker_count > 0) {
    const suv_max_by_region = suvMaxByRegion(markers);
    result += " osseous lesions in ";
    result += displayBoneRegionNames(
      Object.keys(marker_count_by_region).map(
        (regionId) =>
          `${regionName(regionId)} (${marker_count_by_region[regionId]}${
            suv_max_by_region[regionId]
              ? `, SUVmax ${suv_max_by_region[regionId].join(", ")}`
              : ""
          })`
      )
    );
    result += ".";
  }

  return result;
}

export default function useOncologicalFindings(
  markerInfo: PatientScanMarkerInfo,
  isProstateRemoved: boolean
): [string, string, string] {
  const {
    pelvic_lymph_node_metastases,
    prostate_tumor,
    bone_metastases,
    other_organ_metastases,
  } = markerInfo;

  return [
    `T-stage: ${tStageFindings(prostate_tumor, isProstateRemoved)}`,
    `N-stage: ${nStageFindings(pelvic_lymph_node_metastases)}`,
    `M-stage: ${mStageFindings(
      bone_metastases,
      other_organ_metastases,
      pelvic_lymph_node_metastases
    )}`,
  ];
}
