import * as React from "react";
import PropTypes from "prop-types";

const LogContext = React.createContext();

export const LogContextProvider = ({ children }) => {
  const [logMap, setLogMap] = React.useState(new Map());

  function log(message, delay = 3000) {
    const timestamp = Date.now();

    const newLogMap = new Map(logMap);
    newLogMap.set(timestamp, {
      message,
      delay: delay,
      show: true,
    });
    setLogMap(newLogMap);
  }

  return (
    <LogContext.Provider value={{ logMap, setLogMap, log }}>
      {children}
    </LogContext.Provider>
  );
};

LogContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useLogContext = () => React.useContext(LogContext);
