export enum TNMClassifications {
  PROMISE_MITNM_V2 = "PROMISE V2 miTNM",
  AJCC_TNM = "AJCC TNM",
}

export enum MiTNMSections {
  PROSTATE_TUMOR = "local_lesions",
  PELVIC_LYMPH_NODE_METASTASES = "pelvic_and_inguinal_lymph_nodes",
  BONE_METASTASES = "osseous_metastases",
  OTHER_ORGAN_METASTASES = "distant_lymph_nodes_and_organs",
  SUMMARY = "summary",
}

export const MiTNMSectionsArray = Object.values(MiTNMSections);

export function MiTNMSectionName(section: MiTNMSections): string {
  switch (section) {
    case MiTNMSections.PROSTATE_TUMOR:
      return "Local Lesions";
    case MiTNMSections.PELVIC_LYMPH_NODE_METASTASES:
      return "Pelvic and Inguinal LN";
    case MiTNMSections.BONE_METASTASES:
      return "Osseous Metastases";
    case MiTNMSections.OTHER_ORGAN_METASTASES:
      return "Distant LN & Organs";
    case MiTNMSections.SUMMARY:
      return "Summary";
  }
}

export enum ClinicalIndication {
  STAGING = "staging",
  RESTAGING = "restaging",
}

export function clinicalIndicationName(clinicalIndication: ClinicalIndication) {
  switch (clinicalIndication) {
    case ClinicalIndication.STAGING:
      return "Initial staging";
    case ClinicalIndication.RESTAGING:
      return "Re-staging";
    default:
      return "";
  }
}

export enum Radiotracer {
  PSMA = "PSMA",
  FDG = "FDG",
  FCH = "FCH",
  NOT_APPLICABLE = "N/A",
}

// TODO: merge with Radiotracer
export enum Radiopharmaceutical {
  GA_PSMA11 = "\u2076\u2078Ga-PSMA-11", // 68 F
  F_DCFPYL = "\u00B9\u2078F-DCFPyL", // 18 F
  F_PSMA1007 = "\u00B9\u2078F-PSMA-1007", // 18 F
  F_rhPSMA73 = "\u00B9\u2078F-rhPSMA-7.3", // 18 F
  F_FDG = "\u00B9\u2078F-FDG", // 18 F
  TC_MDP = "\u2079\u2079Tc-MDP", // 99 Tc
}

export enum TherapeuticRadiopharmaceutical {
  LU_PSMA617 = "\u00B9\u2077\u2077Lu-PSMA-617", //177 Lu
  LU_PSMAINT = "\u00B9\u2077\u2077Lu-PSMA-I&T", //177 Lu
  LU_J591 = "\u00B9\u2077\u2077Lu-J591", //177 Lu
  LU_rhPSMA73 = "\u00B9\u2077\u2077Lu-rhPSMA-7.3", //177 Lu
  TB_PSMA_INT = "\u00B9\u2076\u00B9Tb-PSMA-I&T", //161 Tb
  AC_PSMA_INT = "\u00B2\u00B2\u2075Ac-PSMA-I&T", //225 Ac
}

export function radiotracerName(radiotracer: Radiotracer): string {
  switch (radiotracer) {
    case Radiotracer.PSMA:
      return "PSMA";
    case Radiotracer.FDG:
      return "FDG";
    case Radiotracer.FCH:
      return "FCH";
    default:
      return "";
  }
}

export enum ImagingModality {
  PET = "PET",
  PET_CT = "PET/CT",
  PET_MRI = "PET/MRI",
  CT = "CT",
  MRI = "MRI",
  BONE_SCAN = "Bone Scan",
  NOT_APPLICABLE = "N/A",
}

export enum ScanType {
  REGULAR = "regular",
  DISCREPANCIES = "discrepancies",
}

export function scanTypeName(scanType: ScanType): string {
  switch (scanType) {
    case ScanType.REGULAR:
      return "Regular";
    case ScanType.DISCREPANCIES:
      return "Discrepancies";
    default:
      return "";
  }
}

export enum ClinicalState {
  LOCALIZED_PC = "Localized PC",
  BCR = "BCR",
  mHSPC = "mHSPC",
  CRPC = "CRPC",
  mCRPC = "mCRPC",
}

export function clinicalStateName(clinicalState: ClinicalState): string {
  switch (clinicalState) {
    case ClinicalState.LOCALIZED_PC:
      return "Primary prostate cancer";
    case ClinicalState.BCR:
      return "Biochemical recurrence of prostate cancer";
    case ClinicalState.mHSPC:
      return "Metastatic hormone-sensitive prostate cancer";
    case ClinicalState.CRPC:
      return "Non-metastatic castration-resistant prostate cancer";
    case ClinicalState.mCRPC:
      return "Metastatic castration-resistant prostate cancer";
  }
}

export enum RestagingCategory {
  BIOCHEMICAL_FAILURE = "biochemical_failure",
  PSMA_THERAPY_ELIGIBILITY = "psma_therapy_eligibility",
  TREATMENT_RESPONSE_EVALUATION = "treatment_response_evaluation",
  BASELINE_SCAN = "baseline_scan",
  SCAN_COMPARISON_RISING_PSA = "scan_comparison_rising_psa",
}

export function restagingCategoryName(restagingCategory: RestagingCategory) {
  switch (restagingCategory) {
    case RestagingCategory.BIOCHEMICAL_FAILURE:
      return "Biochemical failure after definitive or salvage therapy";
    case RestagingCategory.BASELINE_SCAN:
      return "Baseline scan prior to initiation of new therapy";
    case RestagingCategory.SCAN_COMPARISON_RISING_PSA:
      return "Rising PSA with previous PSMA-PET available for comparison";
    case RestagingCategory.TREATMENT_RESPONSE_EVALUATION:
      return "Treatment response evaluation with previous PSMA-PET available for comparison";
    case RestagingCategory.PSMA_THERAPY_ELIGIBILITY:
      return "Eligibility for PSMA targeted therapy";
  }
}
