import {
  BoneMarkerInfo,
  initialMarkerInfo,
  OtherMarkerInfo,
  PatientScan,
  PelvicMarkerInfo,
  TumorMarkerInfo,
} from "../api/patientScans";
import { Radiotracer } from "../constants/enums";
import { BONE_EQUVALENT_REGIONS } from "../constants/regionNames";

export function manualDiscrepancyCalculation(
  currentPatientScan: PatientScan,
  setCurrentPatientScan: (patient: PatientScan) => void,
  firstScan: PatientScan,
  secondScanRadiotracer: Radiotracer,
  log: (message: string, delay: number) => void
) {
  // Check if the scan already exists. If not, load markers from the first discrepancy scan.
  if (currentPatientScan.id) {
    setCurrentPatientScan({
      ...currentPatientScan,
      marker_info: firstScan.marker_info,
    });
  }
  log(
    `Markers from the previous discrepancy scan have been loaded. Please deselect (click) on the markers that are not visible on the ${secondScanRadiotracer} scan.`,
    10000
  );
}

// NB: Discrepancy is an element which
// DOES NOT exist in fhe FIRST SCAN
// but EXISTS in the SECOND SCAN
export async function calculateDiscrepancies(
  currentPatientScan: PatientScan,
  setCurrentPatientScan: (patient: PatientScan) => void,
  firstScan: PatientScan,
  secondScan: PatientScan
) {
  // Find discrepancies in both scans
  const firstScanMarkerInfo = firstScan.marker_info;
  const secondScanMarkerInfo = secondScan.marker_info;

  setCurrentPatientScan({
    ...currentPatientScan,
    marker_info: {
      prostate_tumor: findTumorDiscrepancies(
        firstScanMarkerInfo.prostate_tumor,
        secondScanMarkerInfo.prostate_tumor
      ),
      pelvic_lymph_node_metastases: findPelvicDiscrepancies(
        firstScanMarkerInfo.pelvic_lymph_node_metastases,
        secondScanMarkerInfo.pelvic_lymph_node_metastases
      ),
      bone_metastases: findBoneDiscrepancies(
        firstScanMarkerInfo.bone_metastases,
        secondScanMarkerInfo.bone_metastases
      ),
      other_organ_metastases: findOtherDiscrepancies(
        firstScanMarkerInfo.other_organ_metastases,
        secondScanMarkerInfo.other_organ_metastases
      ),
    },
  });
}

function findTumorDiscrepancies(
  firstTumorMarkerInfo: TumorMarkerInfo,
  secondTumorMarkerInfo: TumorMarkerInfo
): TumorMarkerInfo {
  const tumorDiscrepancies: TumorMarkerInfo =
    initialMarkerInfo().prostate_tumor;
  for (let regionName in secondTumorMarkerInfo.marker_count_by_region) {
    if (
      !firstTumorMarkerInfo.marker_count_by_region.hasOwnProperty(regionName)
    ) {
      // Add tumor discrepancy
      const markerKey = Object.keys(secondTumorMarkerInfo.markers).find((key) =>
        key.startsWith(regionName)
      );
      tumorDiscrepancies.markers[markerKey!] =
        secondTumorMarkerInfo.markers[markerKey!];
      tumorDiscrepancies.marker_count_by_region[regionName] = 1;
    }
  }
  return tumorDiscrepancies;
}

function findPelvicDiscrepancies(
  firstPelvicMarkerInfo: PelvicMarkerInfo,
  secondPelvicMarkerInfo: PelvicMarkerInfo
): PelvicMarkerInfo {
  const pelvicDiscrepancies: PelvicMarkerInfo =
    initialMarkerInfo().pelvic_lymph_node_metastases;
  for (let regionName in secondPelvicMarkerInfo.marker_count_by_region) {
    if (
      !firstPelvicMarkerInfo.marker_count_by_region.hasOwnProperty(regionName)
    ) {
      // Add tumor discrepancy
      const markerKey = Object.keys(secondPelvicMarkerInfo.markers).find(
        (key) => key.startsWith(regionName)
      );
      pelvicDiscrepancies.markers[markerKey!] =
        secondPelvicMarkerInfo.markers[markerKey!];
      pelvicDiscrepancies.marker_count_by_region[regionName] = 1;
    }
  }
  return pelvicDiscrepancies;
}

function findOtherDiscrepancies(
  firstOtherMarkerInfo: OtherMarkerInfo,
  secondOtherMarkerInfo: OtherMarkerInfo
): OtherMarkerInfo {
  const otherDiscrepancies: OtherMarkerInfo =
    initialMarkerInfo().other_organ_metastases;
  // We need to repeat the same loop twice: for other lymph nodes and other organs
  for (let regionName in secondOtherMarkerInfo.marker_count_by_lymph_node) {
    if (
      !firstOtherMarkerInfo.marker_count_by_lymph_node.hasOwnProperty(
        regionName
      )
    ) {
      // Add tumor discrepancy
      const markerKey = Object.keys(secondOtherMarkerInfo.markers).find((key) =>
        key.startsWith(regionName)
      );
      otherDiscrepancies.markers[markerKey!] =
        secondOtherMarkerInfo.markers[markerKey!];
      otherDiscrepancies.marker_count_by_lymph_node[regionName] = 1;
    }
  }
  for (let regionName in secondOtherMarkerInfo.marker_count_by_organ) {
    if (
      !firstOtherMarkerInfo.marker_count_by_organ.hasOwnProperty(regionName)
    ) {
      // Add tumor discrepancy
      const markerKey = Object.keys(secondOtherMarkerInfo.markers).find((key) =>
        key.startsWith(regionName)
      );
      otherDiscrepancies.markers[markerKey!] =
        secondOtherMarkerInfo.markers[markerKey!];
      otherDiscrepancies.marker_count_by_organ[regionName] = 1;
    }
  }
  return otherDiscrepancies;
}

function findBoneDiscrepancies(
  firstBoneMarkerInfo: BoneMarkerInfo,
  secondBoneMarkerInfo: BoneMarkerInfo
): BoneMarkerInfo {
  // Handle DMI difference
  const boneDiscrepancies = initialMarkerInfo().bone_metastases;
  if (
    (firstBoneMarkerInfo.is_dmi && !secondBoneMarkerInfo.is_dmi) ||
    (!firstBoneMarkerInfo.is_dmi && secondBoneMarkerInfo.is_dmi)
  ) {
    boneDiscrepancies.is_dmi = true;
    return boneDiscrepancies;
  }

  for (let regionName in secondBoneMarkerInfo.marker_count_by_region) {
    // Check for equivalent regions
    let foundEquivalent = false;
    const equivalentRegions = BONE_EQUVALENT_REGIONS.find(
      (elem) => elem.indexOf(regionName) !== -1
    ) || [regionName];
    if (!equivalentRegions) {
      continue;
    }
    for (let equivalentRegionName of equivalentRegions) {
      if (
        firstBoneMarkerInfo.marker_count_by_region.hasOwnProperty(
          equivalentRegionName
        )
      ) {
        foundEquivalent = true;
        break;
      }
    }
    if (!foundEquivalent) {
      const markerKey = Object.keys(secondBoneMarkerInfo.markers).find((key) =>
        key.startsWith(regionName)
      );
      boneDiscrepancies.markers[markerKey!] =
        secondBoneMarkerInfo.markers[markerKey!];
      boneDiscrepancies.marker_count_by_region[regionName] = 1;
      boneDiscrepancies.marker_count = boneDiscrepancies.marker_count + 1;
    }
  }

  return boneDiscrepancies;
}
