import * as React from "react";
import { Nav, Container } from "react-bootstrap";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import MiTNMHeader from "../MiTNMHeader";
import { useOrgContext } from "../../contexts/OrgContext";

function OrgSettingsPage() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { currentOrg } = useOrgContext();

  React.useEffect(() => {
    if (!outlet) {
      return navigate("/settings/org/patient_scans", { replace: true });
    }
  }, []);

  return (
    <Container fluid style={{ paddingLeft: 28, paddingRight: 28 }}>
      <MiTNMHeader>
        <Nav className="me-auto">
          <Nav.Link as={Link} style={{ marginTop: "0.25rem" }} to="/home">
            Home
          </Nav.Link>
          <Nav.Link
            as={Link}
            style={{ marginTop: "0.25rem" }}
            to="/settings/org/patient_scans"
          >
            Patient Scans
          </Nav.Link>
          {currentOrg?.product === "clinic" && (
            <Nav.Link
              as={Link}
              style={{ marginTop: "0.25rem" }}
              to="/settings/org/patient_scan_comparisons"
            >
              Saved Lesion Tracking
            </Nav.Link>
          )}
          {currentOrg?.product === "research" && (
            <Nav.Link
              as={Link}
              style={{ marginTop: "0.25rem" }}
              to="/settings/org/users"
            >
              Users
            </Nav.Link>
          )}
        </Nav>
      </MiTNMHeader>
      {outlet}
    </Container>
  );
}

export default OrgSettingsPage;
