import InitialDiagnosis from "./InitialDiagnosis";
import LabTests from "./LabTests";
import TreatmentHistory from "./TreatmentHistory";

export default function ClinicalData(): React.ReactElement {
  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">Clinical Data</h3>
      </div>
      <InitialDiagnosis />
      <TreatmentHistory />
      <LabTests />
    </>
  );
}
