import { DateTime } from "luxon";

export function formatDateDiff(date1: DateTime, date2: DateTime) {
  let duration = date1.diff(date2, "days");

  const negativeDuration = duration.valueOf() < 0;

  if (negativeDuration) {
    duration = duration.negate();
  }

  return `${duration.toHuman({ listStyle: "narrow" })} ${
    negativeDuration ? "before" : "after"
  }`;
}

export function parseDate(dateString: any): DateTime | null {
  return dateString ? DateTime.fromISO(dateString) : null;
}
