import { TestResult } from "../api/testResults";

export function calculatePSADoublingTime(sortedPSAValues: TestResult[]) {
  const n = sortedPSAValues.length - 1;
  if (
    sortedPSAValues.length < 2 ||
    sortedPSAValues[0].value == null ||
    sortedPSAValues[n].value == null
  ) {
    console.log("Cannot calculate PSA doubling time - no enough data points");
    return undefined;
  }

  // PSA values are sorted in reversed order, so the formula is ln(2) / ((ln(x0)-ln(xn)) / (t0-tn))
  const psaDoublingTime =
    Math.log(2) /
    ((Math.log(sortedPSAValues[0].value) -
      Math.log(sortedPSAValues[n].value!)) /
      sortedPSAValues[0].taken_at.diff(sortedPSAValues[n].taken_at, "months")
        .months);
  return parseFloat(psaDoublingTime.toFixed(1));
}
