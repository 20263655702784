import * as React from "react";
import { Patient } from "../../api/patients";
import { PatientScan } from "../../api/patientScans";
import { ClinicalIndication, RestagingCategory } from "../../constants/enums";

interface LuPSMAEligibilityProps {
  criteriaApplicable: boolean;
  eligibleForTherapy: boolean | undefined;
  reportText: string;
}

export default function useLuPSMAEligibility(
  currentPatient: Patient,
  currentPatientScan: PatientScan
): LuPSMAEligibilityProps {
  const criteriaApplicable = React.useMemo(
    () =>
      currentPatient.id !== null &&
      currentPatientScan.clinical_indication === ClinicalIndication.RESTAGING &&
      currentPatientScan.restaging_category ===
        RestagingCategory.PSMA_THERAPY_ELIGIBILITY,
    [
      currentPatient.id,
      currentPatientScan.clinical_indication,
      currentPatientScan.restaging_category,
    ]
  );

  const eligibleForTherapy = React.useMemo(() => {
    if (
      criteriaApplicable &&
      currentPatientScan.liver_suv_max &&
      currentPatientScan.tumor_suv_max &&
      currentPatientScan.has_psma_negative_soft_tissue_lesions !== null
    ) {
      return (
        currentPatientScan.tumor_suv_max > currentPatientScan.liver_suv_max &&
        currentPatientScan.has_psma_negative_soft_tissue_lesions === false
      );
    } else {
      return undefined;
    }
  }, [
    criteriaApplicable,
    currentPatientScan.liver_suv_max,
    currentPatientScan.tumor_suv_max,
    currentPatientScan.has_psma_negative_soft_tissue_lesions,
  ]);

  const reportText = React.useMemo(() => {
    let luPSMAEligibility = "";

    if (!criteriaApplicable) {
      return luPSMAEligibility;
    }

    luPSMAEligibility += `The patient is ${
      eligibleForTherapy ? "eligible" : "ineligible"
    } for`;

    if (currentPatientScan.therapeutic_radiopharmaceutical) {
      luPSMAEligibility += ` therapy with ${currentPatientScan.therapeutic_radiopharmaceutical}`;
    } else {
      luPSMAEligibility += " PSMA targeted therapy";
    }

    luPSMAEligibility += ` according to the VISION PET criteria. Metastatic tumor uptake (SUVmax ${
      currentPatientScan.tumor_suv_max
    }) is ${
      (currentPatientScan.tumor_suv_max || 0) >
      (currentPatientScan.liver_suv_max || 0)
        ? "higher"
        : "lower"
    } than the liver background uptake (SUVmax ${
      currentPatientScan.liver_suv_max
    })`;

    if (currentPatientScan.has_psma_negative_soft_tissue_lesions) {
      luPSMAEligibility += ` Metastatic PSMA-negative soft-tissue tumor lesion was observed (SUVmax ${currentPatientScan.psma_negative_soft_tissue_lesions_suv_max}, location - ${currentPatientScan.psma_negative_soft_tissue_lesions})`;
    } else {
      luPSMAEligibility +=
        " There was no PSMA-negative soft-tissue metastatic lesion detected.";
    }

    return luPSMAEligibility;
  }, [currentPatientScan, eligibleForTherapy, criteriaApplicable]);

  return {
    criteriaApplicable,
    eligibleForTherapy,
    reportText,
  };
}
