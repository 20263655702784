import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";

export interface StudyParticipation {
  id: number | null;
  user_id: number | null;
  group: string;
  current_patient_id: number | null;
  current_step: number | null;
  created_at: DateTime | null;
  updated_at: DateTime | null;
}

export function initialStudyParticipation(group: string): StudyParticipation {
  return {
    id: null,
    user_id: null,
    group,
    current_patient_id: null,
    current_step: null,
    created_at: null,
    updated_at: null,
  };
}

export function serializeToStudyParticipation(
  jsonResponse: any
): StudyParticipation {
  return {
    ...initialStudyParticipation(""),
    ...jsonResponse,
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serialzeFromStudyParticipation(sp: StudyParticipation): string {
  return JSON.stringify({
    ...sp,
    created_at: sp.created_at?.toISO(),
    updated_at: sp.updated_at?.toISO(),
  });
}

export async function getAllStudyParticipations(
  accessToken: string
): Promise<StudyParticipation[]> {
  const response = await fetch("/api/study_participations", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToStudyParticipation);
}

export async function getStudyParticipationById(
  accessToken: string,
  id: number
): Promise<StudyParticipation> {
  const response = await fetch(`/api/study_participations/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToStudyParticipation(jsonResponse);
}

export async function createStudyParticipation(
  accessToken: string,
  studyParticipation: StudyParticipation
): Promise<any> {
  const response = await fetch("/api/study_participations", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serialzeFromStudyParticipation(studyParticipation),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function updateStudyParticipation(
  accessToken: string,
  id: number,
  studyParticipation: StudyParticipation
): Promise<any> {
  const response = await fetch(`/api/study_participations/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serialzeFromStudyParticipation(studyParticipation),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function deleteStudyParticipation(
  accessToken: string,
  id: number
): Promise<any> {
  const response = await fetch(`/api/study_participations/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}
