import * as React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Navigate } from "react-router-dom";

import Footer from "../Footer";

import logo from "../../img/TNM-Stager-Logo.svg";
import tnmStagerUIImage from "../../img/TNM-Stager-UI.png";
import tnmStagerDemo from "../../img/TNM-Stager-Demo.mp4";
import petCtScanImage from "../../img/PET-CT-Scan.png";
import bodyWithMarkersImage from "../../img/Body-With-Markers.png";
import radiologistImage from "../../img/Radiologist.png";
import clinicianImage from "../../img/Clinician.png";
import patientImage from "../../img/Patient.png";
import { useAuthContext } from "../../contexts/AuthContext";

function LandingPage() {
  const { signedIn } = useAuthContext();
  return signedIn ? (
    <Navigate to="/home" replace />
  ) : (
    <React.Fragment>
      <Navbar expand="md">
        <Container fluid="lg" className="d-flex justify-content-center">
          <Link to="/" className="navbar-brand logo">
            <img alt="TNM Stager Logo" src={logo} height="70"></img>
          </Link>
          <Navbar.Toggle aria-controls="LandingPage-navbar" />
          <Navbar.Collapse id="LandingPage-navbar">
            <Nav className="me-auto mb-2 mb-md-0 pt-2">
              <Nav.Link href="#features" disabled>
                Features
              </Nav.Link>
              <Nav.Link href="#pricing" disabled>
                Pricing
              </Nav.Link>
            </Nav>
            <div className="d-flex pt-2">
              <Link to="/sign_in" className="btn btn-outline-primary me-2">
                Sign in
              </Link>
              <a
                href="https://forms.office.com/r/640qkFX4wZ"
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                Register your interest
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container
        fluid="lg"
        style={{ minHeight: "84vh", flexDirection: "column" }}
      >
        <Row className="p-3 pb-0 pt-lg-3 align-items-center">
          <Col
            md={{ span: 7, order: 0 }}
            xs={{ span: 12, order: 1 }}
            className="p-3 p-lg-5 pt-lg-3"
          >
            <p className="display-4 fw-normal">
              Standardization and visualization of complex imaging findings
            </p>
          </Col>
          <Col
            md={{ span: 5, order: 1, offset: 0 }}
            xs={{ span: 10, order: 0, offset: 1 }}
            className="p-0 overflow-hidden"
          >
            <Image alt="TNM Stager UI" src={tnmStagerUIImage} fluid />
          </Col>
        </Row>
        <Row className="px-3 pt-5 my-5 text-center">
          <Col>
            <video
              src={`${tnmStagerDemo}#t=0.001`}
              controls
              className="border rounded-3 shadow-lg mb-4 bg-light"
              width="100%"
              preload="metadata"
            />
          </Col>
        </Row>
        <Row className="px-3 pt-5 mt-5 text-center">
          <Col>
            <p className="display-5 fw-normal">Turn scan...</p>
          </Col>
          <Col>
            <p className="display-5 fw-normal">Into map...</p>
          </Col>
          <Col>
            <p className="display-5 fw-normal">Into code</p>
          </Col>
        </Row>
        <Row className="px-3 my-5 text-center align-items-center">
          <Col>
            <Image alt="PET-CT scan" src={petCtScanImage} fluid />
          </Col>
          <Col>
            <Image alt="Body with markers" src={bodyWithMarkersImage} fluid />
          </Col>
          <Col>
            <h3>T0 N0 M1a M1c</h3>
          </Col>
        </Row>
        <Row className="px-3 text-center" style={{ marginBottom: "10rem" }}>
          <Col>
            <p className="display-5 fw-normal">Turn code into an action plan</p>
          </Col>
        </Row>
        <Row className="px-3 pt-5 my-5 text-center">
          <Col lg={8} className="mx-auto">
            <p className="display-4 mb-4">
              How can <span className="text-primary">TNM Stager</span> enrich
              your workflows?
            </p>
          </Col>
        </Row>
        <Row className="px-3 px-md-5 mx-md-3">
          <Col md className="my-auto">
            <h1 className="display-5 fw-normal lh-1 mb-3">Radiologists</h1>
            <p className="lead">
              Imaging specialists are using TNM Stager to summarize oncologic
              imaging findings. The TNM score, corresponding images, and
              oncological findings section can be transferred separately or in
              its entirety in the official report.
            </p>
          </Col>
          <Col
            md={{ span: true, offset: 0 }}
            xs={{ span: 8, offset: 2 }}
            className="my-auto"
          >
            <Image
              alt="Radiologist"
              src={radiologistImage}
              className="d-block mx-auto"
              fluid
              rounded
            />
          </Col>
        </Row>
        <Row className="px-3 px-md-5 mx-md-3 align-items-center">
          <Col
            md={{ span: true, order: 1 }}
            sm={{ order: 0 }}
            className="my-auto"
          >
            <h1 className="display-5 fw-normal lh-1 mb-3">Clinicians</h1>
            <p className="lead">
              Fast assessment of severity and extent of disease without looking
              at DICOM images.
            </p>
          </Col>
          <Col
            md={{ span: true, offset: 0, order: 0 }}
            sm={{ span: 8, offset: 2, order: 1 }}
            className="my-auto"
          >
            <Image
              alt="Clinician"
              src={clinicianImage}
              className="d-block mx-auto"
              fluid
              rounded
            />
          </Col>
        </Row>
        <Row className="px-3 px-md-5 mx-md-3">
          <Col md className="my-auto">
            <h1 className="display-5 fw-normal lh-1 mb-3">Patients</h1>
            <p className="lead">
              Clinicians can use images and PDF report generated by TNM Stager
              during discussion with patients to better explain the stage of
              their disease.
            </p>
          </Col>
          <Col
            md={{ span: true, offset: 0 }}
            sm={{ span: 8, offset: 2 }}
            className="my-auto"
          >
            <Image
              alt="Patient"
              src={patientImage}
              className="d-block mx-auto"
              fluid
              rounded
            />
          </Col>
        </Row>
        <Row
          className="p-3 text-center"
          style={{ marginTop: "10rem", marginBottom: "10rem" }}
        >
          <Col xs={12}>
            <p className="display-5 fw-normal">Turn code into an action plan</p>
          </Col>
          <Col xs={{ span: 6, offset: 3 }}>
            <a
              href="https://forms.office.com/r/640qkFX4wZ"
              target="_blank"
              rel="noreferrer"
              className="btn btn-outline-primary"
            >
              Register your interest
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;
