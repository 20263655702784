import * as React from "react";
import {
  BoneMarkerInfo,
  createPatientScan,
  getPatientScanById,
  getPatientScansForPatient,
  initialPatientScanForProduct,
  OtherMarkerInfo,
  PatientScan,
  PelvicMarkerInfo,
  TumorMarkerInfo,
  updatePatientScan,
} from "../api/patientScans";
import {
  ClinicalIndication,
  ClinicalState,
  Radiotracer,
  ScanType,
} from "../constants/enums";
import { useAuthContext } from "./AuthContext";
import { useOrgContext } from "./OrgContext";
import { usePatientsContext } from "./PatientsContext";

interface PatientScansContextType {
  // TODO: Refactor patientScans as a useMemo function
  // Docs: https://reactjs.org/docs/hooks-reference.html#usememo
  patientScans: PatientScan[];
  currentPatientScan: PatientScan;
  setCurrentPatientScan: (newPatientScan: PatientScan) => void;
  updateTumorMarkers: (markers: TumorMarkerInfo) => void;
  updatePelvicMarkers: (markers: PelvicMarkerInfo) => void;
  updateBoneMarkers: (markers: BoneMarkerInfo) => void;
  updateOtherMarkers: (markers: OtherMarkerInfo) => void;
  savePatientScan: (
    patientId: number,
    newPatientScan: PatientScan
  ) => Promise<void>;
  resetPatientScan: () => void;
  changesSaved: boolean;
  markerColor: string;
}

const PatientScansContext = React.createContext<PatientScansContextType>({
  patientScans: [],
  currentPatientScan: initialPatientScanForProduct("basic"),
  setCurrentPatientScan: (p) => {},
  changesSaved: true,
  updateTumorMarkers: (markers) => {},
  updatePelvicMarkers: (markers) => {},
  updateBoneMarkers: (markers) => {},
  updateOtherMarkers: (markers) => {},
  savePatientScan: (patientId, patientScan) => Promise.resolve(),
  resetPatientScan: () => {},
  markerColor: "#e53700",
});

interface PatientScansContextProviderProps {
  children: React.ReactNode;
}

export function PatientScansContextProvider({
  children,
}: PatientScansContextProviderProps) {
  const { getAccessToken } = useAuthContext();
  const { currentOrg } = useOrgContext();
  const { currentPatient } = usePatientsContext();

  const [patientScans, setPatientScans] = React.useState<PatientScan[]>([]);
  const [changesSaved, setChangesSaved] = React.useState(false);

  const [currentPatientScan, setCurrentPatientScan__internal] =
    React.useState<PatientScan>(
      initialPatientScanForProduct(currentOrg?.product || "basic")
    );

  const markerColor: string = React.useMemo(() => {
    if (currentPatientScan.scan_type === ScanType.REGULAR) {
      switch (currentPatientScan.radiotracer) {
        case Radiotracer.FDG:
          return "#9DC3E6";
        case Radiotracer.FCH:
          return "#7030A0";
        case Radiotracer.PSMA:
        default:
          return "#e53700";
      }
    } else {
      const discrepancyType = `${currentPatientScan.metadata.firstScanRadiotracer}-${currentPatientScan.metadata.secondScanRadiotracer}`;
      switch (discrepancyType) {
        case "PSMA-FDG":
          return "#92D051";
        case "PSMA-FCH":
          return "#FFD966";
        case "[PSMA-FDG]-FCH":
          return "#7030A0";
        case "[PSMA-FCH]-FDG":
          return "#9DC3E6";
        default:
          return "#e53700";
      }
    }
  }, [
    currentPatientScan.scan_type,
    currentPatientScan.radiotracer,
    currentPatientScan.metadata.firstScanRadiotracer,
    currentPatientScan.metadata.secondScanRadiotracer,
  ]);

  // Fetch all scans every time a new patient is selected
  React.useEffect(() => {
    (async () => {
      if (!currentPatient.id) {
        console.warn("Current patient is not defined");
        return;
      }
      const accessToken = await getAccessToken();
      try {
        let newPatientScans = await getPatientScansForPatient(
          accessToken,
          currentPatient.id
        );
        setPatientScans(newPatientScans);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [currentPatient.id]);

  // Reset the clinical state every time clinical indication is changed
  React.useEffect(() => {
    if (
      currentPatientScan.clinical_indication === ClinicalIndication.STAGING &&
      currentPatientScan.clinical_state !== ClinicalState.LOCALIZED_PC
    ) {
      setCurrentPatientScan({
        ...currentPatientScan,
        clinical_state: ClinicalState.LOCALIZED_PC,
      });
    }
    if (
      currentPatientScan.clinical_indication === ClinicalIndication.RESTAGING &&
      currentPatientScan.clinical_state === ClinicalState.LOCALIZED_PC
    ) {
      setCurrentPatientScan({
        ...currentPatientScan,
        clinical_state: null,
      });
    }
  }, [currentPatientScan.clinical_indication]);

  function setCurrentPatientScan(newPatientScan: PatientScan) {
    setCurrentPatientScan__internal(newPatientScan);
    setChangesSaved(false);
  }

  function updateTumorMarkers(markers: TumorMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          prostate_tumor: markers,
        },
      });
    }
  }

  function updatePelvicMarkers(markers: PelvicMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          pelvic_lymph_node_metastases: markers,
        },
      });
    }
  }

  function updateBoneMarkers(markers: BoneMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          bone_metastases: markers,
        },
      });
    }
  }

  function updateOtherMarkers(markers: OtherMarkerInfo) {
    if (currentPatientScan) {
      setCurrentPatientScan({
        ...currentPatientScan,
        marker_info: {
          ...currentPatientScan.marker_info,
          other_organ_metastases: markers,
        },
      });
    }
  }

  async function handleCreatePatientScan(
    patientId: number,
    patientScanId: number
  ): Promise<void> {
    const accessToken = await getAccessToken();
    const newPatientScan = await getPatientScanById(
      accessToken,
      patientId,
      patientScanId
    );
    setPatientScans(patientScans.concat(newPatientScan));
    setCurrentPatientScan__internal(newPatientScan);
    setChangesSaved(true);
  }

  function hanldeUpdatePatientScan(ps: PatientScan) {
    setPatientScans(patientScans.map((p) => (p.id === ps.id ? ps : p)));
    setCurrentPatientScan__internal(ps);
    setChangesSaved(true);
  }

  function resetPatientScan() {
    setCurrentPatientScan(
      initialPatientScanForProduct(currentOrg?.product || "basic")
    );
    setChangesSaved(true);
  }

  async function savePatientScan(patientId: number, patientScan: PatientScan) {
    const accessToken = await getAccessToken();

    try {
      if (!patientScan.id) {
        const response = await createPatientScan(
          accessToken,
          patientId,
          patientScan
        );
        await handleCreatePatientScan(patientId, response.id);
      } else {
        await updatePatientScan(
          accessToken,
          patientId,
          patientScan.id,
          currentPatientScan
        );
        await hanldeUpdatePatientScan(patientScan);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <PatientScansContext.Provider
      value={{
        patientScans,
        currentPatientScan,
        setCurrentPatientScan,
        updateTumorMarkers,
        updateBoneMarkers,
        updatePelvicMarkers,
        updateOtherMarkers,
        savePatientScan,
        resetPatientScan,
        changesSaved,
        markerColor,
      }}
    >
      {children}
    </PatientScansContext.Provider>
  );
}

export function usePatientScansContext() {
  return React.useContext(PatientScansContext);
}
