import * as React from "react";

import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <footer className="mt-2 pt-3">
      <Container
        fluid="lg"
        className="flex-column flex-lg-row justify-content-between"
      >
        <p className="text-center text-lg-left">&copy; 2023 TNM Stager LLC</p>
        {/* <p className="text-center text-lg-right">
          <a href="#">Privacy</a> &middot; <a href="#">Terms</a>
        </p> */}
      </Container>
    </footer>
  );
}

export default Footer;
