import * as React from "react";
import { Spinner } from "react-bootstrap";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Product } from "../api/orgs";
import { useOrgContext } from "../contexts/OrgContext";

interface RequireProductProps {
  product: Product;
}

function RequireProduct({ product }: RequireProductProps) {
  const { currentOrg, loading } = useOrgContext();
  const location = useLocation();

  if (loading) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        style={{
          position: "fixed",
          top: "50%",
          right: "50%",
        }}
      />
    );
  }

  return currentOrg?.product === product ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

export default RequireProduct;
