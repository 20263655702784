import { DateTime } from "luxon";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";
import { Patient, serializeToPatient } from "./patients";
import { PatientScan, serializeToPatientScan } from "./patientScans";
import {
  serializeToStudyParticipation,
  StudyParticipation,
} from "./studyParticipations";

export type Product = "basic" | "clinic" | "research";

export interface Org {
  id: number;
  name: string;
  product: Product;
  created_at: DateTime | null;
  updated_at: DateTime | null;
}

export function serializeToOrg(jsonResponse: any): Org {
  return {
    ...jsonResponse,
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
  };
}

export function serializeFromOrg(org: Org): string {
  return JSON.stringify({
    ...org,
    created_at: org.created_at?.toISO(),
    updated_at: org.updated_at?.toISO(),
  });
}

export async function getOrg(accessToken: string, orgId: number): Promise<Org> {
  const response = await fetch(`/api/orgs/${orgId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToOrg(jsonResponse);
}

export async function getOrgUsers(
  accessToken: string,
  orgId: number
): Promise<any[]> {
  const response = await fetch(`/api/orgs/${orgId}/users`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse as any[];
}

export async function getOrgPatients(
  accessToken: string,
  orgId: number
): Promise<Patient[]> {
  const response = await fetch(`/api/orgs/${orgId}/patients`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatient);
}

export async function getOrgPatientScans(
  accessToken: string,
  orgId: number
): Promise<PatientScan[]> {
  const response = await fetch(`/api/orgs/${orgId}/patient_scans`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatientScan);
}

export async function getOrgStudyParticipations(
  accessToken: string,
  orgId: number
): Promise<StudyParticipation[]> {
  const response = await fetch(`/api/orgs/${orgId}/study_participations`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToStudyParticipation);
}

export async function getOrgPatientFeedback(
  accessToken: string,
  orgId: number
): Promise<any[]> {
  const response = await fetch(`/api/orgs/${orgId}/patient_feedback`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse as any[];
}
