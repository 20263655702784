import * as React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import { useLogContext } from "./LogContext";

function LogToast() {
  const { logMap, setLogMap } = useLogContext();

  function closeToast(timestamp) {
    const newLogMap = new Map(logMap);
    newLogMap.set(timestamp, {
      ...logMap.get(timestamp),
      show: false,
    });
    setLogMap(newLogMap);
  }

  return (
    <ToastContainer
      className="p-3"
      position="top-end"
      style={{ zIndex: 1000, marginTop: "4rem" }}
    >
      {Array.from(logMap.entries()).map(([timestamp, logEntry]) => (
        <Toast
          key={`toast-${timestamp}`}
          show={logEntry.show}
          delay={logEntry.delay}
          autohide
          onClose={() => {
            closeToast(timestamp);
          }}
        >
          <Toast.Body>{logEntry.message}</Toast.Body>
        </Toast>
      ))}
    </ToastContainer>
  );
}

export default LogToast;
