import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  createPatientScanDescriptiveFindings,
  getPatientScanDescriptiveFindings,
  initialPatientScanDescriptiveFindings,
  serializeFromPatientScanDescriptiveFindings,
  serializeToPatientScanDescriptiveFindings,
  updatePatientScanDescriptiveFindings,
} from "../api/patientScanDescriptiveFindings";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { usePatientsContext } from "../contexts/PatientsContext";
import { Col, Form, Row } from "react-bootstrap";
import { useAuthContext } from "../contexts/AuthContext";

const LocalStorageKey = "tmp.descriptive-finding";

export default function DescriptiveFindings() {
  const { getAccessToken } = useAuthContext();
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();

  const [currentDescirptiveFindings, setCurrentDescriptiveFindings] =
    React.useState(initialPatientScanDescriptiveFindings());

  // Reset descriptive findings on patient / patient scan change
  React.useEffect(() => {
    setCurrentDescriptiveFindings(initialPatientScanDescriptiveFindings());
  }, [currentPatient.id, currentPatientScan.id]);

  // Fetch descriptive findings on initial render
  React.useEffect(() => {
    fetchData();
    // Save descriptive findings on page unload
    return () => {
      saveData();
    };
  }, []);

  // Write state to local storage on every update
  React.useEffect(() => {
    window.localStorage.setItem(
      LocalStorageKey,
      serializeFromPatientScanDescriptiveFindings(currentDescirptiveFindings)
    );
  }, [currentDescirptiveFindings]);

  async function fetchData() {
    if (!currentPatient.id || !currentPatientScan.id) {
      return;
    }

    if (window.localStorage.getItem(LocalStorageKey)) {
      const descriptiveFindings = serializeToPatientScanDescriptiveFindings(
        JSON.parse(window.localStorage.getItem(LocalStorageKey) || "")
      );
      setCurrentDescriptiveFindings(descriptiveFindings);
    } else {
      const accessToken = await getAccessToken();
      try {
        const descriptiveFindings = await getPatientScanDescriptiveFindings(
          accessToken,
          currentPatient.id,
          currentPatientScan.id
        );
        setCurrentDescriptiveFindings(descriptiveFindings);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function saveData() {
    // Get descriptive findings from local storage
    const descriptiveFindings = serializeToPatientScanDescriptiveFindings(
      JSON.parse(window.localStorage.getItem(LocalStorageKey) || "")
    );

    if (!currentPatient.id || !currentPatientScan.id || !descriptiveFindings) {
      return;
    }
    const accessToken = await getAccessToken();
    if (descriptiveFindings.id) {
      await updatePatientScanDescriptiveFindings(
        accessToken,
        currentPatient.id,
        currentPatientScan.id,
        descriptiveFindings
      );
    } else {
      await createPatientScanDescriptiveFindings(
        accessToken,
        currentPatient.id,
        currentPatientScan.id,
        descriptiveFindings
      );
    }
    window.localStorage.removeItem(LocalStorageKey);
  }

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">Descriptive Findings</h3>
      </div>
      <Form style={{ marginTop: 12, marginBottom: 36 }}>
        <Row>
          <Col>
            <h5>Head and Neck</h5>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col}>
            {/* No label needed */}
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.head_neck || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  head_neck: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Col>
            <h5>Chest</h5>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Lungs and pleura</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.lungs_pleura || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  lungs_pleura: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Cardiovascular</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.cardiovascular || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  cardiovascular: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">
              Lymph nodes and mediastinum
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={
                currentDescirptiveFindings.chest_lymph_nodes_mediastinum || ""
              }
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  chest_lymph_nodes_mediastinum: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Col>
            <h5>Abdomen and Pelvis</h5>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Liver and gallbladder</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.liver_gallbladder || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  liver_gallbladder: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">
              Spleen, pancreas and adrenals
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.spleen_pancreas_adrenals || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  spleen_pancreas_adrenals: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Kidneys and ureters</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.kidneys_ureters || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  kidneys_ureters: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Bowels</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.bowels || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  bowels: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">
              Bladder and reproductive organs
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={
                currentDescirptiveFindings.bladder_reproductive_organs || ""
              }
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  bladder_reproductive_organs: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Lymph nodes</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.abdomen_lymph_nodes || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  abdomen_lymph_nodes: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Vessels</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.abdomen_vessels || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  abdomen_vessels: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Abdominal wall</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.abdominal_wall || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  abdominal_wall: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-3">
          <Col>
            <h5>Muscoskeletal</h5>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col}>
            {/* No label needed */}
            <Form.Control
              size="sm"
              type="text"
              as={TextareaAutosize}
              disabled={!currentPatientScan.id}
              value={currentDescirptiveFindings.muskoskeletal || ""}
              onChange={(e) => {
                setCurrentDescriptiveFindings({
                  ...currentDescirptiveFindings,
                  muskoskeletal: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}
