import * as React from "react";
import { GlobalHotKeys } from "react-hotkeys";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";

import BootstrapIcon from "../../BootstrapIcon";
import { copyText } from "../../../utils/clipboardUtils";
import { useLogContext } from "../../LogToast/LogContext";
import CopyImagesButton from "../CopyImagesButton";
import CopyImageButton from "../CopyImageButton";
import useMiTNMImages from "../useMiTNMImages";
import { MiTNMSectionName, MiTNMSections } from "../../../constants/enums";
import getCombinedTNMCodeLine from "./combinedTNMCodeLineUtils";
import { useAppSettingsContext } from "../../../contexts/AppSettingsContext";
import { usePatientScansContext } from "../../../contexts/PatientScansContext";
import { useParams } from "react-router-dom";

function TNMCodeLine() {
  const { log } = useLogContext();
  const { TNMClassification } = useAppSettingsContext();
  const { currentPatientScan } = usePatientScansContext();

  const { combinedImages, combinedLabels, imageForSection } = useMiTNMImages(
    currentPatientScan.clinical_indication,
    currentPatientScan.marker_info
  );
  const { mitnmSection } = useParams();

  const [combinedTNMCodeLine, combinedLongTNMCodeLine] = React.useMemo(() => {
    return getCombinedTNMCodeLine(
      TNMClassification,
      currentPatientScan.marker_info,
      currentPatientScan.clinical_indication,
      currentPatientScan.prostate_removed
    );
  }, [
    TNMClassification,
    currentPatientScan.marker_info,
    currentPatientScan.clinical_indication,
    currentPatientScan.prostate_removed,
  ]);

  function onClick() {
    copyText(["TNM Code Line:", combinedLongTNMCodeLine].join("\n"))
      .then(() => {
        log("TNM code line copied to clipboard");
      })
      .catch((error) => {
        console.error(error);
        log(error.message);
      });
  }

  const sectionImage = React.useMemo(
    () => imageForSection(mitnmSection as MiTNMSections),
    [mitnmSection, imageForSection]
  );

  return (
    <GlobalHotKeys
      handlers={{
        COPY_CODELINE: onClick,
      }}
      allowChanges={true}
    >
      <InputGroup className="mx-auto" style={{ width: "auto" }}>
        <InputGroup.Text>
          <b>TNM Code Line:</b>
        </InputGroup.Text>
        <FormControl value={combinedTNMCodeLine} readOnly />
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="copy-code-button-tooltip">Copy miTNM codeline</Tooltip>
          }
        >
          <Button variant="outline-secondary" onClick={onClick}>
            <BootstrapIcon name="pencil-square" size={18} />
          </Button>
        </OverlayTrigger>
        {mitnmSection === MiTNMSections.SUMMARY ? (
          <CopyImagesButton
            images={combinedImages}
            labels={combinedLabels}
            log={log}
          />
        ) : (
          <CopyImageButton
            image={sectionImage as Blob}
            label={MiTNMSectionName(mitnmSection as MiTNMSections)}
            log={log}
          />
        )}
      </InputGroup>
    </GlobalHotKeys>
  );
}

export default TNMCodeLine;
