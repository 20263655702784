import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import Footer from "./Footer";

import logo from "../img/TNM-Stager-Logo.svg";

function AuthLayout({ title, body, footer }) {
  React.useEffect(() => {
    document.body.className = "bg-light";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <Container
        fluid="lg"
        style={{ minHeight: "85vh", flexDirection: "column" }}
      >
        <Row className="mt-5">
          <Col>
            <div className="d-flex justify-content-center">
              <Link to="/" className="logo mx-auto">
                <Image alt="TNM Stager Logo" src={logo} height="100" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            {title && <h3 className="text-center my-4">{title}</h3>}
            <Card>
              {body && <Card.Body>{body}</Card.Body>}
              {footer && (
                <Card.Footer className="bg-white d-flex flex-column">
                  {footer}
                </Card.Footer>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

AuthLayout.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
};

export default AuthLayout;
