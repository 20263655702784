import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { usePatientsContext } from "../contexts/PatientsContext";
import {
  GleasonScore,
  Patient,
  gleasonScores,
  initialPatient,
} from "../api/patients";
import { Col, Row, Form } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";

export default function PatientPage(): React.ReactElement {
  const { patients } = usePatientsContext();
  const { patientId } = useParams();

  const [currentPatient, setCurrentPatient] = useState<Patient>(
    initialPatient("")
  );

  React.useEffect(() => {
    const newCurrentPatient =
      patientId && parseInt(patientId) != null
        ? patients.find((p) => p.id === parseInt(patientId))
        : null;

    setCurrentPatient(newCurrentPatient || initialPatient(""));
  }, [patients, patientId]);

  const newPatient = useMemo(
    () => currentPatient.id === null,
    [currentPatient.id]
  );

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">
          {newPatient ? "New patient" : `Patient #${currentPatient.id}`}
        </h3>
      </div>
      <Form style={{ marginTop: 12 }}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="patientId">
            <Form.Label className="mb-0">Patient ID</Form.Label>
            <Form.Control
              type="text"
              value={currentPatient.patient_id}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  patient_id: e.target.value,
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="patientId">
            <Form.Label className="mb-0">Study ID</Form.Label>
            <Form.Control
              type="text"
              value={currentPatient.study_id || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  study_id: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Date of birth</Form.Label>
            <DatePicker
              className="form-control"
              value={
                currentPatient.date_of_birth &&
                currentPatient.date_of_birth.toJSDate()
              }
              onChange={(value: Date | null) => {
                setCurrentPatient({
                  ...currentPatient,
                  date_of_birth: value && DateTime.fromJSDate(value),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Age</Form.Label>
            <Form.Control
              plaintext
              readOnly
              value={`${
                currentPatient.date_of_birth
                  ? Math.floor(
                      -currentPatient.date_of_birth.diffNow("years").years
                    )
                  : 0
              } years`}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">Initial PSA Value (ng/ml)</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              value={currentPatient.initial_psa_value || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  initial_psa_value: parseFloat(e.target.value) || null,
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">Initial Diagnosis Date</Form.Label>
            <DatePicker
              className="form-control"
              value={
                currentPatient.diagnosis_date &&
                currentPatient.diagnosis_date.toJSDate()
              }
              onChange={(value: Date | null) => {
                setCurrentPatient({
                  ...currentPatient,
                  diagnosis_date: value && DateTime.fromJSDate(value),
                });
              }}
            />
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">Gleason Score</Form.Label>
            <Form.Select
              value={currentPatient.gleason_score || "n/a"}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  gleason_score: e.target.value as GleasonScore,
                });
              }}
            >
              <option value="n/a" disabled>
                Select score:
              </option>
              {gleasonScores.map((score) => (
                <option value={score} key={score}>
                  {score}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} lg={3} md={6} sm={12}>
            <Form.Label className="mb-0">cTNM</Form.Label>
            <Form.Control
              type="text"
              value={currentPatient.c_tnm || ""}
              onChange={(e) => {
                setCurrentPatient({
                  ...currentPatient,
                  c_tnm: e.target.value,
                });
              }}
            />
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}
