import React from "react";
import { Coordinates, EllipseCoordinates } from "../../api/patientScans";
import SUVmaxOverlay from "./SUVmaxOverlay";
import { findMarker } from "../../utils/svgUtils";

interface useSUVmaxOverlayProps {
  parentRef: React.MutableRefObject<SVGSVGElement | null>;
  markers: { [key: string]: Coordinates | EllipseCoordinates };
  updateMarkers: (newMarkers: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) => void;
}

interface useSUVmaxOverlayType {
  overlay: React.ReactElement;
  onContextMenu: (event: React.MouseEvent) => void;
}

export default function useSUVmaxOverlay({
  parentRef,
  markers,
  updateMarkers,
}: useSUVmaxOverlayProps): useSUVmaxOverlayType {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [pointerX, setPointerX] = React.useState(0);
  const [pointerY, setPointerY] = React.useState(0);
  const [currentMarkerId, setCurrentMarkerId] = React.useState<string>();
  const [currentMarker, setCurrentMarker] = React.useState<
    Coordinates | EllipseCoordinates
  >();

  function onHide() {
    setShowOverlay(false);
  }

  function showOverlayForMarker(
    pointerX: number,
    pointerY: number,
    targetMarkerID: string,
    targetMarker: Coordinates | EllipseCoordinates
  ) {
    setShowOverlay(true);
    const bodyOffsets = parentRef?.current?.getBoundingClientRect() || {
      x: 0,
      y: 0,
    };
    setPointerX(pointerX - bodyOffsets.x);
    setPointerY(pointerY - bodyOffsets.y);
    setCurrentMarkerId(targetMarkerID);
    setCurrentMarker(targetMarker);
  }

  function onContextMenu(event: React.MouseEvent) {
    // Show the SUVmax overlay on right click
    const [found, elementId] = findMarker(4, event.target);
    if (found) {
      event.preventDefault();
      const targetMarker = markers[elementId];

      showOverlayForMarker(
        event.clientX,
        event.clientY,
        elementId,
        targetMarker
      );
    }
  }

  function updateSUVmax(markerId: string, newSUVmax: number) {
    const marker = markers[markerId];

    if (marker == null) {
      console.warn("Marker not found");
      return;
    }

    const newMarkers = structuredClone(markers);
    newMarkers[markerId] = {
      ...marker,
      suvMax: newSUVmax,
    };
    updateMarkers(newMarkers);
  }

  const overlay = (
    <SUVmaxOverlay
      show={showOverlay}
      onHide={onHide}
      top={pointerY}
      left={pointerX}
      markerId={currentMarkerId}
      marker={currentMarker}
      updateSUVmax={updateSUVmax}
    />
  );

  return {
    overlay,
    onContextMenu,
  };
}
