import * as React from "react";
import { Row, Col, Carousel } from "react-bootstrap";

import { useLogContext } from "../LogToast/LogContext";
import { MiTNMSections, MiTNMSectionsArray } from "../../constants/enums";
import MiTNMNav from "./MiTNMNav";
import { GlobalHotKeys } from "react-hotkeys";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";

import { useAuthContext } from "../../contexts/AuthContext";
import { useOrgContext } from "../../contexts/OrgContext";
import { loadCurrentStep } from "../../utils/researchAutomatedWorkflowUtils";
import { useStudyParticipationContext } from "../../contexts/StudyParticipationContext";
import PatientFeedbackModal from "../PatientFeedbackModal";
import useMiTNMImages from "./useMiTNMImages";
import MiTNMSectionContent from "./MiTNMSectionContent";
import { initialPatientScanForProduct } from "../../api/patientScans";
import { useNavigate, useParams } from "react-router-dom";

function MiTNM() {
  const { getAccessToken, currentUser } = useAuthContext();
  const { currentOrg } = useOrgContext();
  const { currentPatient, setCurrentPatient } = usePatientsContext();
  const {
    currentPatientScan,
    setCurrentPatientScan,
    updateTumorMarkers,
    updateBoneMarkers,
    updateOtherMarkers,
    updatePelvicMarkers,
    markerColor,
  } = usePatientScansContext();
  const {
    currentUserStudyParticipation,
    setCurrentUserStudyParticipation,
    showFeedbackModal,
    setShowFeedbackModal,
  } = useStudyParticipationContext();

  const navigate = useNavigate();
  const { mitnmSection } = useParams();

  const miTNMImages = useMiTNMImages(
    currentPatientScan.clinical_indication,
    currentPatientScan.marker_info
  );

  // RESEARCH-ONLY: Load current step every time study participation changes
  // TODO: Figure out whether we can remove the useEffect call
  React.useEffect(() => {
    if (currentOrg && currentOrg.product === "research") {
      (async () => {
        const accessToken = await getAccessToken();
        loadCurrentStep(
          accessToken,
          currentUser,
          navigate,
          currentPatient,
          setCurrentPatient,
          initialPatientScanForProduct("research"),
          setCurrentPatientScan,
          currentUserStudyParticipation,
          setCurrentUserStudyParticipation,
          () => {
            setShowFeedbackModal(true);
          },
          log
        );
      })();
    }
  }, [currentUserStudyParticipation]);

  const { log } = useLogContext();

  return (
    <GlobalHotKeys
      handlers={{
        LOCAL_LESIONS_STEP: () => {
          navigate("/home/imaging_findings/tnm_classification/local_lesions");
        },
        PELVIC_LN_STEP: () => {
          navigate(
            "/home/imaging_findings/tnm_classification/pelvic_and_inguinal_lymph_nodes"
          );
        },
        OSSEOUS_METASTASES_STEP: () => {
          navigate(
            "/home/imaging_findings/tnm_classification/osseous_metastases"
          );
        },
        DISTANT_LN_AND_ORGANS_STEP: () => {
          navigate(
            "/home/imaging_findings/tnm_classification/distant_lymph_nodes_and_organs"
          );
        },
        REVIEW_STEP: () => {
          navigate("/home/imaging_findings/tnm_classification/summary");
        },
      }}
      allowChanges={true}
    >
      {currentOrg && currentOrg.product === "research" && (
        <PatientFeedbackModal
          showModal={showFeedbackModal}
          onModalHide={() => setShowFeedbackModal(false)}
        />
      )}
      <header
        className="d-flex align-items-center justify-content-center pt-2"
        style={{ minHeight: 78 }}
      >
        <MiTNMNav baseURL="/home/imaging_findings/tnm_classification" />
      </header>
      <Row className="gx-0">
        <Col>
          <Carousel
            className="w-100"
            variant="dark"
            interval={null}
            keyboard={false}
            activeIndex={
              MiTNMSectionsArray.indexOf(mitnmSection as MiTNMSections) || 0
            }
            onSelect={(selectedIndex) => {
              const newSection = MiTNMSectionsArray[selectedIndex];
              navigate(
                `/home/imaging_findings/tnm_classification/${newSection}`
              );
            }}
          >
            {MiTNMSectionsArray.map((section: MiTNMSections, index: number) => (
              <Carousel.Item key={index}>
                <MiTNMSectionContent
                  sectionName={section}
                  images={miTNMImages}
                  clinicalIndication={currentPatientScan.clinical_indication}
                  prostateRemoved={currentPatientScan.prostate_removed}
                  markerInfo={currentPatientScan.marker_info}
                  updateTumorMarkers={updateTumorMarkers}
                  updatePelvicMarkers={updatePelvicMarkers}
                  updateBoneMarkers={updateBoneMarkers}
                  updateOtherMarkers={updateOtherMarkers}
                  markerColor={markerColor}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </GlobalHotKeys>
  );
}

export default MiTNM;
