import { DateTime } from "luxon";
import { PatientScan } from "../../../api/patientScans";
import { PatientTreatment } from "../../../api/patientTreatments";
import { Patient } from "../../../api/patients";
import { TestResult } from "../../../api/testResults";
import {
  ImagingModality,
  clinicalIndicationName,
  clinicalStateName,
} from "../../../constants/enums";
import { calculatePSADoublingTime } from "../../../utils/psaValueUtils";
import {
  isOngoingTreatment,
  isSameDayTreatment,
} from "../../../utils/treatmentUtils";
import { PatientScanDescriptiveFindings } from "../../../api/patientScanDescriptiveFindings";

export function getClinicalInformation(
  patient: Patient,
  patientScan: PatientScan,
  patientPSAValues: TestResult[],
  patientTreatments: PatientTreatment[]
) {
  let clinicalInformation = "";

  // Sort PSA values by distance to the scan date
  // Defer to the most recent if the scan date is not defined
  function psaValueSortCriteria(a: TestResult, b: TestResult): number {
    if (patientScan.date == null) {
      return (
        a.taken_at.diffNow().milliseconds - b.taken_at.diffNow().milliseconds
      );
    }
    return (
      Math.abs(b.taken_at.diff(patientScan.date).milliseconds) -
      Math.abs(a.taken_at.diff(patientScan.date).milliseconds)
    );
  }

  const sortedPSAValues = patientPSAValues.sort(psaValueSortCriteria);
  const psaDoublingTime = calculatePSADoublingTime(sortedPSAValues);

  // Date of birth
  if (patient.date_of_birth) {
    clinicalInformation += `${
      patient.date_of_birth
        ? Math.floor(-patient.date_of_birth.diffNow("years").years)
        : 0
    } years old patient`;
  } else {
    clinicalInformation += "Patient";
  }

  if (patientScan.clinical_state) {
    clinicalInformation += ` with ${clinicalStateName(
      patientScan.clinical_state
    ).toLowerCase()}`;
  }

  // Clinical indication is always defined
  clinicalInformation += ` referred for ${clinicalIndicationName(
    patientScan.clinical_indication
  ).toLowerCase()}.`;

  // Initial diagnosis
  if (patient.diagnosis_date) {
    clinicalInformation += ` Initial diagnosis on ${patient.diagnosis_date.toLocaleString(
      DateTime.DATE_SHORT
    )}`;
    if (patient.gleason_score) {
      clinicalInformation += ` with Gleason score ${patient.gleason_score}`;
    }
    clinicalInformation += ".";
  }

  // Initial PSA value
  if (patient.initial_psa_value) {
    clinicalInformation += ` Initial PSA was ${patient.initial_psa_value} ng/ml.`;
  }

  // Most recent PSA value
  if (patientPSAValues.length > 0) {
    const mostRecentPSAValue = sortedPSAValues[patientPSAValues.length - 1];
    clinicalInformation += ` The most recent PSA value from ${mostRecentPSAValue.taken_at.toLocaleString(
      DateTime.DATE_SHORT
    )} is ${mostRecentPSAValue.value} ng/ml.`;
  }

  // PSA doubling time
  if (psaDoublingTime) {
    clinicalInformation += ` PSA doubling time is ${psaDoublingTime} months.`;
  }

  if (patientTreatments.length > 0) {
    clinicalInformation += " Treatment history include";
    clinicalInformation += patientTreatments
      .map((treatment) => {
        // If same-day treatment, leave suffix empty
        let treatmentSuffix = "";
        if (isOngoingTreatment(treatment)) {
          treatmentSuffix = " - ongoing";
        } else if (!isSameDayTreatment(treatment)) {
          treatmentSuffix = ` - ${treatment.ended_at!.toLocaleString(
            DateTime.DATE_SHORT
          )}`;
        }
        return ` ${
          treatment.treatment_name
        } (${treatment.started_at.toLocaleString(
          DateTime.DATE_SHORT
        )}${treatmentSuffix})`;
      })
      .join(", ");
    clinicalInformation += ".";
  }

  return clinicalInformation;
}

export function getTechnicalInformation(patientScan: PatientScan) {
  // Technical information
  let technicalInformation = "The patient was injected i.v.";

  if (patientScan.date) {
    technicalInformation += ` on ${patientScan.date?.toLocaleString(
      DateTime.DATE_SHORT
    )}`;
  }

  if (patientScan.injected_activity) {
    technicalInformation += ` with ${patientScan.injected_activity} MBq of`;
  }

  if (patientScan.radiopharmaceutical) {
    technicalInformation += " " + patientScan.radiopharmaceutical;
  } else {
    technicalInformation += " unknown pharmaceutical";
  }

  technicalInformation += ".";

  technicalInformation += ` Body ${
    patientScan.modality ? patientScan.modality : "scan"
  } from the vertex of the skull to the mid-thigh was performed`;

  if (patientScan.scanner_type || patientScan.scanner_model) {
    technicalInformation += " using";
  }

  if (patientScan.scanner_type) {
    technicalInformation += ` ${patientScan.scanner_type}`;
  }

  if (patientScan.scanner_model) {
    technicalInformation += ` ${patientScan.scanner_model}`;
  }

  if (patientScan.location) {
    technicalInformation += ` at ${patientScan.location}`;
  }

  technicalInformation += ".";

  if (patientScan.reconstruction_method) {
    technicalInformation += ` PET data was acquired by using ${patientScan.reconstruction_method}.`;
  }

  technicalInformation += " Images were obtained";

  if (patientScan.injected_time) {
    technicalInformation += ` ${patientScan.injected_time} minutes after the radiopharmaceutical injection`;
  }

  if (
    patientScan.modality === ImagingModality.PET_CT ||
    patientScan.modality === ImagingModality.PET_MRI
  ) {
    technicalInformation += ` with concurrent ${
      patientScan.contrast || false
        ? "contrast-enhanced"
        : "non-contrast-enhanced"
    } ${
      patientScan.modality === ImagingModality.PET_CT ? "CT" : "MRI"
    } for attenuation correction and anatomical localization`;
  }

  technicalInformation += ".";

  // Contrast agent
  if (patientScan.contrast !== null) {
    if (patientScan.contrast === "None") {
      technicalInformation += ` No contrast agent administered.`;
    } else {
      technicalInformation += ` Contrast agent (${patientScan.contrast}) was administered.`;
    }
  }

  // Diuretics
  if (patientScan.diuretics !== null) {
    if (patientScan.diuretics === "None") {
      technicalInformation += ` No diuretic administered.`;
    } else {
      technicalInformation += ` Diuretic (${patientScan.diuretics}) was administered.`;
    }
  }

  return technicalInformation;
}

export function getDescriptiveFindings(
  descriptiveFindings: PatientScanDescriptiveFindings
): [string, string, string, string] {
  const headNeckFindings = descriptiveFindings.head_neck;
  const chestFindings = [
    `Lungs and pleura: ${descriptiveFindings.lungs_pleura}`,
    `Cardiovascular: ${descriptiveFindings.cardiovascular}`,
    `Lymph nodes and mediastinum: ${descriptiveFindings.chest_lymph_nodes_mediastinum}`,
  ].join("\n");
  const abdomenPelvisFindings = [
    `Liver and gallbladder: ${descriptiveFindings.liver_gallbladder}`,
    `Spleen, pancreas, and adrenals: ${descriptiveFindings.spleen_pancreas_adrenals}`,
    `Kidneys and ureters: ${descriptiveFindings.kidneys_ureters}`,
    `Bowels: ${descriptiveFindings.bowels}`,
    `Bladder and reproductive organs: ${descriptiveFindings.bladder_reproductive_organs}`,
    `Lymph nodes: ${descriptiveFindings.abdomen_lymph_nodes}`,
    `Vessels: ${descriptiveFindings.abdomen_vessels}`,
    `Abdominal wall: ${descriptiveFindings.abdominal_wall}`,
  ].join("\n");
  const muskoskeletalFindings = descriptiveFindings.muskoskeletal;
  return [
    headNeckFindings,
    chestFindings,
    abdomenPelvisFindings,
    muskoskeletalFindings,
  ];
}
