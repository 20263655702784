import * as React from "react";
import PropTypes from "prop-types";
import { NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

import KeyMapModal from "./KeyMapModal";
// import TutorialModal from "./TutorialModal";
// import IntroModal from "./IntroModal";
import SettingsModal from "./SettingsModal";
import BootstrapIcon from "./BootstrapIcon";

import { useAuthContext } from "../contexts/AuthContext";
import { useAppSettingsContext } from "../contexts/AppSettingsContext";

import logoURL from "../img/TNM-Stager-Logo.svg";
import { signOut } from "../api/auth";
import { useOrgContext } from "../contexts/OrgContext";
import SidebarFooter from "./Sidebar/SidebarFooter";

// let showIntroSet = false;

function MiTNMHeader({ children }) {
  const [showKeyMapModal, setShowKeyMapModal] = React.useState(false);
  // const [showTutorialModal, setShowTutorialModal] = React.useState(false);
  // const [showIntroModal, setShowIntroModal] = React.useState(false);
  const [showSettingsModal, setShowSettingsModal] = React.useState(false);

  const navigate = useNavigate();
  const { currentUser, getAccessToken, onSignOut } = useAuthContext();
  const { currentOrg } = useOrgContext();
  const { showIntro } = useAppSettingsContext();

  // if (!showIntroSet && showIntro !== undefined) {
  //   setShowIntroModal(showIntro);
  //   showIntroSet = true;
  // }

  return (
    <>
      <KeyMapModal
        showModal={showKeyMapModal}
        onModalHide={() => {
          setShowKeyMapModal(false);
        }}
      />
      {/* <TutorialModal
        showModal={showTutorialModal}
        onModalHide={() => {
          setShowTutorialModal(false);
        }}
      />
      <IntroModal
        showModal={showIntroModal}
        onModalHide={() => {
          setShowIntroModal(false);
        }}
        showTutorialModal={() => {
          setShowTutorialModal(true);
        }}
      /> */}
      <SettingsModal
        showModal={showSettingsModal}
        onModalHide={() => {
          setShowSettingsModal(false);
        }}
      />

      <header
        className={classNames(
          "d-flex",
          "align-items-center",
          "justify-content-between",
          "pt-2"
        )}
        style={{ minHeight: 78 }}
      >
        <Link to="/" className="d-flex align-items-center">
          <img alt="TNM Stager logo" src={logoURL} height={60} />
        </Link>
        {children}
        <div className="d-flex flex-row justify-content-end align-items-center">
          <SidebarFooter />
          <NavDropdown title={<BootstrapIcon name="person" size={32} />}>
            <NavDropdown.ItemText>
              {currentUser.first_name} {currentUser.last_name}
            </NavDropdown.ItemText>
            <NavDropdown.ItemText>
              <small>{currentOrg && currentOrg.name}</small>
            </NavDropdown.ItemText>
            <NavDropdown.Divider />
            {currentOrg?.product !== "basic" &&
              currentUser.role === "org_admin" && (
                <>
                  <NavDropdown.Item as={Link} to="/settings/org">
                    {currentOrg?.product === "research"
                      ? "Study management"
                      : "Data export"}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </>
              )}
            <NavDropdown.Item
              onClick={() => {
                setShowSettingsModal(true);
              }}
            >
              Settings
            </NavDropdown.Item>
            {/* <NavDropdown.Item
              onClick={() => {
                setShowTutorialModal(true);
              }}
            >
              Tutorial
            </NavDropdown.Item> */}
            <NavDropdown.Item
              onClick={() => {
                setShowKeyMapModal(true);
              }}
            >
              Key Bindings
            </NavDropdown.Item>
            <NavDropdown.Item
              href="https://forms.office.com/r/gNm78mjpPQ"
              target="_blank"
            >
              Feedback
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={async () => {
                try {
                  const accessToken = await getAccessToken();
                  await signOut(accessToken);
                } finally {
                  onSignOut();
                  navigate("/");
                }
              }}
            >
              Sign out
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </header>
    </>
  );
}

MiTNMHeader.propTypes = {
  children: PropTypes.node,
};

export default MiTNMHeader;
