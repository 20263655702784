import {
  BoneMarkerInfo,
  OtherMarkerInfo,
  PatientScanMarkerInfo,
  PelvicMarkerInfo,
  TumorMarkerInfo,
} from "../../api/patientScans";
import { ClinicalIndication, MiTNMSections } from "../../constants/enums";
import BoneMetastases from "./BoneMetastases";
import OtherOrganMetastases from "./OtherOrganMetastases";
import PelvicLymphNodeMetastases from "./PelvicLymphNodeMetastases";
import ProstateTumor from "./ProstateTumor";
import Summary from "./Summary";
import { MiTNMImages } from "./useMiTNMImages";

interface MiTNMSectionContentProps {
  sectionName: MiTNMSections;
  images: MiTNMImages;
  clinicalIndication: ClinicalIndication;
  prostateRemoved: boolean;
  markerInfo: PatientScanMarkerInfo;
  updateTumorMarkers: (markers: TumorMarkerInfo) => void;
  updatePelvicMarkers: (markers: PelvicMarkerInfo) => void;
  updateBoneMarkers: (markers: BoneMarkerInfo) => void;
  updateOtherMarkers: (markers: OtherMarkerInfo) => void;
  markerColor: string;
}

export default function MiTNMSectionContent({
  sectionName,
  images,
  clinicalIndication,
  prostateRemoved,
  markerInfo,
  updateTumorMarkers,
  updatePelvicMarkers,
  updateBoneMarkers,
  updateOtherMarkers,
  markerColor,
}: MiTNMSectionContentProps): React.ReactElement {
  switch (sectionName) {
    case MiTNMSections.PROSTATE_TUMOR:
      return (
        <ProstateTumor
          markerInfo={markerInfo.prostate_tumor}
          updateTumorMarkers={updateTumorMarkers}
          clinicalIndication={clinicalIndication}
          prostateRemoved={prostateRemoved}
          markerColor={markerColor}
          key="ProstateTumor"
        />
      );
    case MiTNMSections.PELVIC_LYMPH_NODE_METASTASES:
      return (
        <PelvicLymphNodeMetastases
          markerInfo={markerInfo.pelvic_lymph_node_metastases}
          updatePelvicMarkers={updatePelvicMarkers}
          markerColor={markerColor}
          key="PelvicLymphNodeMetastases"
        />
      );
    case MiTNMSections.BONE_METASTASES:
      return (
        <BoneMetastases
          markerInfo={markerInfo.bone_metastases}
          updateBoneMarkers={updateBoneMarkers}
          markerColor={markerColor}
          key="BoneMetastases"
        />
      );
    case MiTNMSections.OTHER_ORGAN_METASTASES:
      return (
        <OtherOrganMetastases
          markerInfo={markerInfo.other_organ_metastases}
          updateOtherMarkers={updateOtherMarkers}
          markerColor={markerColor}
          key="OtherOrganMetastases"
        />
      );
    case MiTNMSections.SUMMARY:
      return (
        <Summary
          prostateImageSnapshot={images.tumorImage}
          pelvicImageSnapshot={images.pelvicImage}
          boneImageSnapshot={images.boneImage}
          otherOrganImageSnapshot={images.otherImage}
          prostateImageEmpty={images.tumorImageEmpty}
          pelvicImageEmpty={images.pelvicImageEmpty}
          boneImageEmpty={images.boneImageEmpty}
          otherOrganImageEmpty={images.otherImageEmpty}
          key="Summary"
        />
      );
    default:
      return <></>;
  }
}
