import CopyClinicalReportButton from "../MiTNM/ClinicalReport/CopyClinicalReportButton";
import GeneratePDFReportButton from "../MiTNM/GeneratePDFReportButton";
import { usePatientsContext } from "../../contexts/PatientsContext";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientRecipCriteriaContext } from "../../contexts/PatientRecipCriteriaContext";
import { useMemo } from "react";
import getCombinedTNMCodeLine from "../MiTNM/TNMCodeLine/combinedTNMCodeLineUtils";
import useOncologicalFindings from "../MiTNM/ClinicalReport/useOncologicalFindings";
import { useAppSettingsContext } from "../../contexts/AppSettingsContext";
import useMiTNMImages from "../MiTNM/useMiTNMImages";

export default function SidebarFooter() {
  const { TNMClassification } = useAppSettingsContext();
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const { currentPatientScanRecipCriteria } = usePatientRecipCriteriaContext();

  const [, combinedLongTNMCodeLine] = useMemo(() => {
    return getCombinedTNMCodeLine(
      TNMClassification,
      currentPatientScan.marker_info,
      currentPatientScan.clinical_indication,
      currentPatientScan.prostate_removed
    );
  }, [
    TNMClassification,
    currentPatientScan.marker_info,
    currentPatientScan.clinical_indication,
    currentPatientScan.prostate_removed,
  ]);

  const oncologicalFindings = useOncologicalFindings(
    currentPatientScan.marker_info,
    currentPatientScan.prostate_removed
  );

  const { combinedImages, combinedLabels } = useMiTNMImages(
    currentPatientScan.clinical_indication,
    currentPatientScan.marker_info
  );

  return (
    <div className="d-flex justify-content-center flex-nowrap">
      <CopyClinicalReportButton
        patient={currentPatient}
        patientScan={currentPatientScan}
        patientRecipCriteria={currentPatientScanRecipCriteria}
        tnmCodeLine={combinedLongTNMCodeLine}
        oncologicalFindings={oncologicalFindings}
        images={combinedImages}
        labels={combinedLabels}
      />
      <GeneratePDFReportButton
        images={combinedImages}
        labels={combinedLabels}
        patient={currentPatient}
        patientScan={currentPatientScan}
        patientRecipCriteria={currentPatientScanRecipCriteria}
        tnmCodeLine={combinedLongTNMCodeLine}
        oncologicalFindings={oncologicalFindings}
      />
    </div>
  );
}
