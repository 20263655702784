import { useOrgContext } from "../../contexts/OrgContext";
import ClinicPatientScansManagement from "./ClinicPatientScansManagement";
import ResearchPatientScansManagement from "./ResearchPatientScansManagement";

export default function PatientScansManagement(): React.ReactElement {
  const { currentOrg } = useOrgContext();

  switch (currentOrg?.product) {
    case "clinic":
      return <ClinicPatientScansManagement />;
    case "research":
      return <ResearchPatientScansManagement />;
    case "basic":
    default:
      return <></>;
  }
}
