import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { TestResult } from "../../api/testResults";
import { DateTime } from "luxon";
import BootstrapIcon from "../BootstrapIcon";
import React from "react";
import DatePicker from "react-date-picker";

interface TestResultRowEditProps {
  testResult: TestResult;
  showTestName: boolean;
  onUpdateTestResult: (newTestResult: TestResult) => void;
  onDiscardChanges: () => void;
}

export default function TestResultRoEdit({
  testResult,
  showTestName,
  onUpdateTestResult,
  onDiscardChanges,
}: TestResultRowEditProps): React.ReactElement {
  const [testName, setTestName] = React.useState(testResult.test_name);
  const [value, setValue] = React.useState(testResult.value);
  const [takenAt, setTakenAt] = React.useState(testResult.taken_at);

  return (
    <Row>
      {showTestName && (
        <Form.Group as={Col} xs="3">
          <Form.Control
            type="text"
            value={testName}
            onChange={(e) => {
              setTestName(e.target.value);
            }}
          />
        </Form.Group>
      )}
      <Form.Group as={Col} xs={showTestName ? "3" : "6"}>
        <Form.Control
          type="number"
          step="0.01"
          value={value || ""}
          onChange={(e) => {
            setValue(parseFloat(e.target.value) || null);
          }}
        />
      </Form.Group>
      <Form.Group as={Col} xs={2}>
        <DatePicker
          className="form-control"
          value={takenAt && takenAt.toJSDate()}
          onChange={(value: Date | null) => {
            if (value) {
              setTakenAt(DateTime.fromJSDate(value));
            }
          }}
        />
      </Form.Group>
      <Form.Group as={Col} xs={{ span: 2, offset: 2 }}>
        <ButtonGroup size="sm" style={{ marginTop: 4 }}>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-submit">Save changes</Tooltip>}
          >
            <Button
              variant="outline-success"
              onClick={() => {
                onUpdateTestResult({
                  ...testResult,
                  test_name: showTestName ? testName : testResult.test_name,
                  value,
                  taken_at: takenAt,
                });
              }}
            >
              <BootstrapIcon name="check2" size={16} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-discard">Discard changes</Tooltip>}
          >
            <Button
              variant="outline-danger"
              onClick={() => {
                setTestName(testResult.test_name);
                setValue(testResult.value);
                setTakenAt(testResult.taken_at);
                onDiscardChanges();
              }}
            >
              <BootstrapIcon name="x" size={16} />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </Form.Group>
    </Row>
  );
}
