import { shortRegionName } from "../../../constants/regionNames";
import { handleProstateRegions } from "../../../utils/regionNameUtils";
import {
  ClinicalIndication,
  TNMClassifications,
} from "../../../constants/enums";
import { TumorMarkerInfo } from "../../../api/patientScans";

export default function getProstateTumorTNMCode(
  tnmClassification: TNMClassifications,
  markerInfo: TumorMarkerInfo,
  clinicalIndication: ClinicalIndication,
  priorRadicalProstatectomy: boolean
): [string, string] {
  const { marker_count_by_region, extraprostatic_involvement } = markerInfo;

  let prostateTumorTNMCode = "T0";
  let longProstateTumorTNMCode = "T0";

  if (clinicalIndication === ClinicalIndication.STAGING) {
    const leftHalfInnerAffected =
      marker_count_by_region.hasOwnProperty("Apex-TLI") ||
      marker_count_by_region.hasOwnProperty("Apex-BLI") ||
      marker_count_by_region.hasOwnProperty("Mid-TLI") ||
      marker_count_by_region.hasOwnProperty("Mid-BLI") ||
      marker_count_by_region.hasOwnProperty("Base-TLI") ||
      marker_count_by_region.hasOwnProperty("Base-BLI");

    const rightHalfInnerAffected =
      marker_count_by_region.hasOwnProperty("Apex-TRI") ||
      marker_count_by_region.hasOwnProperty("Apex-BRI") ||
      marker_count_by_region.hasOwnProperty("Mid-TRI") ||
      marker_count_by_region.hasOwnProperty("Mid-BRI") ||
      marker_count_by_region.hasOwnProperty("Base-TRI") ||
      marker_count_by_region.hasOwnProperty("Base-BRI");

    if (tnmClassification === TNMClassifications.PROMISE_MITNM_V2) {
      if (leftHalfInnerAffected && rightHalfInnerAffected) {
        prostateTumorTNMCode = "T2m";
      } else if (leftHalfInnerAffected || rightHalfInnerAffected) {
        prostateTumorTNMCode = "T2u";
      }
    } else if (tnmClassification === TNMClassifications.AJCC_TNM) {
      if (leftHalfInnerAffected && rightHalfInnerAffected) {
        prostateTumorTNMCode = "T2c";
      } else if (leftHalfInnerAffected || rightHalfInnerAffected) {
        const bothLobesAffected =
          (marker_count_by_region.hasOwnProperty("Apex-TLI") &&
            marker_count_by_region.hasOwnProperty("Apex-BLI")) ||
          (marker_count_by_region.hasOwnProperty("Apex-TRI") &&
            marker_count_by_region.hasOwnProperty("Apex-BRI")) ||
          (marker_count_by_region.hasOwnProperty("Mid-TLI") &&
            marker_count_by_region.hasOwnProperty("Mid-BLI")) ||
          (marker_count_by_region.hasOwnProperty("Mid-TRI") &&
            marker_count_by_region.hasOwnProperty("Mid-BRI")) ||
          (marker_count_by_region.hasOwnProperty("Base-TLI") &&
            marker_count_by_region.hasOwnProperty("Base-BLI")) ||
          (marker_count_by_region.hasOwnProperty("Base-TRI") &&
            marker_count_by_region.hasOwnProperty("Base-BRI"));
        if (bothLobesAffected) {
          prostateTumorTNMCode = "T2b";
        } else {
          prostateTumorTNMCode = "T2a";
        }
      }
    }

    const midAffected =
      marker_count_by_region.hasOwnProperty("Apex-TLM") ||
      marker_count_by_region.hasOwnProperty("Apex-BLM") ||
      marker_count_by_region.hasOwnProperty("Apex-BRM") ||
      marker_count_by_region.hasOwnProperty("Apex-TRM") ||
      marker_count_by_region.hasOwnProperty("Mid-TLM") ||
      marker_count_by_region.hasOwnProperty("Mid-BLM") ||
      marker_count_by_region.hasOwnProperty("Mid-BRM") ||
      marker_count_by_region.hasOwnProperty("Mid-TRM") ||
      marker_count_by_region.hasOwnProperty("Base-TLM") ||
      marker_count_by_region.hasOwnProperty("Base-BLM") ||
      marker_count_by_region.hasOwnProperty("Base-BRM") ||
      marker_count_by_region.hasOwnProperty("Base-TRM");

    if (midAffected) {
      prostateTumorTNMCode = "T3a";
    }

    const seminalVesiculesAffected =
      marker_count_by_region.hasOwnProperty("LSV") ||
      marker_count_by_region.hasOwnProperty("RSV");

    if (seminalVesiculesAffected) {
      prostateTumorTNMCode = "T3b";
    }

    const bladderAffected = marker_count_by_region.hasOwnProperty("Bladder");
    const outerAffected =
      marker_count_by_region.hasOwnProperty("Apex-TLO") ||
      marker_count_by_region.hasOwnProperty("Apex-BLO") ||
      marker_count_by_region.hasOwnProperty("Apex-BRO") ||
      marker_count_by_region.hasOwnProperty("Apex-TRO") ||
      marker_count_by_region.hasOwnProperty("Mid-TLO") ||
      marker_count_by_region.hasOwnProperty("Mid-BLO") ||
      marker_count_by_region.hasOwnProperty("Mid-BRO") ||
      marker_count_by_region.hasOwnProperty("Mid-TRO") ||
      marker_count_by_region.hasOwnProperty("Base-TLO") ||
      marker_count_by_region.hasOwnProperty("Base-BLO") ||
      marker_count_by_region.hasOwnProperty("Base-BRO") ||
      marker_count_by_region.hasOwnProperty("Base-TRO") ||
      marker_count_by_region.hasOwnProperty("EPE");

    if (bladderAffected || outerAffected) {
      prostateTumorTNMCode = "T4";
    }
  }

  if (clinicalIndication === ClinicalIndication.RESTAGING) {
    if (priorRadicalProstatectomy) {
      // Prostate removed
      if (Object.keys(marker_count_by_region).length > 0) {
        prostateTumorTNMCode = "Tr";
      }
    } else {
      const prostateAffected =
        marker_count_by_region.hasOwnProperty("Prostate");

      if (prostateAffected) {
        prostateTumorTNMCode = "T2";
      }

      const prostateCapsuleAffected =
        marker_count_by_region.hasOwnProperty("Prostate-Capsule");

      if (prostateCapsuleAffected) {
        prostateTumorTNMCode = "T3a";
      }

      const seminalVesiculesAffected =
        marker_count_by_region.hasOwnProperty("LSV") ||
        marker_count_by_region.hasOwnProperty("RSV");

      if (seminalVesiculesAffected) {
        prostateTumorTNMCode = "T3b";
      }

      const outerAffected =
        marker_count_by_region.hasOwnProperty("Bladder") ||
        marker_count_by_region.hasOwnProperty("Rectum") ||
        marker_count_by_region.hasOwnProperty("PFM-Right") ||
        marker_count_by_region.hasOwnProperty("PFM-Left") ||
        marker_count_by_region.hasOwnProperty("ES");

      if (outerAffected) {
        prostateTumorTNMCode = "T4";
      }
    }
  }

  const displayAffectedRegions = new Set();
  handleProstateRegions(Object.keys(marker_count_by_region)).forEach(
    (region) => {
      displayAffectedRegions.add(shortRegionName(region));
    }
  );
  if (extraprostatic_involvement.length > 0) {
    displayAffectedRegions.add(`EPI-${extraprostatic_involvement}`);
  }

  if (displayAffectedRegions.size > 0) {
    longProstateTumorTNMCode = `${prostateTumorTNMCode} (${Array.from(
      displayAffectedRegions
    ).join(",")})`;
  }

  return [prostateTumorTNMCode, longProstateTumorTNMCode];
}
