import { PatientScanMarkerInfo } from "../../../api/patientScans";
import {
  ClinicalIndication,
  TNMClassifications,
} from "../../../constants/enums";
import getPelvicLymphNodeTNMCode from "../PelvicLymphNodeMetastases/pelvicLymphNodeTNMCodeUtils";
import getBoneTNMCode from "../BoneMetastases/boneTNMCodeUtils";
import {
  getOtherLymphNodeTNMCode,
  getOtherOrganTNMCode,
} from "../OtherOrganMetastases/otherOrganTNMCodeUtils";
import getProstateTumorTNMCode from "../ProstateTumor/prostateTumorTNMCodeUtils";

export default function getCombinedTNMCodeLine(
  tnmClassification: TNMClassifications,
  markerInfo: PatientScanMarkerInfo,
  clinicalIndication: ClinicalIndication,
  priorRadicalProstatectomy: boolean
): [string, string] {
  const [pelvicLymphNodeTNMCodeLine, longPelvicLymphNodeTNMCodeLine] =
    getPelvicLymphNodeTNMCode(
      tnmClassification,
      markerInfo.pelvic_lymph_node_metastases
    );

  const [boneTNMCodeLine, longBoneTNMCodeLine] = getBoneTNMCode(
    markerInfo.bone_metastases
  );
  const [otherLymphNideTNMCodeLine, longOtherLymphNideTNMCodeLine] =
    getOtherLymphNodeTNMCode(
      markerInfo.pelvic_lymph_node_metastases,
      markerInfo.other_organ_metastases
    );
  const [otherOrganTNMCodeLine, longOtherOrganTNMCodeLine] =
    getOtherOrganTNMCode(markerInfo.other_organ_metastases);
  const [prostateTumorTNMCodeLine, longProstateTumorTNMCodeLine] =
    getProstateTumorTNMCode(
      tnmClassification,
      markerInfo.prostate_tumor,
      clinicalIndication,
      priorRadicalProstatectomy
    );

  const combinedM1CodeLine = () => {
    let newCodeLine = "M1";
    if (otherLymphNideTNMCodeLine !== "") {
      newCodeLine += "a";
    }
    if (boneTNMCodeLine !== "") {
      newCodeLine += "b";
    }
    if (otherOrganTNMCodeLine !== "") {
      newCodeLine += "c";
    }
    if (newCodeLine === "M1") return "M0";
    return newCodeLine;
  };

  const tnmCodeLine = [
    prostateTumorTNMCodeLine,
    pelvicLymphNodeTNMCodeLine,
    combinedM1CodeLine(),
  ]
    .filter((code) => code !== "")
    .join(" ");

  const longTNMCodeLine = [
    longProstateTumorTNMCodeLine,
    longPelvicLymphNodeTNMCodeLine,
  ]
    .concat(
      combinedM1CodeLine() === "M0"
        ? ["M0"]
        : [
            longOtherLymphNideTNMCodeLine,
            longBoneTNMCodeLine,
            longOtherOrganTNMCodeLine,
          ]
    )
    .filter((code) => code !== "")
    .join(" ");

  return [tnmCodeLine, longTNMCodeLine];
}
