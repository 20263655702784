import * as React from "react";

import { Modal, Row, Form, Col, Spinner } from "react-bootstrap";
import { TNMClassifications } from "../constants/enums";
import { useAppSettingsContext } from "../contexts/AppSettingsContext";
import { useOrgContext } from "../contexts/OrgContext";

interface SettingsModalProps {
  showModal: boolean;
  onModalHide: () => void;
}

export default function SettingsModal({
  showModal,
  onModalHide,
}: SettingsModalProps) {
  const { showIntro, setShowIntro, TNMClassification, setTNMClassification } =
    useAppSettingsContext();
  const { currentOrg } = useOrgContext();

  return (
    <Modal size="lg" show={showModal} onHide={onModalHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {TNMClassification !== undefined && showIntro !== undefined ? (
          <Form as={Row}>
            <Col>
              {currentOrg?.product === "clinic" && (
                <Form.Group as={Row} className="mb-3">
                  <Form.Label className="mb-0">TNM Classification</Form.Label>
                  <Form.Select
                    aria-label="TNM Classification"
                    value={TNMClassification.toString()}
                    onChange={(e) => {
                      setTNMClassification(
                        e.target.value as TNMClassifications
                      );
                    }}
                  >
                    {Object.values(TNMClassifications).map(
                      (classification, index) => (
                        <option
                          value={classification}
                          key={`TNMClassification-${index}`}
                        >
                          {classification}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              )}
              <Form.Group as={Row} className="mb-3">
                <Form.Check
                  type="switch"
                  checked={showIntro}
                  onChange={(e) => {
                    setShowIntro(e.target.checked);
                  }}
                  label="Show intro"
                />
              </Form.Group>
            </Col>
          </Form>
        ) : (
          <Spinner animation="border" variant="primary" className="mx-5 my-5" />
        )}
      </Modal.Body>
    </Modal>
  );
}
