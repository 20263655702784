import React, { Fragment } from "react";
import {
  PatientTreatment,
  PatientTreatmentOption,
  PatientTreatmentOptions,
} from "../../api/patientTreatments";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  Highlighter,
  Menu,
  MenuItem,
  Typeahead,
} from "react-bootstrap-typeahead";
import DatePicker from "react-date-picker";
import { DateTime } from "luxon";
import BootstrapIcon from "../BootstrapIcon";
import {
  isOngoingTreatment,
  isSameDayTreatment,
} from "../../utils/treatmentUtils";

interface TreatmentRowEditProps {
  treatment: PatientTreatment;
  onUpdateTreatment: (newTreatment: PatientTreatment) => void;
  onDiscardChanges: () => void;
}

export default function TreatmentRowEdit({
  treatment,
  onUpdateTreatment,
  onDiscardChanges,
}: TreatmentRowEditProps): React.ReactElement {
  const [treatmentName, setTreatmentName] = React.useState(
    treatment.treatment_name
  );
  const [startedAt, setStartedAt] = React.useState(treatment.started_at);
  const [endedAt, setEndedAt] = React.useState(treatment.ended_at);
  const [ongoingTreatment, setOngoingTreatment] = React.useState(
    isOngoingTreatment(treatment)
  );
  const [sameDayTreatment, setSameDayTreatment] = React.useState(
    isSameDayTreatment(treatment)
  );

  const shouldDisplayEndDate = !(ongoingTreatment || sameDayTreatment);

  return (
    <>
      <Row className="mb-1">
        <Form.Group as={Col} xs="6">
          <Typeahead
            id={`treatment-${
              treatment.id || DateTime.now().toUnixInteger()
            }-typeahead`}
            placeholder="Select treatment name"
            allowNew
            newSelectionPrefix="Custom: "
            selected={treatmentName ? [treatmentName] : []}
            options={PatientTreatmentOptions}
            renderMenu={(
              results,
              {
                newSelectionPrefix,
                paginationText,
                renderMenuItemChildren,
                ...menuProps
              },
              state
            ) => {
              let index = 0;
              const categories = (results as PatientTreatmentOption[]).reduce(
                (group, result) => {
                  let { category } = result;
                  if (category == null) {
                    category = "Custom";
                  }
                  group[category] = group[category] ?? [];
                  group[category].push(result);
                  return group;
                },
                {} as { [key: string]: PatientTreatmentOption[] }
              );
              const items = Object.keys(categories)
                .sort()
                .map((category) => (
                  <Fragment key={category}>
                    {index !== 0 && <Menu.Divider />}
                    <Menu.Header>{category}</Menu.Header>
                    {categories[category].map((option) => {
                      const item = (
                        <MenuItem key={index} option={option} position={index}>
                          <Highlighter search={state.text}>
                            {option.label}
                          </Highlighter>
                        </MenuItem>
                      );
                      index += 1;
                      return item;
                    })}
                  </Fragment>
                ));
              return <Menu {...menuProps}>{items}</Menu>;
            }}
            onChange={([selection]) => {
              if (selection != null) {
                //@ts-ignore Wrong typing
                setTreatmentName(selection.label as string);
              } else {
                setTreatmentName("");
              }
            }}
          />
        </Form.Group>
        <Form.Group as={Col} xs="2">
          <DatePicker
            className="form-control"
            value={startedAt && startedAt.toJSDate()}
            onChange={(value: Date | null) => {
              if (value) {
                setStartedAt(DateTime.fromJSDate(value));
              }
            }}
          />
        </Form.Group>
        {shouldDisplayEndDate && (
          <Form.Group as={Col} xs="2">
            <DatePicker
              className="form-control"
              value={endedAt && endedAt.toJSDate()}
              onChange={(value: Date | null) => {
                if (value) {
                  setEndedAt(DateTime.fromJSDate(value));
                }
              }}
            />
          </Form.Group>
        )}
        <Form.Group
          as={Col}
          xs={{ span: 2, offset: shouldDisplayEndDate ? 0 : 2 }}
          className="d-flex align-items-center"
        >
          <ButtonGroup size="sm">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-submit">Save changes</Tooltip>}
            >
              <Button
                variant="outline-success"
                onClick={() => {
                  if (ongoingTreatment) {
                    onUpdateTreatment({
                      ...treatment,
                      treatment_name: treatmentName,
                      started_at: startedAt,
                      ended_at: null,
                    });
                  } else if (sameDayTreatment) {
                    onUpdateTreatment({
                      ...treatment,
                      treatment_name: treatmentName,
                      started_at: startedAt,
                      ended_at: startedAt,
                    });
                  } else {
                    onUpdateTreatment({
                      ...treatment,
                      treatment_name: treatmentName,
                      started_at: startedAt,
                      ended_at: endedAt,
                    });
                  }
                }}
              >
                <BootstrapIcon name="check2" size={16} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-discard">Discard changes</Tooltip>}
            >
              <Button
                variant="outline-danger"
                onClick={() => {
                  setTreatmentName(treatment.treatment_name);
                  setStartedAt(treatment.started_at);
                  setEndedAt(treatment.ended_at);
                  onDiscardChanges();
                }}
              >
                <BootstrapIcon name="x" size={16} />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} xs={{ span: 2, offset: 6 }}>
          <Form.Check
            checked={ongoingTreatment}
            onChange={(e) => {
              if (e.target.checked) {
                setOngoingTreatment(true);
                setSameDayTreatment(false);
              } else {
                setOngoingTreatment(false);
              }
            }}
            type="checkbox"
            label="Ongoing treatment"
          />
        </Form.Group>
        <Form.Group as={Col} xs="2">
          <Form.Check
            checked={sameDayTreatment}
            onChange={(e) => {
              if (e.target.checked) {
                setSameDayTreatment(true);
                setOngoingTreatment(false);
              } else {
                setSameDayTreatment(false);
              }
            }}
            type="checkbox"
            label="Same end date"
          />
        </Form.Group>
      </Row>
    </>
  );
}
