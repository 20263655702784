import * as React from "react";
import { GlobalHotKeys } from "react-hotkeys";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";

import generateReport from "../../utils/generatePDFReport";
import BootstrapIcon from "../BootstrapIcon";
import {
  fetchAndDrawSVGOnCanvas,
  drawSVGGridOnCanvas,
} from "../../utils/imageUtils";

import logoURL from "../../img/TNM-Stager-Logo.svg";
import { Patient } from "../../api/patients";
import { PatientScan } from "../../api/patientScans";
import { useAuthContext } from "../../contexts/AuthContext";
import { getTreatmentsForPatient } from "../../api/patientTreatments";
import { getTestResultsForPatient } from "../../api/testResults";
import useLuPSMAEligibility from "../ImagingFindings/useLuPSMAEligibility";
import { Spinner } from "react-bootstrap";
import {
  getClinicalInformation,
  getDescriptiveFindings,
  getTechnicalInformation,
} from "./ClinicalReport/clinicalReportUtils";
import { getPatientScanDescriptiveFindings } from "../../api/patientScanDescriptiveFindings";
import { PatientRecipCriteria } from "../../api/patientRecipCriteria";
import useRecipCriteria from "../RecipCriteria/useRecipCriteria";

interface GeneratePDFReportButtonProps {
  images: Blob[];
  labels: string[];
  patient: Patient;
  patientScan: PatientScan;
  patientRecipCriteria: PatientRecipCriteria | null;
  tnmCodeLine: string;
  oncologicalFindings: [string, string, string];
}

function GeneratePDFReportButton({
  images,
  labels,
  patient,
  patientScan,
  patientRecipCriteria,
  tnmCodeLine,
  oncologicalFindings,
}: GeneratePDFReportButtonProps) {
  const { getAccessToken } = useAuthContext();

  const {
    criteriaApplicable: luPSMACriteriaApplicable,
    reportText: luPSMAReportText,
  } = useLuPSMAEligibility(patient, patientScan);

  const {
    criteriaApplicable: recipCriteriaApplicable,
    reportText: recipCriteriaReportText,
    reference,
  } = useRecipCriteria(patientScan, patientRecipCriteria);

  const [isReportOpening, setIsReportOpening] = React.useState(false);

  async function onClick() {
    if (!patient.id || !patientScan.id || isReportOpening) {
      return;
    }
    setIsReportOpening(true);

    try {
      const accessToken = await getAccessToken();
      const logoPNGBlob = await fetchAndDrawSVGOnCanvas(logoURL, 272, 138);
      const imageSnapshots = await drawSVGGridOnCanvas(images, labels);
      const patientTreatments = await getTreatmentsForPatient(
        accessToken,
        patient.id
      );
      const patientTestResults = await getTestResultsForPatient(
        accessToken,
        patient.id
      );
      const patientScanDescriptiveFindings =
        await getPatientScanDescriptiveFindings(
          accessToken,
          patient.id,
          patientScan.id
        );
      const descriptiveFindings = getDescriptiveFindings(
        patientScanDescriptiveFindings
      );

      const clinicalInformation = getClinicalInformation(
        patient,
        patientScan,
        patientTestResults.filter((result) => result.test_name === "PSA"),
        patientTreatments
      );
      const technicalInformation = getTechnicalInformation(patientScan);

      const pdfReport = await generateReport(
        logoPNGBlob,
        imageSnapshots,
        clinicalInformation,
        technicalInformation,
        oncologicalFindings,
        descriptiveFindings,
        luPSMACriteriaApplicable,
        patientScan.therapeutic_radiopharmaceutical || "PSMA",
        luPSMAReportText,
        recipCriteriaApplicable,
        recipCriteriaReportText,
        reference,
        tnmCodeLine
      );

      const popUpWindow = window.open("", "_blank");
      if (!popUpWindow) {
        alert(
          'Please allow pop-up windows from this website to display PDF reports in your browser or use the "Download PDF report" option.'
        );
      } else {
        pdfReport.open({}, popUpWindow);
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsReportOpening(false);
    }
  }

  return (
    <GlobalHotKeys
      handlers={{
        GET_PDF_REPORT: onClick,
      }}
      allowChanges={true}
    >
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="get-pdf-report-button-tooltip">
            Download clinical report as PDF
          </Tooltip>
        }
      >
        <Button
          variant="link"
          onClick={onClick}
          disabled={isReportOpening}
          style={{
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          {isReportOpening ? (
            <Spinner
              as="span"
              className="me-2"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <BootstrapIcon name="file-earmark-pdf" size={28} />
          )}
        </Button>
      </OverlayTrigger>
    </GlobalHotKeys>
  );
}

export default GeneratePDFReportButton;
