import * as React from "react";
import PropTypes from "prop-types";
import Overlay from "react-bootstrap/Overlay";
import UpdatingTooltip from "./UpdatingTooltip";

import "./RegionOverlay.css";

const RegionOverlay = ({ left = 0, top = 0, show = false, text = "" }) => {
  const ref = React.useRef();

  return (
    <React.Fragment>
      <div
        ref={ref}
        style={{
          position: "relative",
          left,
          top,
          height: 1,
          width: 1,
        }}
      ></div>
      <Overlay target={ref} show={show} placement="top">
        <UpdatingTooltip
          style={{ pointerEvents: "none" }}
          left={left}
          top={top}
        >
          {text}
        </UpdatingTooltip>
      </Overlay>
    </React.Fragment>
  );
};

RegionOverlay.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  show: PropTypes.bool,
  text: PropTypes.string,
};

export default RegionOverlay;
