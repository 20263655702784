export default function displayBoneRegionNames(boneRegionNames) {
  const boneRegionNamesByArea = boneRegionNames.reduce(
    (prevMap, curRegionName) => {
      const curMap = new Map(prevMap);
      if (
        /^(Skull|Sternum|Clavicula|Scapula|Humerus|Femur).*$/.test(
          curRegionName
        )
      ) {
        const categoryName = curRegionName.split(" ")[0];
        curMap.set(
          categoryName,
          curMap.has(categoryName)
            ? curMap.get(categoryName).concat(curRegionName)
            : [curRegionName]
        );
      }

      if (/^(C\d|T\d{1,2}|L\d|Sacrum).*$/.test(curRegionName)) {
        curMap.set(
          "Spine",
          curMap.has("Spine")
            ? curMap.get("Spine").concat(curRegionName)
            : [curRegionName]
        );
      }

      if (/^(Ilium|Iscium|Pubic).*$/.test(curRegionName)) {
        curMap.set(
          "Pelvis",
          curMap.has("Pelvis")
            ? curMap.get("Pelvis").concat(curRegionName)
            : [curRegionName]
        );
      }

      if (/^R\d{1,2} \((L)\).*$/.test(curRegionName)) {
        curMap.set(
          "Ribs Left",
          curMap.has("Ribs Left")
            ? curMap
                .get("Ribs Left")
                .concat(curRegionName.replace(/ \((R|L)\)/gi, ""))
            : [curRegionName.replace(/ \((R|L)\)/gi, "")]
        );
      }

      if (/^R\d{1,2} \((R)\).*$/.test(curRegionName)) {
        curMap.set(
          "Ribs Right",
          curMap.has("Ribs Right")
            ? curMap
                .get("Ribs Right")
                .concat(curRegionName.replace(/ \((R|L)\)/gi, ""))
            : [curRegionName.replace(/ \((R|L)\)/gi, "")]
        );
      }

      return curMap;
    },
    new Map()
  );

  const result = [];

  if (boneRegionNamesByArea.has("Skull")) {
    result.push("Skull");
  }

  if (boneRegionNamesByArea.has("Spine")) {
    result.push(
      `Spine: ${boneRegionNamesByArea
        .get("Spine")
        .sort(sortVertebrae)
        .join(", ")}`
    );
  }

  if (boneRegionNamesByArea.has("Pelvis")) {
    result.push(`Pelvis: ${boneRegionNamesByArea.get("Pelvis").join(", ")}`);
  }

  const ribSections = [];
  if (boneRegionNamesByArea.has("Ribs Left")) {
    ribSections.push(
      `left ${boneRegionNamesByArea.get("Ribs Left").sort(sortRibs).join(", ")}`
    );
  }

  if (boneRegionNamesByArea.has("Ribs Right")) {
    ribSections.push(
      `right ${boneRegionNamesByArea
        .get("Ribs Right")
        .sort(sortRibs)
        .join(", ")}`
    );
  }
  if (ribSections.length > 0) {
    result.push(`Ribs: ${ribSections.join(", ")}`);
  }

  if (boneRegionNamesByArea.has("Sternum")) {
    result.push("Sternum");
  }

  if (boneRegionNamesByArea.has("Clavicula")) {
    result.push(boneRegionNamesByArea.get("Clavicula").join(", "));
  }

  if (boneRegionNamesByArea.has("Scapula")) {
    result.push(boneRegionNamesByArea.get("Scapula").join(", "));
  }

  if (boneRegionNamesByArea.has("Humerus")) {
    result.push(boneRegionNamesByArea.get("Humerus").join(", "));
  }

  if (boneRegionNamesByArea.has("Femur")) {
    result.push(boneRegionNamesByArea.get("Femur").join(", "));
  }

  return result.join("; ");
}

function sortRibs(r1, r2) {
  return r1.slice(1) - r2.slice(1);
}

function sortVertebrae(v1, v2) {
  const REGION_PRIORITIES = new Map(
    Object.entries({
      C: 0,
      T: 1,
      L: 2,
      S: 3,
    })
  );

  const v1Region = REGION_PRIORITIES.get(v1.slice(0, 1));
  const v2Region = REGION_PRIORITIES.get(v2.slice(0, 1));

  if (v1Region === v2Region) {
    return v1.slice(1) - v2.slice(1);
  }
  return v1Region - v2Region;
}

export function handleOrientation(regionIds) {
  const auxMap = new Map();

  regionIds.forEach((regionId) => {
    let [regionName, regionOrientation] = regionId.split(/\s(?=[^\s]+$)/);
    if (!regionOrientation) {
      regionOrientation = "";
    }
    regionOrientation = regionOrientation.replace(/(\(|\))/gi, "");

    auxMap.set(
      regionName,
      auxMap.has(regionName)
        ? auxMap.get(regionName) + regionOrientation
        : regionOrientation
    );
  });

  return Array.from(auxMap.keys()).map((key) => {
    switch (auxMap.get(key)) {
      case "R":
        return `${key} (R)`;
      case "L":
        return `${key} (L)`;
      case "LR":
      case "RL":
        return `${key} (L+R)`;
      default:
        return key;
    }
  });
}

export function handleProstateRegions(regionSet) {
  const resultSet = new Set(regionSet);
  resultSet.delete("EPE");
  ["Apex", "Mid", "Base"].forEach((region) => {
    ["TL", "TR", "BL", "BR"].forEach((subregion) => {
      const innerProstateRegionId = `${region}-${subregion}I`;
      const borderProstateRegionId = `${region}-${subregion}M`;
      const outerProstateRegionId = `${region}-${subregion}O`;
      if (resultSet.has(outerProstateRegionId)) {
        resultSet.delete(borderProstateRegionId);
        resultSet.delete(innerProstateRegionId);
      } else if (resultSet.has(borderProstateRegionId)) {
        resultSet.delete(innerProstateRegionId);
      }
    });
  });
  return resultSet;
}
