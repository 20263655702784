import * as React from "react";
import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";

function S3Fallback({ children }) {
  const location = useLocation();

  const hash = window.location.hash;
  const fallbackLocation = hash.replace(/^#/, "");

  return fallbackLocation ? (
    <Navigate to={fallbackLocation} state={{ from: location }} replace />
  ) : (
    children
  );
}

S3Fallback.propTypes = {
  children: PropTypes.node.isRequired,
};

export default S3Fallback;
