import { Button, Col, Form, Row } from "react-bootstrap";
import { ImagingModality, Radiopharmaceutical } from "../constants/enums";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { usePatientsContext } from "../contexts/PatientsContext";
import React from "react";
import ImportDICOMModal from "./ImportDICOMModal";

export const PreDefinedCTContrast = [
  "None",
  "Imeron 300 1,5 ml/kg i.v. and oral",
];
export const PreDefinedMRIContrast = ["None", "Gadovist 0,1 ml/kg i.v."];
export const PreDefinedDiuretics = ["None", "Furosemide 20 mg i.v."];

function TechnicalParams() {
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const [showImportModal, setShowImportModal] = React.useState(false);

  const ctContrast =
    currentPatientScan.modality === ImagingModality.CT ||
    currentPatientScan.modality === ImagingModality.PET_CT;
  const mriContrast =
    currentPatientScan.modality === ImagingModality.MRI ||
    currentPatientScan.modality === ImagingModality.PET_MRI;
  const shouldDisplayContrast = ctContrast || mriContrast;

  return (
    <>
      <ImportDICOMModal
        show={showImportModal}
        onHide={() => {
          setShowImportModal(false);
        }}
      />
      <Row style={{ height: 85 }}>
        <Col md className="d-flex">
          <h3 className="my-auto">Technical Parameters</h3>
        </Col>
        <Col md className="d-flex justify-content-end">
          <Button
            variant="success"
            className="my-auto"
            onClick={() => {
              setShowImportModal(true);
            }}
          >
            Import from DICOM
          </Button>
        </Col>
      </Row>
      <Form style={{ marginTop: 12 }}>
        {/* Row 1 - Scanner Location / Radiopharmaceutical */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Scanner Location</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.location || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  location: e.target.value,
                });
              }}
            >
              {/* TODO: Clarify location list and whether this is editable */}
              <option value="n/a" disabled>
                Select location:
              </option>
              <option value="Location 1">Location 1</option>
              <option value="Location 1">Location 2</option>
              {currentPatientScan.location != null &&
                currentPatientScan.reconstruction_method !== "Location 1" &&
                currentPatientScan.reconstruction_method !== "Location 2" && (
                  <option value={currentPatientScan.location}>
                    {currentPatientScan.location}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Radiopharmaceutical</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.radiopharmaceutical || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  radiopharmaceutical: e.target.value as Radiopharmaceutical,
                });
              }}
            >
              <option value="n/a" disabled>
                Select radiopharmaceutical:
              </option>
              {Object.values(Radiopharmaceutical).map((rph, index) => (
                <option value={rph} key={`Radiopharmaceutical-${index}`}>
                  {rph}
                </option>
              ))}
              {currentPatientScan.radiopharmaceutical != null &&
                !Object.values(Radiopharmaceutical).includes(
                  currentPatientScan.radiopharmaceutical
                ) && (
                  <option value={currentPatientScan.radiopharmaceutical}>
                    {currentPatientScan.radiopharmaceutical}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
        </Row>
        {/* Row 2 – Imaging Technique, Injected Activity */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Imaging Technique</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              aria-label="Clinical state"
              value={currentPatientScan.modality || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  modality: e.target.value as ImagingModality,
                });
              }}
            >
              <option value="n/a" disabled>
                Select modality:
              </option>
              {Object.values(ImagingModality).map((imod, index) => (
                <option value={imod} key={`ImagingModality-${index}`}>
                  {imod}
                </option>
              ))}
              {currentPatientScan.modality != null &&
                !Object.values(ImagingModality).includes(
                  currentPatientScan.modality
                ) && (
                  <option value={currentPatientScan.modality}>
                    {currentPatientScan.modality}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Injected activity (Mbq)</Form.Label>
            <Form.Control
              disabled={!currentPatient.id}
              type="number"
              step="0.01"
              value={currentPatientScan.injected_activity || ""}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  injected_activity: parseFloat(e.target.value) || null,
                });
              }}
            />
          </Form.Group>
        </Row>
        {/* Row 3 – Scanner Model, Injected Time p.i. */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Scanner Model</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.scanner_model || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  scanner_model: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                Select scanner model:
              </option>
              <option value="Siemens, Biograph mCT">
                Siemens, Biograph mCT
              </option>
              <option value="Siemens, Biograph 64">Siemens, Biograph 64</option>
              {currentPatientScan.scanner_model != null &&
                currentPatientScan.scanner_model !== "Siemens, Biograph mCT" &&
                currentPatientScan.scanner_model !== "Siemens, Biograph 64" && (
                  <option value={currentPatientScan.scanner_model}>
                    {currentPatientScan.scanner_model}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Injected time p.i. (min)</Form.Label>
            <Form.Control
              disabled={!currentPatient.id}
              type="number"
              step="0.01"
              value={currentPatientScan.injected_time || ""}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  injected_time: parseFloat(e.target.value) || null,
                });
              }}
            />
          </Form.Group>
        </Row>
        {/* Row 4 – Scanner Type, Diuretics */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Scanner Type</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.scanner_type || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  scanner_type: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                Select scanner type:
              </option>
              <option value="Analogue">Analogue</option>
              <option value="Digital">Digital</option>
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Diuretics</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.diuretics || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  diuretics: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                Select diuretics:
              </option>
              {PreDefinedDiuretics.map((diuretic, index) => (
                <option value={diuretic} key={index}>
                  {diuretic}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        {/* Row 5 – Reconstruction Method, Contrast */}
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="mb-0">Reconstruction Method</Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={currentPatientScan.reconstruction_method || "n/a"}
              onChange={(e) => {
                setCurrentPatientScan({
                  ...currentPatientScan,
                  reconstruction_method: e.target.value,
                });
              }}
            >
              <option value="n/a" disabled>
                Select reconstruction method:
              </option>
              <option value="PSF-TOF (3i, 21s)">PSF-TOF (3i, 21s)</option>
              <option value="OSEMD3D (2i, 24s)">OSEMD3D (2i, 24s)</option>
              {currentPatientScan.reconstruction_method != null &&
                currentPatientScan.reconstruction_method !==
                  "PSF-TOF (3i, 21s)" &&
                currentPatientScan.reconstruction_method !==
                  "OSEMD3D (2i, 24s)" && (
                  <option value={currentPatientScan.reconstruction_method}>
                    {currentPatientScan.reconstruction_method}
                  </option>
                )}
            </Form.Select>
          </Form.Group>
          {shouldDisplayContrast && (
            <Form.Group as={Col}>
              <Form.Label className="mb-0">Contrast agent</Form.Label>
              <Form.Select
                disabled={!currentPatient.id}
                value={currentPatientScan.contrast || "n/a"}
                onChange={(e) => {
                  setCurrentPatientScan({
                    ...currentPatientScan,
                    contrast: e.target.value,
                  });
                }}
              >
                <option value="n/a" disabled>
                  Select contrast agent:
                </option>
                {(ctContrast
                  ? PreDefinedCTContrast
                  : PreDefinedMRIContrast
                ).map((contrast, index) => (
                  <option value={contrast} key={index}>
                    {contrast}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Row>
      </Form>
    </>
  );
}

export default TechnicalParams;
