import * as React from "react";
import { Spinner } from "react-bootstrap";
import { getOrg, Org } from "../api/orgs";
import { useAuthContext } from "./AuthContext";

type OrgContextType = {
  currentOrg?: Org;
  loading: boolean;
};

const OrgContext = React.createContext<OrgContextType>({
  currentOrg: undefined,
  loading: true,
});

interface OrgContextProviderProps {
  children?: React.ReactNode;
}

export function OrgContextProvider({ children }: OrgContextProviderProps) {
  const { currentUser, getAccessToken } = useAuthContext();

  const [currentOrg, setCurrentOrg] = React.useState<Org>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    fetchOrg();
  }, [currentUser]);

  async function fetchOrg() {
    const accessToken = await getAccessToken();
    if (currentUser) {
      const orgInfo = await getOrg(accessToken, currentUser["org_id"]);
      setCurrentOrg(orgInfo);
    }
    setLoading(false);
  }

  return loading ? (
    <Spinner
      animation="border"
      variant="primary"
      style={{
        position: "fixed",
        top: "50%",
        right: "50%",
      }}
    />
  ) : (
    <OrgContext.Provider value={{ currentOrg, loading }}>
      {children}
    </OrgContext.Provider>
  );
}

export function useOrgContext() {
  return React.useContext(OrgContext);
}
