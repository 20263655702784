import React from "react";

import { ClinicalIndication, MiTNMSections } from "../../constants/enums";
import { updateImageSnapshot } from "../../utils/imageUtils";
import { PatientScanMarkerInfo } from "../../api/patientScans";

async function fetchImage(dataURL: string): Promise<Blob> {
  const response = await fetch(dataURL);
  const imageBlob = await response.blob();
  return imageBlob;
}

export interface MiTNMImages {
  tumorImage: Blob;
  setTumorImage: React.Dispatch<React.SetStateAction<Blob>>;
  tumorImageEmpty: boolean;
  pelvicImage: Blob;
  setPelvicImage: React.Dispatch<React.SetStateAction<Blob>>;
  pelvicImageEmpty: boolean;
  boneImage: Blob;
  setBoneImage: React.Dispatch<React.SetStateAction<Blob>>;
  boneImageEmpty: boolean;
  otherImage: Blob;
  setOtherImage: React.Dispatch<React.SetStateAction<Blob>>;
  otherImageEmpty: boolean;
  combinedImages: Blob[];
  combinedLabels: string[];
  imageForSection: (section: MiTNMSections) => Blob | Blob[] | undefined;
}

export default function useMiTNMImages(
  clinicalIndication: ClinicalIndication,
  markerInfo: PatientScanMarkerInfo
): MiTNMImages {
  const {
    pelvic_lymph_node_metastases,
    prostate_tumor,
    bone_metastases,
    other_organ_metastases,
  } = markerInfo;

  const [pelvicImage, setPelvicImage] = React.useState(new Blob());
  const [boneImage, setBoneImage] = React.useState(new Blob());
  const [otherImage, setOtherImage] = React.useState(new Blob());
  const [tumorImage, setTumorImage] = React.useState(new Blob());

  // Update images on every change to markers
  React.useEffect(() => {
    if (clinicalIndication === ClinicalIndication.STAGING) {
      updateImageSnapshot(
        setTumorImage,
        document.getElementById("ProstateTumorImage"),
        [
          [
            /fill="#fff" stroke="#e53700"/g,
            'fill="#e53700" stroke="transparent"',
          ],
          [/-label" fill="#e53700"/g, '-label" fill="#fff"'],
        ]
      );
    } else {
      updateImageSnapshot(
        setTumorImage,
        document.getElementById("ProstateTumorImage")
      );
    }

    updateImageSnapshot(
      setPelvicImage,
      document.getElementById("PelvicLymphNodeMetastasesImage")
    );

    updateImageSnapshot(
      setBoneImage,
      document.getElementById("BoneMetastasesImage")
    );

    updateImageSnapshot(
      setOtherImage,
      document.getElementById("OtherOrganMetastasesImage")
    );
  }, [markerInfo, clinicalIndication]);

  const tumorImageEmpty = Object.keys(prostate_tumor.markers).length === 0;
  const pelvicImageEmpty =
    Object.keys(pelvic_lymph_node_metastases.markers).length === 0;
  const boneImageEmpty =
    bone_metastases.marker_count === 0 && !bone_metastases.is_dmi;
  const otherImageEmpty =
    Object.keys(other_organ_metastases.markers).length === 0;

  const combinedImages: Blob[] = [];
  const combinedLabels: string[] = [];

  if (!tumorImageEmpty) {
    combinedImages.push(tumorImage);
    combinedLabels.push("Local Lesions");
  }

  if (!pelvicImageEmpty) {
    combinedImages.push(pelvicImage);
    combinedLabels.push("Pelvic LN");
  }

  if (!boneImageEmpty) {
    combinedImages.push(boneImage);
    combinedLabels.push("Osseous Metastases");
  }

  if (!otherImageEmpty) {
    combinedImages.push(otherImage);
    combinedLabels.push("Distant LN & Organs");
  }

  function imageForSection(
    sectionName: MiTNMSections
  ): Blob | Blob[] | undefined {
    switch (sectionName) {
      case MiTNMSections.PROSTATE_TUMOR:
        return tumorImage;
      case MiTNMSections.PELVIC_LYMPH_NODE_METASTASES:
        return pelvicImage;
      case MiTNMSections.BONE_METASTASES:
        return boneImage;
      case MiTNMSections.OTHER_ORGAN_METASTASES:
        return otherImage;
      case MiTNMSections.SUMMARY:
        return combinedImages;
      default:
        return undefined;
    }
  }

  return {
    tumorImage,
    setTumorImage,
    tumorImageEmpty,
    pelvicImage,
    setPelvicImage,
    pelvicImageEmpty,
    boneImage,
    setBoneImage,
    boneImageEmpty,
    otherImage,
    setOtherImage,
    otherImageEmpty,
    combinedImages,
    combinedLabels,
    imageForSection,
  };
}
