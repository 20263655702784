import React from "react";
import { PatientRecipCriteria } from "../../api/patientRecipCriteria";
import { PatientScan } from "../../api/patientScans";
import { ClinicalIndication, RestagingCategory } from "../../constants/enums";

interface RecipCriteriaProps {
  criteriaApplicable: boolean;
  responseEvaluation: string;
  reportText: string;
  reference: string;
}

export default function useRecipCriteria(
  patientScan: PatientScan,
  patientRecipCriteria: PatientRecipCriteria | null
): RecipCriteriaProps {
  const criteriaApplicable = React.useMemo(() => {
    return (
      patientScan.clinical_indication === ClinicalIndication.RESTAGING &&
      patientScan.restaging_category ===
        RestagingCategory.TREATMENT_RESPONSE_EVALUATION
    );
  }, [patientScan.clinical_indication, patientScan.restaging_category]);

  const responseEvaluation = React.useMemo(() => {
    if (patientRecipCriteria == null) {
      return "Not Defined";
    }
    switch (patientRecipCriteria.psma_positive_tumor_volume_change) {
      case "complete_response":
        return "RECIP-CR";
      case "partial_response":
        if (patientRecipCriteria.new_lesion_appearance) {
          return "RECIP-SD";
        } else {
          return "RECIP-PR";
        }
      case "stable_disease":
        return "RECIP-SD";
      case "progressive_disease":
        if (patientRecipCriteria.new_lesion_appearance) {
          return "RECIP-PD";
        } else {
          return "RECIP-SD";
        }
      case null:
      case undefined:
        return "Not Defined";
      default:
        console.warn("Unknown PSMA tumor change");
        return "Not Defined";
    }
  }, [patientRecipCriteria]);

  const reportText = React.useMemo(() => {
    if (patientRecipCriteria == null) {
      return "";
    }
    const lesionAppearanceText = patientRecipCriteria.new_lesion_appearance
      ? `is an occurrence of at least one new lesion ${
          patientRecipCriteria.new_lesion_location
            ? `(${patientRecipCriteria.new_lesion_location})`
            : ""
        }`
      : "are no new lesions observed";

    let psmaPositiveTumorVolumeChangeText;
    switch (patientRecipCriteria.psma_positive_tumor_volume_change) {
      case "complete_response":
        psmaPositiveTumorVolumeChangeText =
          "a complete response (absence of any PSMA-positive lesions) is observed";
        break;
      case "partial response":
        psmaPositiveTumorVolumeChangeText =
          "a partial response in total tumor volume (≥30% reduction) is observed";
        break;
      case "stable_disease":
        psmaPositiveTumorVolumeChangeText =
          "a stable disease in total tumor volume (<30% reduction or >20% increase) is observed";
        break;
      case "progressive_disease":
        psmaPositiveTumorVolumeChangeText =
          "a progression in total tumor volume (≥20% increase) is observed";
        break;
      default:
        psmaPositiveTumorVolumeChangeText =
          "with an unknown change to the total tumor volume";
    }

    let recipCriteriaText;
    switch (responseEvaluation) {
      case "RECIP-SD":
        recipCriteriaText = "stable disease";
        break;
      case "RECIP-CR":
        recipCriteriaText = "complete response";
        break;
      case "RECIP-PD":
        recipCriteriaText = "progressive disease";
        break;
      case "RECIP-PR":
        recipCriteriaText = "partial response";
        break;
      default:
        recipCriteriaText = "unknown response";
    }

    return `In comparison with the initial PET/CT, there ${lesionAppearanceText} and ${psmaPositiveTumorVolumeChangeText}. These findings are consistent with the ${recipCriteriaText} according to the RECIP criteria.`;
  }, [patientRecipCriteria, responseEvaluation]);

  const reference =
    "Reference: Gafita A, Djaileb L, Rauscher I, Fendler WP, Hadaschik B, Herrmann K et al. Response Evaluation Criteria in PSMA-PET/CT (RECIP 1.0) in metastatic castration-resistant prostate cancer. Radiology 2023; 308(1):222148";

  return {
    criteriaApplicable,
    responseEvaluation,
    reportText,
    reference,
  };
}
