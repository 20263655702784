import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { Row, Col } from "react-bootstrap";

import MiTNMHeader from "../MiTNMHeader";
import ResearchSidebar from "../Sidebar/ResearchSidebar";

import MiTNM from "../MiTNM";

function HomePageResearchLayout() {
  const [editMode, setEditMode] = React.useState(false);

  const sidebarRef = React.useRef(null);
  const contentRef = React.useRef(null);

  return (
    <Row className="px-3">
      <CSSTransition
        nodeRef={sidebarRef}
        in={editMode}
        timeout={1000}
        classNames="MiTNM-sidebar"
      >
        <Col md="3" ref={sidebarRef} className="MiTNM-sidebar">
          <MiTNMHeader />
          <ResearchSidebar editMode={editMode} setEditMode={setEditMode} />
        </Col>
      </CSSTransition>
      <CSSTransition
        nodeRef={contentRef}
        in={!editMode}
        timeout={1000}
        classNames="MiTNM-content"
      >
        <Col md="9" ref={contentRef}>
          <MiTNM />
        </Col>
      </CSSTransition>
    </Row>
  );
}

export default HomePageResearchLayout;
