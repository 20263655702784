import { Row, Col, Button, Table } from "react-bootstrap";
import * as Excel from "exceljs";
import {
  PatientScanComparison,
  deletePatientScanComparison,
  getScanComparisonsForPatient,
} from "../../api/patientScanComparisons";
import { useAuthContext } from "../../contexts/AuthContext";
import React from "react";
import { Patient } from "../../api/patients";
import { PatientScan, PatientScanMarkerInfo } from "../../api/patientScans";
import { getOrgPatientScans, getOrgPatients } from "../../api/orgs";
import { clinicalPatientScanLabel } from "../../utils/patientScanUtils";
import BootstrapIcon from "../BootstrapIcon";

import "./PatientScanComparisonsManagement.css";
import { ALL_TNM_REGIONS, shortRegionName } from "../../constants/regionNames";
import saveAs from "file-saver";
import {
  DISAPPEARED_LESION_COLOR,
  NEW_LESION_COLOR,
  REMAINING_LESION_COLOR,
  calculateDiscrepancies,
} from "../../utils/clinicDiscrepancyUtils";

export type AffectedRegionDiff = "NEW" | "DISAPPEARED" | "REMAINED" | "NONE";

export default function PatientScansComparisonManagement(): React.ReactElement {
  const { getAccessToken, currentUser } = useAuthContext();

  const [patients, setPatients] = React.useState<Patient[]>([]);
  const [patientScans, setPatientScans] = React.useState<PatientScan[]>([]);
  const [patientScanComparisons, setPatientScanComparisons] = React.useState<
    PatientScanComparison[]
  >([]);

  async function fetchData() {
    const accessToken = await getAccessToken();
    const newPatients = await getOrgPatients(accessToken, currentUser.org_id);
    const newPatientScans = await getOrgPatientScans(
      accessToken,
      currentUser.org_id
    );
    const newPatientScanComparisons: PatientScanComparison[] = [];
    for (let currentPatient of newPatients) {
      const currentPatientScanComparisons = await getScanComparisonsForPatient(
        accessToken,
        currentPatient.id!
      );

      newPatientScanComparisons.push(...currentPatientScanComparisons);
    }

    setPatients(newPatients);
    setPatientScans(newPatientScans);
    setPatientScanComparisons(newPatientScanComparisons);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  async function onDeletePatientScanComparison(
    comparison: PatientScanComparison
  ) {
    if (!comparison.patient_id) {
      console.warn(
        "Cannot delete a PatientScanComparison that doesn't have an id."
      );
      return;
    }
    try {
      const accessToken = await getAccessToken();
      await deletePatientScanComparison(
        accessToken,
        comparison.patient_id,
        comparison
      );
      // Delete the patient scan comparison from the fetched list
      const comparisonIndex = patientScanComparisons.findIndex(
        (c) => c.id === comparison.id
      );
      if (comparisonIndex === -1) {
        console.warn("Comparison cannot be found in an array");
        return;
      }
      const newPatientScanComparisons = Array.from(patientScanComparisons);
      newPatientScanComparisons.splice(comparisonIndex, 1);
      setPatientScanComparisons(newPatientScanComparisons);
    } catch (e) {
      console.error(e);
    }
  }

  function patientScanComparisonToTableRow(
    comparison: PatientScanComparison
  ): React.ReactElement {
    /*
      <tr>
        <th>Patient ID</th>
        <th>First scan</th>
        <th>Second scan</th>
        <th>Remove</th>
      </tr>
    */

    const firstScan = patientScans.find(
      (scan) => scan.id === comparison.first_scan_id
    );
    const secondScan = patientScans.find(
      (scan) => scan.id === comparison.second_scan_id
    );

    return (
      <tr key={`scanComparison-${comparison.id}`}>
        <td>
          {
            patients.find((patient) => patient.id === comparison.patient_id)
              ?.patient_id
          }
        </td>
        <td>{clinicalPatientScanLabel(firstScan!)}</td>
        <td>{clinicalPatientScanLabel(secondScan!)}</td>
        <td>
          <Button
            variant="outline-secondary"
            onClick={() => {
              if (
                window.confirm("Are you sure? This action cannot be undone!")
              ) {
                onDeletePatientScanComparison(comparison);
              }
            }}
          >
            <BootstrapIcon name="trash3" size={16} />
          </Button>
        </td>
      </tr>
    );
  }

  function markerColorMapping(color: string | undefined): AffectedRegionDiff {
    switch (color) {
      case REMAINING_LESION_COLOR:
        return "REMAINED";
      case NEW_LESION_COLOR:
        return "NEW";
      case DISAPPEARED_LESION_COLOR:
        return "DISAPPEARED";
      default:
        return "NONE";
    }
  }

  function patientScanComparisonToExcelRow(comparison: PatientScanComparison) {
    const firstScan = patientScans.find(
      (scan) => scan.id === comparison.first_scan_id
    );
    const secondScan = patientScans.find(
      (scan) => scan.id === comparison.second_scan_id
    );

    if (!firstScan || !secondScan) {
      console.warn("At least one scan cannot be found");
      return {};
    }

    const diff = calculateDiscrepancies(firstScan, secondScan);
    console.log(diff);

    const affectedRegions: {
      [key: string]: AffectedRegionDiff;
    } = {};

    for (let image of [
      "prostate_tumor",
      "pelvic_lymph_node_metastases",
      "bone_metastases",
      "other_organ_metastases",
    ]) {
      if (!diff.hasOwnProperty(image)) {
        continue;
      }
      for (let markerId in diff[image as keyof PatientScanMarkerInfo].markers) {
        const regionName = shortRegionName(markerId.split("*")[0]);
        affectedRegions[regionName] = markerColorMapping(
          diff[image as keyof PatientScanMarkerInfo].markers[markerId]?.color
        );
      }
    }

    const patientName =
      patients.find((p) => p.id === comparison.patient_id)?.patient_id ||
      comparison.patient_id;

    return {
      patientId: patientName,
      firstScanId: comparison.first_scan_id,
      firstScanDate: firstScan?.date || "N/A",
      secondScanId: comparison.second_scan_id,
      secondScanDate: secondScan?.date || "N/A",
      ...ALL_TNM_REGIONS.reduce((prev, region) => {
        return {
          ...prev,
          [region]: affectedRegions.hasOwnProperty(region)
            ? affectedRegions[region]
            : "NONE",
        };
      }, {}),
    };
  }

  async function exportToExcel() {
    /*
    - Patient ID    
    - First Scan ID
    - First Scan Date
    - Second Scan ID
    - Second Scan Date
    - Diff
    */

    const workbook = new Excel.Workbook();

    const patientScanComparisonsWorksheet =
      workbook.addWorksheet("Lesion Tracking");

    patientScanComparisonsWorksheet.columns = [
      { header: "Patient ID", key: "patientId" },
      { header: "First Scan ID", key: "firstScanId" },
      { header: "First Scan Date", key: "firstScanDate" },
      { header: "Second Scan ID", key: "secondScanId" },
      { header: "Second Scan Date", key: "secondScanDate" },
      ...ALL_TNM_REGIONS.map((region) => ({ header: region, key: region })),
      { header: "DMI", key: "dmi" },
    ];

    for (let comparison of patientScanComparisons) {
      patientScanComparisonsWorksheet.addRow(
        patientScanComparisonToExcelRow(comparison)
      );
    }

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });

    saveAs(blob, "Lesion Tracking.xlsx");
  }

  return (
    <>
      <Row className="my-3 px-3">
        <Col md>
          <h3>Lesion Tracking</h3>
        </Col>
        <Col md>
          <Button
            variant="success"
            className="float-end"
            onClick={() => {
              exportToExcel();
            }}
          >
            Export to Excel
          </Button>
        </Col>
      </Row>
      <Row className="px-3">
        <Col>
          <Table size="sm" striped bordered hover>
            <thead>
              <tr>
                <th>Patient ID</th>
                <th>First scan</th>
                <th>Second scan</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {patientScanComparisons.map(patientScanComparisonToTableRow)}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
