import * as React from "react";

import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import PatientScanSelector from "./Sidebar/PatientScanSelect";
import { usePatientScansContext } from "../contexts/PatientScansContext";
import { scanLabel } from "../utils/patientScanUtils";
import { useAuthContext } from "../contexts/AuthContext";
import { usePatientsContext } from "../contexts/PatientsContext";
import { getPatientScansForPatient, PatientScan } from "../api/patientScans";
import { Patient } from "../api/patients";
import { Typeahead } from "react-bootstrap-typeahead";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

interface LoadPatientScanModalProps {
  showModal: boolean;
  onModalHide: () => void;
}

export default function LoadPatientScanModal({
  showModal,
  onModalHide,
}: LoadPatientScanModalProps) {
  const { getAccessToken } = useAuthContext();

  const { patients, setCurrentPatient } = usePatientsContext();
  const [selectedPatient, setSelectedPatient] = React.useState<
    Patient | undefined
  >();
  const [patientScans, setPatientScans] = React.useState<PatientScan[]>([]);
  const [selectedPatientScan, setSelectedPatientScan] = React.useState<
    PatientScan | undefined
  >();

  // Update patient scans every time selected patient changes
  React.useEffect(() => {
    (async () => {
      if (selectedPatient?.id) {
        const accessToken = await getAccessToken();
        const newPatientScans = await getPatientScansForPatient(
          accessToken,
          selectedPatient.id
        );
        setPatientScans(newPatientScans);
      } else {
        setPatientScans([]);
      }
    })();
  }, [selectedPatient]);

  const { setCurrentPatientScan } = usePatientScansContext();

  function loadPatientScan(e: React.FormEvent) {
    e.preventDefault();
    if (selectedPatient && selectedPatientScan) {
      setCurrentPatient(selectedPatient);
      setCurrentPatientScan(selectedPatientScan);
      onModalHide();
    } else {
      console.warn("Patient scan is not selected - cannot proceed");
    }
  }

  return (
    <Modal show={showModal} onHide={onModalHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Load patient scan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={loadPatientScan}>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label className="mb-0">Patient ID</Form.Label>
              <Typeahead
                id="LoadPatientScanModal-patient-id-typeahead"
                labelKey="patient_id"
                placeholder="Select patient"
                clearButton
                selected={selectedPatient?.id ? [selectedPatient] : []}
                options={patients}
                onChange={([selection]) => {
                  if (selection === undefined) {
                    setSelectedPatient(undefined);
                  } else {
                    setSelectedPatient(selection as Patient);
                  }
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label className="mb-0">Patient Scan</Form.Label>
              <PatientScanSelector
                disabled={selectedPatient === undefined}
                patientScans={patientScans}
                value={selectedPatientScan?.id?.toString() || "-1"}
                onChange={(e) => {
                  const newScanId = parseInt(e.target.value);
                  const newScan = patientScans.find(
                    (scan) => scan.id === newScanId
                  );
                  setSelectedPatientScan(newScan);
                }}
                placeholder="Select patient scan"
                scanLabel={scanLabel}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Col>
              <Button
                variant="primary"
                type="submit"
                className="w-100"
                disabled={selectedPatientScan === undefined}
              >
                Load Scan
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
