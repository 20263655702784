import * as React from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import useLuPSMAEligibility from "./useLuPSMAEligibility";
import { TherapeuticRadiopharmaceutical } from "../../constants/enums";

interface LuPSMAEligibilityProps {}

function LuPSMAEligibility(props: LuPSMAEligibilityProps) {
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan, setCurrentPatientScan } =
    usePatientScansContext();

  const { criteriaApplicable, eligibleForTherapy } = useLuPSMAEligibility(
    currentPatient,
    currentPatientScan
  );

  const therapyEligibility = React.useMemo(() => {
    if (eligibleForTherapy == null) {
      return "NOT DEFINED";
    } else {
      return eligibleForTherapy === true ? "ELIGIBLE" : "INELIGIBLE";
    }
  }, [eligibleForTherapy]);

  const therapyEligibilityTextColor = React.useMemo(() => {
    switch (therapyEligibility) {
      case "ELIGIBLE":
        return "text-success";
      case "INELIGIBLE":
        return "text-danger";
      case "NOT DEFINED":
      default:
        return "text-muted";
    }
  }, [therapyEligibility]);

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">Eligibility for PSMA targeted therapy</h3>
      </div>
      <Form style={{ marginTop: 12 }}>
        {criteriaApplicable ? (
          <>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  Therapeutic radiopharmaceutical
                </Form.Label>
                <Form.Select
                  value={
                    currentPatientScan.therapeutic_radiopharmaceutical || "n/a"
                  }
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      therapeutic_radiopharmaceutical: e.target
                        .value as TherapeuticRadiopharmaceutical,
                    });
                  }}
                >
                  <option value="n/a" disabled>
                    Select radiopharmaceutical:
                  </option>
                  {Object.values(TherapeuticRadiopharmaceutical).map(
                    (rph, index) => (
                      <option value={rph} key={index}>
                        {rph}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  Liver uptake (SUV<sub>max</sub>)
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={currentPatientScan.liver_suv_max || ""}
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      liver_suv_max: parseFloat(e.target.value) || null,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  Tumor uptake (SUV<sub>max</sub>)
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={currentPatientScan?.tumor_suv_max || ""}
                  onChange={(e) => {
                    setCurrentPatientScan({
                      ...currentPatientScan,
                      tumor_suv_max: parseFloat(e.target.value) || null,
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="mb-0">
                  Has PSMA-negative soft-tissue lesions?
                </Form.Label>
                <div style={{ marginTop: "6px" }}>
                  <Form.Check
                    inline
                    defaultChecked={
                      currentPatientScan.has_psma_negative_soft_tissue_lesions ===
                      true
                    }
                    onClick={() => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        has_psma_negative_soft_tissue_lesions: true,
                      });
                    }}
                    label="Yes"
                    name="has_psma_negative_soft_tissue_lesions"
                    type="radio"
                    key="has_psma_negative_soft_tissue_lesions-radio-yes"
                  />
                  <Form.Check
                    inline
                    defaultChecked={
                      currentPatientScan.has_psma_negative_soft_tissue_lesions ===
                      false
                    }
                    onClick={() => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        has_psma_negative_soft_tissue_lesions: false,
                        psma_negative_soft_tissue_lesions: "",
                      });
                    }}
                    label="No"
                    name="has_psma_negative_soft_tissue_lesions"
                    type="radio"
                    key="has_psma_negative_soft_tissue_lesions-radio-no"
                  />
                </div>
              </Form.Group>
            </Row>
            {currentPatientScan.has_psma_negative_soft_tissue_lesions ===
              true && (
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="mb-0">
                    PSMA-negative lesion tumor uptake (SUV<sub>max</sub>)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    value={
                      currentPatientScan?.psma_negative_soft_tissue_lesions_suv_max ||
                      ""
                    }
                    onChange={(e) => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        psma_negative_soft_tissue_lesions_suv_max:
                          parseFloat(e.target.value) || null,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="mb-0">
                    PSMA-negative lesion location
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={
                      currentPatientScan.psma_negative_soft_tissue_lesions || ""
                    }
                    onChange={(e) => {
                      setCurrentPatientScan({
                        ...currentPatientScan,
                        psma_negative_soft_tissue_lesions: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Row>
            )}
            <Row className="mb-5">
              <Col>
                <h5>
                  Eligibility for{" "}
                  {currentPatientScan.therapeutic_radiopharmaceutical || "PSMA"}{" "}
                  targeted therapy:{" "}
                  <b className={therapyEligibilityTextColor}>
                    {therapyEligibility}
                  </b>
                </h5>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <h6>Definition of VISION eligibility criteria:</h6>
                <p>
                  The presence of PSMA-positive lesions is defined as
                  PSMA-ligand uptake greater than that of liver parenchyma in
                  one or more metastatic lesions of any size in any organ
                  system.
                </p>
                <p className="mb-0">
                  The presence of PSMA-negative lesions is defined as
                  PSMA-ligand uptake equal to or lower than that of liver
                  parenchyma:
                </p>
                <ul>
                  <li>
                    In any lymph node with a short axis of at least 2.5 cm, OR
                  </li>
                  <li>
                    In any metastatic solid-organ lesions with a short axis of
                    at least 1.0 cm, OR
                  </li>
                  <li>
                    In any metastatic bone lesion with a soft-tissue component
                    of at least 1.0 cm in the short axis.
                  </li>
                </ul>
                <p>
                  Patients without PSMA-positive lesions or with any
                  PSMA-negative metastatic lesion are ineligible.
                </p>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="mb-3">
            <Col>
              <Alert variant="secondary">
                VISION criteria is not applicable for this scan. The eligibility
                for PSMA targeted therapy can only be assessed for the following
                scans:
                <ul>
                  <li>Modality: PET or PET/CT or PET/MRI</li>
                  <li>
                    Radiopharmaceutical: {"\u2076\u2078Ga-PSMA-11"} or{" "}
                    {"\u00B9\u2078F-PSMA-1007"} or {"\u00B9\u2078F-rhPSMA-7.3"}{" "}
                  </li>
                </ul>
              </Alert>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}

export default LuPSMAEligibility;
