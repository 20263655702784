import icons from "bootstrap-icons/bootstrap-icons.svg";

interface BootstrapIconProps {
  name: string;
  size?: number;
  className?: string;
}

export default function BootstrapIcon({
  name,
  size = 16,
  className = "",
}: BootstrapIconProps) {
  return (
    <svg
      className={`bi bi-${name} ${className}`}
      width={size}
      height={size}
      fill="currentColor"
    >
      <use xlinkHref={`${icons}#${name}`} />
    </svg>
  );
}
