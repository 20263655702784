import React from "react";
import {
  getAllStudyParticipations,
  StudyParticipation,
} from "../api/studyParticipations";
import { RESEARCH_PATIENT_IDS } from "../utils/researchAutomatedWorkflowUtils";
import { useAuthContext } from "./AuthContext";
import { useOrgContext } from "./OrgContext";
import { usePatientsContext } from "./PatientsContext";

interface StudyParticipationContextType {
  currentUserStudyParticipation?: StudyParticipation;
  setCurrentUserStudyParticipation: React.Dispatch<
    React.SetStateAction<StudyParticipation | undefined>
  >;
  showFeedbackModal: boolean;
  setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StudyParticipationContext =
  React.createContext<StudyParticipationContextType>({
    setCurrentUserStudyParticipation: () => {},
    showFeedbackModal: false,
    setShowFeedbackModal: () => {},
  });

interface StudyParticipationContextProviderProps {
  children: React.ReactNode;
}

export function StudyParticipationContextProvider({
  children,
}: StudyParticipationContextProviderProps) {
  const { currentOrg } = useOrgContext();
  const { getAccessToken, currentUser } = useAuthContext();
  const { patients } = usePatientsContext();

  const [currentUserStudyParticipation, setCurrentUserStudyParticipation] =
    React.useState<StudyParticipation | undefined>();

  const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);

  // Try to fetch the study participation every time the current user is updated
  React.useEffect(() => {
    (async () => {
      if (!currentOrg || currentOrg.product !== "research") {
        return;
      }
      const accessToken = await getAccessToken();
      const allStudyParticipations = await getAllStudyParticipations(
        accessToken
      );
      const studyParticipation = allStudyParticipations.find(
        (sp) => sp.user_id === currentUser.id
      );

      if (!studyParticipation) {
        console.warn("Study participation not found");
        return;
      }

      if (!studyParticipation.current_patient_id) {
        const firstPatient = patients.find(
          (p) => p.patient_id === RESEARCH_PATIENT_IDS[0]
        );
        if (!firstPatient) {
          console.error("First patient not found");
          return;
        }
        setCurrentUserStudyParticipation({
          ...studyParticipation,
          current_patient_id: firstPatient.id,
          current_step: 1,
        });
      } else {
        setCurrentUserStudyParticipation(studyParticipation);
      }
    })();
  }, [currentUser, currentOrg, getAccessToken, patients]);

  return (
    <StudyParticipationContext.Provider
      value={{
        currentUserStudyParticipation,
        setCurrentUserStudyParticipation,
        showFeedbackModal,
        setShowFeedbackModal,
      }}
    >
      {children}
    </StudyParticipationContext.Provider>
  );
}

export function useStudyParticipationContext() {
  return React.useContext(StudyParticipationContext);
}
