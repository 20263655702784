import { Outlet } from "react-router-dom";
import { PatientScansContextProvider } from "../../contexts/PatientScansContext";
import { PatientsContextProvider } from "../../contexts/PatientsContext";
import { StudyParticipationContextProvider } from "../../contexts/StudyParticipationContext";
import RequireAuth from "../RequireAuth";
import { PatientRecipCriteriaContextProvider } from "../../contexts/PatientRecipCriteriaContext";

function HomePageContextProviders() {
  return (
    <PatientsContextProvider>
      <PatientScansContextProvider>
        <StudyParticipationContextProvider>
          <PatientRecipCriteriaContextProvider>
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          </PatientRecipCriteriaContextProvider>
        </StudyParticipationContextProvider>
      </PatientScansContextProvider>
    </PatientsContextProvider>
  );
}

export default HomePageContextProviders;
