import { Button, Carousel, Col, Form, Row } from "react-bootstrap";
import { PatientScan, initialMarkerInfo } from "../../api/patientScans";
import {
  ClinicalIndication,
  MiTNMSections,
  MiTNMSectionsArray,
  ScanType,
} from "../../constants/enums";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientsContext } from "../../contexts/PatientsContext";
import { calculateDiscrepancies } from "../../utils/clinicDiscrepancyUtils";
import { clinicalPatientScanLabel } from "../../utils/patientScanUtils";
import MiTNMNav from "../MiTNM/MiTNMNav";
import MiTNMSectionContent from "../MiTNM/MiTNMSectionContent";
import useMiTNMImages from "../MiTNM/useMiTNMImages";
import PatientScanSelector from "../Sidebar/PatientScanSelect";
import {
  PatientScanComparison,
  createPatientScanComparison,
  initialPatientScanComparison,
} from "../../api/patientScanComparisons";
import React, { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { useLogContext } from "../LogToast/LogContext";
import { useNavigate, useParams } from "react-router-dom";

interface LesionTrackingProps {}

export default function LesionTracking(props: LesionTrackingProps) {
  const { getAccessToken } = useAuthContext();
  const { log } = useLogContext();
  const { currentPatient } = usePatientsContext();
  const { patientScans } = usePatientScansContext();
  const [comparison, setComparison] = React.useState<PatientScanComparison>(
    initialPatientScanComparison()
  );
  const navigate = useNavigate();
  const { mitnmSection } = useParams();

  const [clinicalIndication, setClinicalIndication] = useState(
    ClinicalIndication.STAGING
  );
  const [prostateRemoved, setProstateRemoved] = useState(false);
  const [markerInfo, setMarkerInfo] = useState(initialMarkerInfo());

  // Reset the patient scan comparison every time the patient ID is changed
  React.useEffect(() => {
    setComparison({
      ...initialPatientScanComparison(),
      patient_id: currentPatient.id,
      first_scan_id: null,
      second_scan_id: null,
    });
  }, [currentPatient]);

  const firstDiscrepancyScan = React.useMemo(
    () =>
      comparison.first_scan_id &&
      patientScans.find((s) => s.id === comparison.first_scan_id),
    [patientScans, comparison.first_scan_id]
  );

  const secondDiscrepancyScan = React.useMemo(
    () =>
      comparison.second_scan_id &&
      patientScans.find((s) => s.id === comparison.second_scan_id),
    [patientScans, comparison.second_scan_id]
  );

  // Recalculate the discrepancies on every scan change
  React.useEffect(() => {
    if (firstDiscrepancyScan && secondDiscrepancyScan) {
      const diff = calculateDiscrepancies(
        firstDiscrepancyScan,
        secondDiscrepancyScan
      );

      setClinicalIndication(firstDiscrepancyScan.clinical_indication);
      setProstateRemoved(firstDiscrepancyScan.prostate_removed);
      setMarkerInfo(diff);
      navigate("/home/imaging_findings/lesion_tracking/summary");
    }
  }, [firstDiscrepancyScan, secondDiscrepancyScan]);

  const miTNMImages = useMiTNMImages(clinicalIndication, markerInfo);

  return (
    <>
      <header
        className="d-flex align-items-center justify-content-center pt-2"
        style={{ minHeight: 78 }}
      >
        <MiTNMNav baseURL="/home/imaging_findings/lesion_tracking" />
      </header>
      <Row className="gx-0">
        <Col>
          <Carousel
            className="w-100"
            variant="dark"
            interval={null}
            keyboard={false}
            activeIndex={
              MiTNMSectionsArray.indexOf(mitnmSection as MiTNMSections) || 0
            }
            onSelect={(selectedIndex) => {
              const newSection = MiTNMSectionsArray[selectedIndex];
              navigate(`/home/imaging_findings/lesion_tracking/${newSection}`);
            }}
          >
            {MiTNMSectionsArray.map((section: MiTNMSections, index: number) => (
              <Carousel.Item key={index}>
                <MiTNMSectionContent
                  sectionName={section}
                  images={miTNMImages}
                  clinicalIndication={clinicalIndication}
                  prostateRemoved={prostateRemoved}
                  markerInfo={markerInfo}
                  updateTumorMarkers={(markers) =>
                    setMarkerInfo({
                      ...markerInfo,
                      prostate_tumor: markers,
                    })
                  }
                  updatePelvicMarkers={(markers) =>
                    setMarkerInfo({
                      ...markerInfo,
                      pelvic_lymph_node_metastases: markers,
                    })
                  }
                  updateBoneMarkers={(markers) =>
                    setMarkerInfo({
                      ...markerInfo,
                      bone_metastases: markers,
                    })
                  }
                  updateOtherMarkers={(markers) =>
                    setMarkerInfo({
                      ...markerInfo,
                      other_organ_metastases: markers,
                    })
                  }
                  markerColor="#000"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Form className="mt-1">
        <Row>
          <Col md="5">
            <PatientScanSelector
              disabled={!currentPatient.id}
              patientScans={patientScans}
              filter={(scan: PatientScan): boolean =>
                scan.scan_type === ScanType.REGULAR
              }
              value={(comparison.first_scan_id || -1).toString()}
              onChange={(e) => {
                const newFirstScanId = parseInt(e.target.value);
                const newFirstScan = patientScans.find(
                  (scan) => scan.id === newFirstScanId
                );
                if (newFirstScanId !== -1 && newFirstScan) {
                  setComparison({
                    ...comparison,
                    first_scan_id: newFirstScanId,
                  });
                } else {
                  setComparison({
                    ...comparison,
                    first_scan_id: null,
                  });
                }
              }}
              placeholder={"Select First Scan"}
              scanLabel={clinicalPatientScanLabel}
            />
          </Col>
          <Col md="5">
            <PatientScanSelector
              disabled={!currentPatient.id}
              patientScans={patientScans}
              filter={(scan: PatientScan): boolean =>
                scan.scan_type === ScanType.REGULAR
              }
              value={(comparison.second_scan_id || -1).toString()}
              onChange={(e) => {
                const newSecondScanId = parseInt(e.target.value);
                const newSecondScan = patientScans.find(
                  (scan) => scan.id === newSecondScanId
                );
                if (newSecondScanId !== -1 && newSecondScan) {
                  setComparison({
                    ...comparison,
                    second_scan_id: newSecondScanId,
                  });
                } else {
                  setComparison({
                    ...comparison,
                    second_scan_id: null,
                  });
                }
              }}
              placeholder={"Select Second Scan"}
              scanLabel={clinicalPatientScanLabel}
            />
          </Col>
          <Col md="2">
            <Button
              disabled={!firstDiscrepancyScan || !secondDiscrepancyScan}
              className="w-100"
              variant="outline-primary"
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                if (!currentPatient.id) {
                  return;
                }

                const accessToken = await getAccessToken();

                await createPatientScanComparison(
                  accessToken,
                  currentPatient.id,
                  comparison
                );

                log("Lesion Tracking successfully saved");
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
