import * as React from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import OtherOrganMetastasesImage from "./OtherOrganMetastasesImage";
import { useRegionOverlay } from "../../RegionOverlay";
import regionName, {
  OTHER_LYMPH_NODE_NAMES,
} from "../../../constants/regionNames";
import findAffectedAreaNode from "../../../utils/svgUtils";
import {
  Coordinates,
  EllipseCoordinates,
  OtherMarkerInfo,
} from "../../../api/patientScans";
import useSUVmaxOverlay from "../../SUVmaxOverlay/useSUVmaxOverlay";

interface OtherOrganMetastasesProps {
  markerInfo: OtherMarkerInfo;
  updateOtherMarkers: (markers: OtherMarkerInfo) => void;
  markerColor: string;
}

function OtherOrganMetastases({
  markerInfo,
  updateOtherMarkers,
  markerColor,
}: OtherOrganMetastasesProps) {
  const ref = React.useRef<SVGSVGElement>(null);
  const [svgPoint, setSvgPoint] = React.useState<SVGPoint>();

  const {
    markers,
    marker_count_by_organ,
    marker_count_by_lymph_node,
    are_other_organs_involved,
    other_organ_involvement,
  } = markerInfo;

  const {
    overlay: regionOverlay,
    setShowOverlay,
    updateOverlayOnMouseMove,
  } = useRegionOverlay(ref, regionName);

  const updateMarkers = (newMarkers: {
    [key: string]: Coordinates | EllipseCoordinates;
  }) => {
    updateOtherMarkers({
      ...markerInfo,
      markers: newMarkers as { [key: string]: Coordinates },
    });
  };

  const { overlay: suvMaxOverlay, onContextMenu } = useSUVmaxOverlay({
    parentRef: ref,
    markers,
    updateMarkers,
  });

  // Set the SVG point after the initial render
  React.useEffect(() => {
    if (ref.current) {
      setSvgPoint(ref.current.createSVGPoint());
    }
  }, []);

  const onOrganOrLymphNodeClick = (event: React.MouseEvent<SVGSVGElement>) => {
    const [found, nodeId] = findAffectedAreaNode(4, event.target);

    if (!found || !svgPoint) {
      event.preventDefault();
      return;
    }

    // Convert screen coords to SVG coords
    svgPoint.x = event.clientX;
    svgPoint.y = event.clientY;
    const metastasisCentre = svgPoint.matrixTransform(
      ref.current!.getScreenCTM()!.inverse()
    );

    const newMetastases = structuredClone(markers);

    const isOtherLymphNode = OTHER_LYMPH_NODE_NAMES.has(nodeId);
    const newMetastasesCount = isOtherLymphNode
      ? structuredClone(marker_count_by_lymph_node)
      : structuredClone(marker_count_by_organ);

    newMetastases[`${nodeId}*${Date.now()}`] = {
      x: metastasisCentre.x,
      y: metastasisCentre.y,
    };

    newMetastasesCount[nodeId] = newMetastasesCount.hasOwnProperty(nodeId)
      ? newMetastasesCount[nodeId] + 1
      : 1;

    updateOtherMarkers(
      isOtherLymphNode
        ? {
            ...markerInfo,
            markers: newMetastases,
            marker_count_by_lymph_node: newMetastasesCount,
          }
        : {
            ...markerInfo,
            markers: newMetastases,
            marker_count_by_organ: newMetastasesCount,
          }
    );
    // log("Selected " + regionName(nodeId));
  };

  const onMetastasisClick = (event: React.MouseEvent<SVGCircleElement>) => {
    const metastasisId = event.currentTarget.id;
    const nodeId = event.currentTarget.id.split("*")[0];

    const isOtherLymphNode = OTHER_LYMPH_NODE_NAMES.has(nodeId);

    const newMetastases = structuredClone(markers);
    const newMetastasesCount = structuredClone(
      isOtherLymphNode ? marker_count_by_lymph_node : marker_count_by_organ
    );

    delete newMetastases[metastasisId];

    if (newMetastasesCount[nodeId] === 1) {
      delete newMetastasesCount[nodeId];
    } else {
      newMetastasesCount[nodeId] -= 1;
    }

    updateOtherMarkers(
      isOtherLymphNode
        ? {
            ...markerInfo,
            markers: newMetastases,
            marker_count_by_lymph_node: newMetastasesCount,
          }
        : {
            ...markerInfo,
            markers: newMetastases,
            marker_count_by_organ: newMetastasesCount,
          }
    );
    // log("Deselected " + regionName(nodeId));
  };

  return (
    <Card border="light">
      <div className="card-img-top">
        {regionOverlay}
        {suvMaxOverlay}
        <OtherOrganMetastasesImage
          ref={ref}
          metastases={markers}
          onMetastasisClick={onMetastasisClick}
          onOrganOrLymphNodeClick={onOrganOrLymphNodeClick}
          onContextMenu={onContextMenu}
          onPointerMove={(event: React.PointerEvent) => {
            const [showOverlay, targetId] = findAffectedAreaNode(
              4,
              event.target
            );
            setShowOverlay(showOverlay);
            if (showOverlay) {
              updateOverlayOnMouseMove(event, targetId);
            }
          }}
          onPointerLeave={() => {
            setShowOverlay(false);
          }}
          markerColor={markerColor}
        />
      </div>
      <Card.Text as="div">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Row
            className="align-items-center gx-1 mx-3"
            style={{ minHeight: 40 }}
          >
            <Col
              xs="auto"
              className={are_other_organs_involved ? "" : "me-auto"}
            >
              <Form.Check
                type="checkbox"
                label={
                  are_other_organs_involved ? " " : "Other organs involved"
                }
                checked={are_other_organs_involved}
                onChange={() => {
                  updateOtherMarkers({
                    ...markerInfo,
                    are_other_organs_involved: !are_other_organs_involved,
                  });
                }}
              />
            </Col>
            {are_other_organs_involved && (
              <Col xs="auto" className="me-auto">
                <Form.Label htmlFor="otherOrgans" visuallyHidden>
                  Other lymph nodes involved
                </Form.Label>
                <Form.Control
                  style={{ pointerEvents: "auto" }}
                  id="otherOrgans"
                  placeholder="Other organs"
                  value={other_organ_involvement}
                  onChange={(event) => {
                    updateOtherMarkers({
                      ...markerInfo,
                      other_organ_involvement: event.target.value,
                    });
                  }}
                />
              </Col>
            )}
          </Row>
        </Form>
      </Card.Text>
    </Card>
  );
}

export default OtherOrganMetastases;
