import { saveAs } from "file-saver";
import { detect } from "detect-browser";
import { drawSVGGridOnCanvas } from "./imageUtils";
const { ClipboardItem } = window;

export function copyText(text) {
  if (navigator.clipboard.writeText === undefined) {
    return Promise.reject(
      "Copying to clipboard is not supported in your browser"
    );
  }
  return navigator.clipboard.writeText(text);
}

export function copyImages(imageBlobs, labels) {
  const browser = detect();

  // Clipboard is not supported – download images instead
  if (
    navigator.clipboard.write === undefined ||
    (browser && browser.name === "firefox")
  ) {
    return downloadImages(imageBlobs, labels);
  }

  // Safari supports multiple items in Clipboard
  if (browser && browser.name === "safari") {
    const clipboardItems = imageBlobs
      .filter((imageBlob) => imageBlob && imageBlob !== null)
      .map(
        (imageBlob) =>
          new ClipboardItem({
            "image/png": imageBlob,
          })
      );

    return navigator.clipboard.write(clipboardItems);
  }

  // Else (Chrome, Edge, etc)
  return drawSVGGridOnCanvas(imageBlobs, labels).then((gridBlob) => {
    const clipboardItem = new ClipboardItem({
      "image/png": gridBlob,
    });

    return navigator.clipboard.write([clipboardItem]);
  });
}

export function copyImage(imageBlob, label) {
  return copyImages([imageBlob], [label]);
}

export function downloadImages(imageBlobs, labels) {
  return drawSVGGridOnCanvas(imageBlobs, labels).then((gridBlob) => {
    const timestamp = new Date().toISOString();
    saveAs(gridBlob, `miTNM-${timestamp}.png`, {
      type: "image/png;charset=utf-8",
    });
  });
}
