import { DateTime } from "luxon";
import {
  ClinicalIndication,
  ClinicalState,
  ImagingModality,
  Radiopharmaceutical,
  Radiotracer,
  RestagingCategory,
  ScanType,
  TherapeuticRadiopharmaceutical,
} from "../constants/enums";
import { parseDate } from "../utils/dateUtils";
import { APIError } from "../utils/errors";
import { Product } from "./orgs";

export interface Uptake {
  suv_mean: number;
  suv_max: number;
}

export interface PatientScan {
  id: number | null;
  patient_id: string | null;
  date: DateTime | null;
  clinical_indication: ClinicalIndication;
  restaging_category: RestagingCategory | null;
  prostate_removed: boolean;
  location: string | null;
  scanner_type: string | null;
  scanner_model: string | null;
  reconstruction_method: string | null;
  contrast: string | null;
  radiotracer: Radiotracer | null;
  radiopharmaceutical: Radiopharmaceutical | null;
  injected_activity: number | null;
  injected_time: number | null;
  image_version: string | null;
  marker_info: PatientScanMarkerInfo;
  created_at: DateTime | null;
  updated_at: DateTime | null;
  modality: ImagingModality | null;
  metadata: PatientScanMetadata;
  created_by_id: number | null;
  diuretics: string | null;
  scan_type: ScanType;
  clinical_state: ClinicalState | null;
  liver_suv_max: number | null;
  tumor_suv_max: number | null;
  psma_negative_soft_tissue_lesions: string | null;
  has_psma_negative_soft_tissue_lesions: boolean | null;
  therapeutic_radiopharmaceutical: TherapeuticRadiopharmaceutical | null;
  psma_negative_soft_tissue_lesions_suv_max: number | null;
  normal_uptake: { [category: string]: Uptake };
  tumor_uptake: { [category: string]: Uptake };
  tumor_volume: { [category: string]: number };
}

export interface TumorMarkerInfo {
  markers: { [key: string]: Coordinates | EllipseCoordinates };
  marker_count_by_region: { [key: string]: number };
  markers_by_label: string[][];
  extraprostatic_involvement: string;
}

export interface BoneMarkerInfo {
  markers: { [key: string]: Coordinates };
  marker_count: number;
  marker_count_by_region: { [key: string]: number };
  is_dmi: boolean;
}

export interface PelvicMarkerInfo {
  markers: { [key: string]: Coordinates };
  marker_count_by_region: { [key: string]: number };
  are_other_nodes_involved: boolean;
  other_nodes_involvement: string;
}

export interface OtherMarkerInfo {
  markers: { [key: string]: Coordinates };
  marker_count_by_organ: { [key: string]: number };
  marker_count_by_lymph_node: { [key: string]: number };
  are_other_organs_involved: boolean;
  other_organ_involvement: string;
}

export interface PatientScanMarkerInfo {
  pelvic_lymph_node_metastases: PelvicMarkerInfo;
  prostate_tumor: TumorMarkerInfo;
  bone_metastases: BoneMarkerInfo;
  other_organ_metastases: OtherMarkerInfo;
}

export interface PatientScanMetadata {
  firstScanId?: number;
  firstScanRadiotracer?: string;
  secondScanId?: number;
  secondScanRadiotracer?: string;
}

export interface Coordinates {
  x: number;
  y: number;
  color?: string;
  suvMax?: number;
}

export interface EllipseCoordinates {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color?: string;
  suvMax?: number;
}

export function initialMarkerInfo(): PatientScanMarkerInfo {
  return {
    prostate_tumor: {
      markers: {},
      marker_count_by_region: {},
      markers_by_label: [[]],
      extraprostatic_involvement: "",
    },
    bone_metastases: {
      markers: {},
      marker_count: 0,
      marker_count_by_region: {},
      is_dmi: false,
    },
    pelvic_lymph_node_metastases: {
      markers: {},
      marker_count_by_region: {},
      are_other_nodes_involved: false,
      other_nodes_involvement: "",
    },
    other_organ_metastases: {
      markers: {},
      marker_count_by_lymph_node: {},
      marker_count_by_organ: {},
      are_other_organs_involved: false,
      other_organ_involvement: "",
    },
  };
}

function serializeToTumorMarkerInfo(prostate_tumor: any): TumorMarkerInfo {
  const emptyTumorMarkerInfo = initialMarkerInfo().prostate_tumor;
  if (prostate_tumor == null) {
    return emptyTumorMarkerInfo;
  }
  return {
    markers: prostate_tumor.markers || emptyTumorMarkerInfo.markers,
    marker_count_by_region:
      prostate_tumor.marker_count_by_region ||
      emptyTumorMarkerInfo.marker_count_by_region,
    markers_by_label:
      prostate_tumor.markers_by_label || emptyTumorMarkerInfo.markers_by_label,
    extraprostatic_involvement:
      prostate_tumor.extraprostatic_involvement ||
      emptyTumorMarkerInfo.extraprostatic_involvement,
  };
}

function serializeToPelvicMarkerInfo(
  pelvic_lymph_node_metastases: any
): PelvicMarkerInfo {
  const emptyPelvicMarkerInfo =
    initialMarkerInfo().pelvic_lymph_node_metastases;
  if (pelvic_lymph_node_metastases == null) {
    return emptyPelvicMarkerInfo;
  }
  return {
    markers:
      pelvic_lymph_node_metastases.markers || emptyPelvicMarkerInfo.markers,
    marker_count_by_region:
      pelvic_lymph_node_metastases.marker_count_by_region ||
      emptyPelvicMarkerInfo.marker_count_by_region,
    are_other_nodes_involved:
      pelvic_lymph_node_metastases.are_other_nodes_involved ||
      emptyPelvicMarkerInfo.are_other_nodes_involved,
    other_nodes_involvement:
      pelvic_lymph_node_metastases.other_nodes_involvement ||
      emptyPelvicMarkerInfo.other_nodes_involvement,
  };
}

function serializeToBoneMarkerInfo(bone_metastases: any): BoneMarkerInfo {
  const emptyBoneMarkerInfo = initialMarkerInfo().bone_metastases;
  if (bone_metastases == null) {
    return emptyBoneMarkerInfo;
  }
  return {
    markers: bone_metastases.markers || emptyBoneMarkerInfo.markers,
    marker_count:
      bone_metastases.marker_count || emptyBoneMarkerInfo.marker_count,
    marker_count_by_region:
      bone_metastases.marker_count_by_region ||
      emptyBoneMarkerInfo.marker_count_by_region,
    is_dmi: bone_metastases.is_dmi || emptyBoneMarkerInfo.is_dmi,
  };
}

function serializeToOtherMarkerInfo(
  other_organ_metastases: any
): OtherMarkerInfo {
  const emptyOtherMarkerInfo = initialMarkerInfo().other_organ_metastases;
  if (other_organ_metastases == null) {
    return emptyOtherMarkerInfo;
  }
  return {
    markers: other_organ_metastases.markers || emptyOtherMarkerInfo.markers,
    marker_count_by_organ:
      other_organ_metastases.marker_count_by_organ ||
      emptyOtherMarkerInfo.marker_count_by_organ,
    marker_count_by_lymph_node:
      other_organ_metastases.marker_count_by_lymph_node ||
      emptyOtherMarkerInfo.marker_count_by_organ,
    are_other_organs_involved:
      other_organ_metastases.are_other_nodes_involved ||
      emptyOtherMarkerInfo.are_other_organs_involved,
    other_organ_involvement:
      other_organ_metastases.other_nodes_involvement ||
      emptyOtherMarkerInfo.other_organ_involvement,
  };
}

function serializeToPatientMarkerInfo(marker_info: any): PatientScanMarkerInfo {
  if (marker_info == null) {
    return initialMarkerInfo();
  }
  return {
    pelvic_lymph_node_metastases: serializeToPelvicMarkerInfo(
      marker_info.pelvic_lymph_node_metastases
    ),
    prostate_tumor: serializeToTumorMarkerInfo(marker_info.prostate_tumor),
    bone_metastases: serializeToBoneMarkerInfo(marker_info.bone_metastases),
    other_organ_metastases: serializeToOtherMarkerInfo(
      marker_info.other_organ_metastases
    ),
  };
}

function serializeToRestagingCategory(
  restagingCategory: number
): RestagingCategory | null {
  switch (restagingCategory) {
    case 0:
      return RestagingCategory.BIOCHEMICAL_FAILURE;
    case 1:
      return RestagingCategory.PSMA_THERAPY_ELIGIBILITY;
    case 2:
      return RestagingCategory.TREATMENT_RESPONSE_EVALUATION;
    case 3:
      return RestagingCategory.BASELINE_SCAN;
    case 4:
      return RestagingCategory.SCAN_COMPARISON_RISING_PSA;
    default:
      return null;
  }
}

function serializeFromRestagingCategory(
  restagingCategory: RestagingCategory | null
) {
  switch (restagingCategory) {
    case RestagingCategory.BIOCHEMICAL_FAILURE:
      return 0;
    case RestagingCategory.PSMA_THERAPY_ELIGIBILITY:
      return 1;
    case RestagingCategory.TREATMENT_RESPONSE_EVALUATION:
      return 2;
    case RestagingCategory.BASELINE_SCAN:
      return 3;
    case RestagingCategory.SCAN_COMPARISON_RISING_PSA:
      return 4;
    default:
      return null;
  }
}

export function initialPatientScan(
  clinical_indication: ClinicalIndication,
  prostate_removed: boolean,
  scan_type: ScanType
): PatientScan {
  return {
    id: null,
    patient_id: null,
    date: null,
    clinical_indication,
    restaging_category: null,
    prostate_removed,
    location: null,
    scanner_type: null,
    scanner_model: null,
    reconstruction_method: null,
    contrast: null,
    radiotracer: null,
    radiopharmaceutical: null,
    injected_activity: null,
    injected_time: null,
    image_version: "V1",
    marker_info: initialMarkerInfo(),
    metadata: {},
    created_at: null,
    updated_at: null,
    modality: null,
    created_by_id: null,
    diuretics: null,
    scan_type,
    clinical_state: null,
    liver_suv_max: null,
    tumor_suv_max: null,
    psma_negative_soft_tissue_lesions: null,
    has_psma_negative_soft_tissue_lesions: null,
    therapeutic_radiopharmaceutical: null,
    psma_negative_soft_tissue_lesions_suv_max: null,
    normal_uptake: {},
    tumor_uptake: {},
    tumor_volume: {},
  };
}

export function initialPatientScanForProduct(product: Product): PatientScan {
  return product === "research"
    ? {
        ...initialPatientScan(
          ClinicalIndication.RESTAGING,
          false,
          ScanType.REGULAR
        ),
        radiotracer: Radiotracer.PSMA,
        modality: ImagingModality.PET,
      }
    : initialPatientScan(ClinicalIndication.STAGING, false, ScanType.REGULAR);
}

export function serializeToPatientScan(jsonResponse: any): PatientScan {
  return {
    ...initialPatientScan(ClinicalIndication.STAGING, false, ScanType.REGULAR),
    ...jsonResponse,
    date: parseDate(jsonResponse.date),
    restaging_category: serializeToRestagingCategory(
      jsonResponse.restaging_category
    ),
    marker_info: serializeToPatientMarkerInfo(
      JSON.parse(jsonResponse.marker_info)
    ),
    created_at: parseDate(jsonResponse.created_at),
    updated_at: parseDate(jsonResponse.updated_at),
    metadata: JSON.parse(jsonResponse.metadata),
    normal_uptake: JSON.parse(jsonResponse.normal_uptake) || {},
    tumor_uptake: JSON.parse(jsonResponse.tumor_uptake) || {},
    tumor_volume: JSON.parse(jsonResponse.tumor_volume) || {},
  };
}

export function serializeFromPatientScan(ps: PatientScan): string {
  return JSON.stringify({
    ...ps,
    date: ps.date?.toISO(),
    restaging_category: serializeFromRestagingCategory(ps.restaging_category),
    marker_info: JSON.stringify(ps.marker_info),
    created_at: ps.created_at?.toISO(),
    updated_at: ps.updated_at?.toISO(),
    metadata: JSON.stringify(ps.metadata),
    normal_uptake: JSON.stringify(ps.normal_uptake),
    tumor_uptake: JSON.stringify(ps.tumor_uptake),
    tumor_volume: JSON.stringify(ps.tumor_volume),
  });
}

export async function createPatientScan(
  accessToken: string,
  patientId: number,
  ps: PatientScan
): Promise<any> {
  const response = await fetch(`/api/patients/${patientId}/scans`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromPatientScan(ps),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function updatePatientScan(
  accessToken: string,
  patientId: number,
  patientScanId: number,
  ps: PatientScan
): Promise<void> {
  const response = await fetch(
    `/api/patients/${patientId}/scans/${patientScanId}`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: serializeFromPatientScan(ps),
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }
}

export async function getPatientScansForPatient(
  accessToken: string,
  patientId: number
): Promise<PatientScan[]> {
  const response = await fetch(`/api/patients/${patientId}/scans`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse.map(serializeToPatientScan);
}

export async function getPatientScanById(
  accessToken: string,
  patientId: number,
  patientScanId: number
): Promise<PatientScan> {
  const response = await fetch(
    `/api/patients/${patientId}/scans/${patientScanId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} – ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToPatientScan(jsonResponse);
}
