import React from "react";
import { usePatientsContext } from "../../contexts/PatientsContext";
import { Row, Form, Col, Table } from "react-bootstrap";
import useRecipCriteria from "./useRecipCriteria";
import { usePatientScansContext } from "../../contexts/PatientScansContext";
import { usePatientRecipCriteriaContext } from "../../contexts/PatientRecipCriteriaContext";

export default function RecipCriteria() {
  const { currentPatient } = usePatientsContext();
  const { currentPatientScan } = usePatientScansContext();
  const {
    currentPatientScanRecipCriteria,
    setCurrentPatientScanRecipCriteria,
    saveCurrentPatientScanRecipCriteria,
  } = usePatientRecipCriteriaContext();

  const { responseEvaluation } = useRecipCriteria(
    currentPatientScan,
    currentPatientScanRecipCriteria
  );

  React.useEffect(() => {
    // Save recip criteria on page unload
    return () => {
      saveCurrentPatientScanRecipCriteria();
    };
  }, []);

  const recipCriteriaLabel = React.useMemo(() => {
    switch (responseEvaluation) {
      case "RECIP-CR":
        return "Complete Response (RECIP-CR)";
      case "RECIP-PR":
        return "Partial Response (RECIP-PR)";
      case "RECIP-SD":
        return "Stable Disease (RECIP-SD)";
      case "RECIP-PD":
        return "Progressive Disease (RECIP-PD)";
      case "Not Defined":
        return "Not Defined";
      default:
        console.warn("Unknown RECIP criteria label");
        return "Not Defined";
    }
  }, [responseEvaluation]);

  const recipCriteriaColor = React.useMemo(() => {
    switch (responseEvaluation) {
      case "RECIP-CR":
        return "text-indigo";
      case "RECIP-PR":
        return "text-success";
      case "RECIP-SD":
        return "text-info";
      case "RECIP-PD":
        return "text-danger";
      default:
        return "text-muted";
    }
  }, [responseEvaluation]);

  return (
    <>
      <div style={{ height: 85 }} className="d-flex">
        <h3 className="my-auto">RECIP Criteria</h3>
      </div>
      <Form style={{ marginTop: 12 }} className="mb-5">
        <Row>
          <Form.Group as={Col} className="mb-3">
            <Form.Label className="mb-0">
              Changes in PSMA-positive tumor volume (PSMA-VOL)
            </Form.Label>
            <Form.Select
              disabled={!currentPatient.id}
              value={
                currentPatientScanRecipCriteria.psma_positive_tumor_volume_change ||
                "n/a"
              }
              onChange={(e) => {
                setCurrentPatientScanRecipCriteria({
                  ...currentPatientScanRecipCriteria,
                  psma_positive_tumor_volume_change: e.target.value,
                  new_lesion_appearance:
                    e.target.value === "complete_response"
                      ? false
                      : currentPatientScanRecipCriteria.new_lesion_appearance,
                  new_lesion_location:
                    e.target.value === "complete_response"
                      ? null
                      : currentPatientScanRecipCriteria.new_lesion_location,
                });
              }}
            >
              <option disabled value="n/a">
                Select one of the options:
              </option>
              <option value="complete_response">
                Complete Response (absent of any PSMA-positive lesion)
              </option>
              <option value="partial_response">
                Partial Response (&ge;30% decline)
              </option>
              <option value="stable_disease">
                Stable Disease (&gt;30% decline or &lt;20% increase)
              </option>
              <option value="progressive_disease">
                Progressive disease (&ge;20% increase)
              </option>
            </Form.Select>
            <Form.Text>
              Training for visual evaluation of changes in tumor volume is
              freely available at{" "}
              <a
                href="https://recip-criteria.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://recip-criteria.com
              </a>
            </Form.Text>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="mb-0">
              Appearance of &ge;1 new lesion on the follow-up PSMA PET/CT?
            </Form.Label>
            <div style={{ marginTop: "6px", marginBottom: "7px" }}>
              <Form.Check
                label="Yes"
                disabled={
                  !currentPatient.id ||
                  currentPatientScanRecipCriteria.psma_positive_tumor_volume_change ===
                    "complete_response"
                }
                inline
                checked={
                  currentPatientScanRecipCriteria.new_lesion_appearance || false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setCurrentPatientScanRecipCriteria({
                      ...currentPatientScanRecipCriteria,
                      new_lesion_appearance: true,
                    });
                  }
                }}
                name="new_lesion_appearance"
                type="radio"
              />
              <Form.Check
                label="No"
                disabled={
                  !currentPatient.id ||
                  currentPatientScanRecipCriteria.psma_positive_tumor_volume_change ===
                    "complete_response"
                }
                inline
                checked={
                  !currentPatientScanRecipCriteria.new_lesion_appearance ||
                  false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setCurrentPatientScanRecipCriteria({
                      ...currentPatientScanRecipCriteria,
                      new_lesion_appearance: false,
                      new_lesion_location: null,
                    });
                  }
                }}
                name="new_lesion_appearance"
                type="radio"
              />
            </div>
          </Form.Group>
          {currentPatientScanRecipCriteria.new_lesion_appearance && (
            <Form.Group as={Col}>
              <Form.Label className="mb-0">New lesion location</Form.Label>
              <Form.Control
                type="text"
                disabled={!currentPatient.id}
                value={
                  currentPatientScanRecipCriteria.new_lesion_location || ""
                }
                onChange={(e) => {
                  setCurrentPatientScanRecipCriteria({
                    ...currentPatientScanRecipCriteria,
                    new_lesion_location: e.target.value,
                  });
                }}
              />
            </Form.Group>
          )}
        </Row>
      </Form>
      <Row className="mb-5">
        <Col>
          <h5>
            Response evaluation using PSMA PET/CT:{" "}
            <b className={recipCriteriaColor}>{recipCriteriaLabel}</b>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Appearance of &ge;1 new lesion on the follow-up PSMA-PET/CT is
            defined as:
          </p>
          <ul>
            <li>Any new focal uptake of PSMA-ligand:</li>
            <ul>
              <li>Higher than the surrounding background</li>
              <li>With tumor SUVmax &gt; blood-pool SUVmax</li>
              <li>
                Not present on the baseline scan (tumor SUVmax &lt; blood-pool
                SUVmax)
              </li>
              <li>
                With tumor uptake not attributable to physiological uptake or
                pitfalls
              </li>
            </ul>
            <li>
              Any new maligant lesion detected on the follow-up CT images
              independent of PSMA-ligand uptake
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <Table striped hover className="align-middle m-0">
            <tbody>
              <tr>
                <td>Complete Response</td>
                <td style={{ minWidth: 100 }}>
                  <b>RECIP-CR</b>
                </td>
                <td>Absense of any PSMA-update on follow-up PET scan</td>
              </tr>
              <tr>
                <td>Partial Response</td>
                <td>
                  <b>RECIP-PR</b>
                </td>
                <td>
                  &#8805;30% decrease in PSMA-VOL without appearance of new
                  lesions
                </td>
              </tr>
              <tr>
                <td>Progressive Disease</td>
                <td>
                  <b>RECIP-PD</b>
                </td>
                <td>
                  &#8805;20% increase in PSMA-VOL with appearance of new lesions
                </td>
              </tr>
              <tr>
                <td>Stable Disease</td>
                <td>
                  <b>RECIP-SD</b>
                </td>
                <td>
                  &lt;30% decrease in PSMA-VOL with/without appearance of new
                  lesions <i>or</i>
                  <br />
                  &#8805;30% decrease in PSMA-VOL with appearance of new lesions{" "}
                  <i>or</i>
                  <br />
                  &lt;20% increase in PSMA-VOL with/without appearance of new
                  lesions <i>or</i>
                  <br />
                  &#8805;20% increase in PSMA-VOL without appearance of new
                  lesions
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
