import {
  OTHER_LYMPH_NODE_NAMES,
  shortRegionName,
} from "../../../constants/regionNames";
import { OtherMarkerInfo, PelvicMarkerInfo } from "../../../api/patientScans";
import { handleOrientation } from "../../../utils/regionNameUtils";

export function getOtherLymphNodeTNMCode(
  pelvicMarkerInfo: PelvicMarkerInfo,
  otherMarkerInfo: OtherMarkerInfo
): [string, string] {
  const { marker_count_by_region: pelvicMetastasesCount } = pelvicMarkerInfo;
  const { marker_count_by_lymph_node: otherMetastasesCount } = otherMarkerInfo;

  const filteredMetastasesCountByNode = Object.keys(pelvicMetastasesCount)
    .concat(Object.keys(otherMetastasesCount))
    .filter((key) => OTHER_LYMPH_NODE_NAMES.has(key));

  return filteredMetastasesCountByNode.length === 0
    ? ["", ""]
    : [
        "M1a",
        `M1a (${filteredMetastasesCountByNode.map(shortRegionName).join(",")})`,
      ];
}

export function getOtherOrganTNMCode(
  markerInfo: OtherMarkerInfo
): [string, string] {
  const {
    marker_count_by_organ,
    are_other_organs_involved,
    other_organ_involvement,
  } = markerInfo;

  const affectedOrgans = Object.keys(marker_count_by_organ).map(
    shortRegionName
  );
  if (are_other_organs_involved) {
    affectedOrgans.push(`Other – ${other_organ_involvement || "?"}`);
  }

  return affectedOrgans.length === 0
    ? ["", ""]
    : ["M1c", `M1c (${handleOrientation(affectedOrgans)})`];
}
