import * as React from "react";
import { TNMClassifications } from "../constants/enums";
import { useAuthContext } from "./AuthContext";

const InitialState = {
  showIntro: true,
  TNMClassification: TNMClassifications.AJCC_TNM,
};

type AppSettingsContextType = {
  showIntro: boolean;
  TNMClassification: TNMClassifications;
  setShowIntro: (showIntro: boolean) => void;
  setTNMClassification: (TNMClassification: TNMClassifications) => void;
};

const AppSettingsContext = React.createContext<AppSettingsContextType>({
  showIntro: InitialState.showIntro,
  TNMClassification: InitialState.TNMClassification,
  setShowIntro: () => {},
  setTNMClassification: () => {},
});

interface AppSettingsContextProviderProps {
  children?: React.ReactNode;
}

export function AppSettingsContextProvider({
  children,
}: AppSettingsContextProviderProps) {
  const { currentUser } = useAuthContext();

  // TODO: Fetch settings from the backend API
  const showIntroKey = React.useMemo(
    () =>
      currentUser ? `${currentUser.username}:settings.showIntro` : undefined,
    [currentUser]
  );
  const tnmClassificationKey = React.useMemo(
    () =>
      currentUser
        ? `${currentUser.username}:settings.TNMClassification`
        : undefined,
    [currentUser]
  );

  const [showIntro, setShowIntro_internal] = React.useState<boolean>(
    InitialState.showIntro
  );
  const [TNMClassification, setTNMClassification_internal] =
    React.useState<TNMClassifications>(InitialState.TNMClassification);

  React.useEffect(() => {
    if (showIntroKey === undefined || tnmClassificationKey === undefined) {
      setShowIntro_internal(InitialState.showIntro);
      setTNMClassification_internal(InitialState.TNMClassification);
      return;
    }

    // On initial render, try to retrieve settings from local storage.
    const localShowIntro = window.localStorage.getItem(showIntroKey);
    const localTNMClassification =
      window.localStorage.getItem(tnmClassificationKey);

    // If values are not available, set the local storage value to default
    if (localShowIntro === null) {
      setShowIntro(true);
    } else {
      setShowIntro_internal(localShowIntro === "true");
    }

    if (localTNMClassification === null) {
      setTNMClassification(TNMClassifications.AJCC_TNM);
    } else {
      setTNMClassification(localTNMClassification as TNMClassifications);
    }
  }, [showIntroKey, tnmClassificationKey]);

  function setShowIntro(value: boolean) {
    if (showIntroKey === undefined) return;
    window.localStorage.setItem(showIntroKey, value.toString());
    setShowIntro_internal(value);
  }

  function setTNMClassification(value: TNMClassifications) {
    if (tnmClassificationKey === undefined) return;
    window.localStorage.setItem(tnmClassificationKey, value);
    setTNMClassification_internal(value);
  }

  return (
    <AppSettingsContext.Provider
      value={{
        showIntro,
        setShowIntro,
        TNMClassification,
        setTNMClassification,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
}

export function useAppSettingsContext() {
  return React.useContext(AppSettingsContext);
}
