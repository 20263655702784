import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface SummaryProps {
  prostateImageSnapshot: Blob;
  pelvicImageSnapshot: Blob;
  boneImageSnapshot: Blob;
  otherOrganImageSnapshot: Blob;
  prostateImageEmpty: boolean;
  pelvicImageEmpty: boolean;
  boneImageEmpty: boolean;
  otherOrganImageEmpty: boolean;
}

function Summary({
  prostateImageSnapshot,
  pelvicImageSnapshot,
  boneImageSnapshot,
  otherOrganImageSnapshot,
  prostateImageEmpty,
  pelvicImageEmpty,
  boneImageEmpty,
  otherOrganImageEmpty,
}: SummaryProps) {
  let imageCount = 0;
  if (!prostateImageEmpty) {
    imageCount += 1;
  }
  if (!pelvicImageEmpty) {
    imageCount += 1;
  }
  if (!boneImageEmpty) {
    imageCount += 1;
  }
  if (!otherOrganImageEmpty) {
    imageCount += 1;
  }

  const maxImageHeight = imageCount > 2 ? "38.175vh" : "77vh";

  return (
    <Row
      className="d-flex justify-content-evenly justify-content-lg-center align-items-center"
      style={{ minHeight: "82vh" }}
    >
      {imageCount === 0 && (
        <Col className="text-center px-0">
          <p className="mb-0">No changes</p>
        </Col>
      )}
      {!prostateImageEmpty && (
        <Col md={6} className="text-center px-0">
          <p className="mb-0">Local Lesions</p>
          <img
            className="img-fluid"
            alt="Prostate Tumor Snapshot"
            id="Review-prostateImageSnapshot"
            src={URL.createObjectURL(prostateImageSnapshot)}
            style={{ maxHeight: maxImageHeight }}
          />
        </Col>
      )}
      {!pelvicImageEmpty && (
        <Col md={6} className="text-center px-0">
          <p className="mb-0">Pelvic and Inguinal LN</p>
          <img
            className="img-fluid"
            alt="Pelvic Metastases Snapshot"
            id="Review-pelvicLymphNodeMetastasesSnapshot"
            src={URL.createObjectURL(pelvicImageSnapshot)}
            style={{ maxHeight: maxImageHeight }}
          />
        </Col>
      )}
      {!boneImageEmpty && (
        <Col md={6} className="text-center px-0">
          <p className="mb-0">Osseous Metastases</p>
          <img
            className="img-fluid"
            alt="Bone Metastases Snapshot"
            id="Review-boneMetastasesSnapshot"
            src={URL.createObjectURL(boneImageSnapshot)}
            style={{ maxHeight: maxImageHeight }}
          />
        </Col>
      )}
      {!otherOrganImageEmpty && (
        <Col md={6} className="text-center px-0">
          <p className="mb-0">Distant LN {"&"} Organs</p>
          <img
            className="img-fluid"
            alt="Other Organ Metastases Snapshot"
            id="Review-OtherOrganMetastasesSnapshot"
            src={URL.createObjectURL(otherOrganImageSnapshot)}
            style={{ maxHeight: maxImageHeight }}
          />
        </Col>
      )}
    </Row>
  );
}

export default Summary;
