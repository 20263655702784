import * as React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";

import AuthLayout from "../AuthLayout";
import { AuthError } from "../../utils/errors";
import { requestPasswordReset } from "../../api/auth";

function RequestPasswordResetPage() {
  const { setLastUserId } = useAuthContext();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await requestPasswordReset(email);
      setLastUserId(email);
      navigate("/reset_password/confirm");
    } catch (error) {
      if (error instanceof AuthError) {
        setErrorMessage(error.details);
      }
      console.error(error.message);
    }
  }

  const body = (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="email" className="mb-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          autoFocus={true}
          autoComplete="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          isInvalid={!!errorMessage}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100">
        Send me reset password instructions
      </Button>
    </Form>
  );

  const links = (
    <React.Fragment>
      <Link to="/sign_in">Sign in</Link>
      <Link to="/sign_up">Sign up</Link>
      <Link to="/sign_up/resend_confirmation_email">
        Didn't receive confirmation instructions?
      </Link>
    </React.Fragment>
  );

  return (
    <AuthLayout title="Forgot your password?" body={body} footer={links} />
  );
}

export default RequestPasswordResetPage;
