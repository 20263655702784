import * as pdfMake from "pdfmake/build/pdfmake";
import { blobToDataURL } from "./imageUtils";

import poppinsMedium from "../fonts/Poppins-Medium.ttf";
import poppinsMediumItalic from "../fonts/Poppins-MediumItalic.ttf";
import poppinsSemiBold from "../fonts/Poppins-SemiBold.ttf";
import poppinsSemiBoldItalic from "../fonts/Poppins-SemiBoldItalic.ttf";
import sourceSerifPro from "../fonts/SourceSerifPro-Regular.ttf";
import sourceSerifProItalic from "../fonts/SourceSerifPro-Italic.ttf";
import {
  ContentText,
  Content,
  Style,
  StyleDictionary,
  TDocumentDefinitions,
} from "pdfmake/interfaces";

function generateReport(
  logoPNGBlob: Blob,
  imageSnapshots: Blob,
  clinicalInformation: string,
  technicalInformation: string,
  oncologicalFindings: [string, string, string],
  descriptiveFindings: [string, string, string, string],
  luPSMACriteriaApplicable: boolean,
  luPSMACriteriaTherapeuticRadiopharmaceutical: string,
  luPSMACriteriaReportText: string,
  recipCriteriaApplicable: boolean,
  recipCriteriaReportText: string,
  recipCriteriaReference: string,
  tnmCodeLine: string
): Promise<pdfMake.TCreatedPdf> {
  return Promise.all(
    [logoPNGBlob, imageSnapshots].map((imageBlob) => blobToDataURL(imageBlob))
  ).then(([logo, image]) => {
    let content: Content = [
      {
        image: logo,
        height: 50,
        width: 99.55,
      },
      {
        text: "Clinical history",
        style: ["header"],
      },
      {
        text: clinicalInformation,
      } as ContentText,
      {
        text: "Technical information",
        style: ["header"],
      },
      { text: technicalInformation } as ContentText,

      {
        text: "Oncological findings",
        style: ["header"],
      },
      ...oncologicalFindings,
      {
        text: "Descriptive findings",
        style: ["header"],
      },
      {
        text: "Head and neck",
        style: ["subHeader"],
      },
      descriptiveFindings[0],
      {
        text: "Chest",
        style: ["subHeader"],
      },
      descriptiveFindings[1],
      {
        text: "Abdomen and Pelvis",
        style: ["subHeader"],
      },
      descriptiveFindings[2],
      {
        text: "Muscoskeletal",
        style: ["subHeader"],
      },
      descriptiveFindings[3],
    ];

    if (luPSMACriteriaApplicable) {
      content = content.concat([
        {
          text: `Eligibility for ${luPSMACriteriaTherapeuticRadiopharmaceutical} targeted therapy`,
          style: ["header"],
        },
        luPSMACriteriaReportText,
        {
          text: "Reference: Sartor O, de Bono J, Chi KN, Fizazi K, Herrmann K, Rahbar K, et al. Lutetium-177-PSMA-617 for Metastatic Castration-Resistant Prostate Cancer. NEJM. 2021;385:1091-103.",
          style: ["small"],
        },
      ]);
    }

    if (recipCriteriaApplicable && recipCriteriaReportText !== "") {
      content = content.concat([
        { text: "Treatment response evaluation", style: ["header"] },
        recipCriteriaReportText,
        {
          text: recipCriteriaReference,
          style: ["small"],
        },
      ]);
    }

    content = content.concat([
      {
        text: "miTNM codeline",
        style: ["header"],
      },
      { text: tnmCodeLine } as ContentText,
      {
        image: image,
        width: 514,
      },
      {
        text: "TNM classification according to PROMISE V2 guideline: Seifert R, Emmett L, Rowe SP, et al. Second Version of the Prostate Cancer Molecular Imaging Standardized Evaluation Framework Including Response Evaluation for Clinical Trials (PROMISE V2). European Urology. 2023 May;83(5):405-412.",
        style: ["small"],
      },
    ]);

    const reportDefinition: TDocumentDefinitions = {
      info: {
        title: "TNM Stager Report",
      },
      content,
      styles: {
        header: {
          font: "Poppins",
          fontSize: 18,
          bold: true,
          margin: [0, 25, 0, 5],
        },
        subHeader: {
          font: "Poppins",
          fontSize: 16,
          bold: true,
        },
        small: {
          fontSize: 10,
        },
      } as StyleDictionary,
      defaultStyle: {
        fontSize: 14,
        alignment: "justify",
        font: "SourceSerifPro",
      } as Style,
    };

    return pdfMake.createPdf(reportDefinition, undefined, {
      Poppins: {
        normal: new URL(poppinsMedium, document.baseURI).href,
        italics: new URL(poppinsMediumItalic, document.baseURI).href,
        bold: new URL(poppinsSemiBold, document.baseURI).href,
        bolditalics: new URL(poppinsSemiBoldItalic, document.baseURI).href,
      },
      SourceSerifPro: {
        normal: new URL(sourceSerifPro, document.baseURI).href,
        italics: new URL(sourceSerifProItalic, document.baseURI).href,
      },
    });
  });
}

export default generateReport;
