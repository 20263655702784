import * as React from "react";
import PropTypes from "prop-types";
import { getApplicationKeyMap } from "react-hotkeys";

import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

function KeyMapModal({ showModal, onModalHide }) {
  const keyMap = getApplicationKeyMap();

  return (
    <Modal size="lg" show={showModal} onHide={onModalHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Key Bindings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table size="sm" hover>
          <tbody>
            {Object.keys(keyMap).map((actionName) => {
              const { sequences, name } = keyMap[actionName];

              return (
                <tr key={name || actionName}>
                  <td>
                    {sequences
                      .map(({ sequence }) => (
                        <kbd key={sequence}>
                          {sequence
                            .toUpperCase()
                            .replace("LEFT", "←")
                            .replace("RIGHT", "→")}
                        </kbd>
                      ))
                      .reduce((prev, cur) => [prev, " , ", cur])}
                  </td>
                  <td>{name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

KeyMapModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onModalHide: PropTypes.func.isRequired,
};

export default KeyMapModal;
