import { Coordinates, EllipseCoordinates } from "../api/patientScans";
import regionName from "../constants/regionNames";

// @ts-ignore Figure out the typings later
export default function findAffectedAreaNode(tries: number, target: any) {
  if (tries === 0) {
    return [false, undefined];
  }
  if (
    target.id &&
    regionName(target.id) &&
    regionName(target.id) !== "Click to remove marker"
  ) {
    return [true, target.id];
  }
  return findAffectedAreaNode(tries - 1, target.parentNode);
}

// @ts-ignore Figure out the typings later
export function findMarker(tries: number, target: any) {
  if (tries === 0) {
    return [false, undefined];
  }
  if (target.id.endsWith("-label")) {
    const correctedTargetId = target.id.split("-label")[0];
    if (regionName(correctedTargetId) === "Click to remove marker") {
      return [true, correctedTargetId];
    }
  }
  if (regionName(target.id) === "Click to remove marker") {
    return [true, target.id];
  }
  return target.parentNode != null
    ? findMarker(tries - 1, target.parentNode)
    : [false, undefined];
}

export function suvMaxByRegion(markers: {
  [key: string]: Coordinates | EllipseCoordinates;
}) {
  let result: { [key: string]: number[] } = {};
  for (let markerId in markers) {
    const regionId = markerId.split("*")[0];
    if (markers[markerId].suvMax != null) {
      if (result.hasOwnProperty(regionId)) {
        result[regionId].push(markers[markerId].suvMax!);
      } else {
        result[regionId] = [markers[markerId].suvMax!];
      }
    }
  }
  return result;
}
