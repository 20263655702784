import { shortRegionName } from "../../../constants/regionNames";
import { PELVIC_LYMPH_NODE_NAMES } from "../../../constants/regionNames";
import { TNMClassifications } from "../../../constants/enums";
import { PelvicMarkerInfo } from "../../../api/patientScans";

export default function getPelvicLymphNodeTNMCode(
  tnmClassification: TNMClassifications,
  markerInfo: PelvicMarkerInfo
): [string, string] {
  const {
    marker_count_by_region,
    are_other_nodes_involved,
    other_nodes_involvement,
  } = markerInfo;

  const filteredMetastasesCountByNode: { [key: string]: number } = {};
  for (const key in marker_count_by_region) {
    if (PELVIC_LYMPH_NODE_NAMES.has(key)) {
      filteredMetastasesCountByNode[key] = marker_count_by_region[key];
    }
  }

  const affectedRegions: string[] = Object.keys(
    filteredMetastasesCountByNode
  ).map(shortRegionName);
  if (are_other_nodes_involved) {
    affectedRegions.push(`Other – ${other_nodes_involvement || "?"}`);
  }

  let newCode = "N0";
  switch (affectedRegions.length) {
    case 0:
      newCode = "N0";
      break;
    case 1:
      newCode = "N1";
      break;
    default:
      if (tnmClassification === TNMClassifications.PROMISE_MITNM_V2) {
        newCode = "N2";
      } else if (tnmClassification === TNMClassifications.AJCC_TNM) {
        newCode = "N1";
      }
  }

  return [
    newCode,
    affectedRegions.length > 0
      ? `${newCode} (${affectedRegions.join(",")})`
      : newCode,
  ];
}
