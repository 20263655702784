import { DateTime } from "luxon";
import { PatientScan } from "../api/patientScans";

function regularScanLabel(patientScan: PatientScan) {
  let label = patientScan.date
    ? patientScan.date.toLocaleString(DateTime.DATE_SHORT)
    : patientScan.created_at?.toLocaleString(DateTime.DATE_SHORT) ||
      `#${patientScan.id || "Unknown"}`;

  if (patientScan.radiotracer || patientScan.modality) {
    const descriptionItems = [];
    if (patientScan.radiotracer) {
      descriptionItems.push(patientScan.radiotracer);
    }
    if (patientScan.modality) {
      descriptionItems.push(patientScan.modality);
    }
    label += `(${descriptionItems.join(" ")})`;
  }

  return label;
}

function discrepancyScanLabel(patientScan: PatientScan) {
  return `${patientScan.metadata.firstScanRadiotracer}-${
    patientScan.metadata.secondScanRadiotracer
  } discrepancy - ${
    patientScan.created_at?.toLocaleString(DateTime.DATE_SHORT) || "N/A"
  }`;
}

export function scanLabel(patientScan: PatientScan) {
  return patientScan.scan_type === "regular"
    ? regularScanLabel(patientScan)
    : discrepancyScanLabel(patientScan);
}

export function clinicalPatientScanLabel(patientScan: PatientScan) {
  let label = patientScan.date
    ? patientScan.date.toLocaleString(DateTime.DATE_SHORT)
    : patientScan.created_at?.toLocaleString(DateTime.DATE_SHORT) ||
      `#${patientScan.id || "Unknown"}`;

  if (patientScan.radiopharmaceutical || patientScan.modality) {
    const descriptionItems = [];
    if (patientScan.radiopharmaceutical) {
      descriptionItems.push(patientScan.radiopharmaceutical);
    }
    if (patientScan.modality) {
      descriptionItems.push(patientScan.modality);
    }
    label += ` (${descriptionItems.join(" ")})`;
  }

  return label;
}
