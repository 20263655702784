import { APIError } from "../utils/errors";

export interface PatientRecipCriteria {
  id: number | null;
  psma_positive_tumor_volume_change: string | null;
  new_lesion_appearance: boolean | null;
  new_lesion_location: string | null;
}

export function initialRecipCriteria() {
  return {
    id: null,
    psma_positive_tumor_volume_change: null,
    new_lesion_appearance: null,
    new_lesion_location: null,
  };
}

export function serializeToRecipCriteria(jsonResponse: any) {
  return {
    ...jsonResponse,
  };
}

export function serializeFromRecipCriteria(criteria: PatientRecipCriteria) {
  return JSON.stringify({
    ...criteria,
  });
}

export async function createPatientRecipCriteria(
  accessToken: string,
  patientId: number,
  criteria: PatientRecipCriteria
): Promise<any> {
  const response = await fetch(`/api/patients/${patientId}/recip_criteria`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromRecipCriteria(criteria),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return jsonResponse;
}

export async function updatePatientRecipCriteria(
  accessToken: string,
  patientId: number,
  criteria: PatientRecipCriteria
): Promise<void> {
  const response = await fetch(`/api/patients/${patientId}/recip_criteria`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: serializeFromRecipCriteria(criteria),
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }
}

export async function getPatientRecipCriteria(
  accessToken: string,
  patientId: number
): Promise<PatientRecipCriteria> {
  const response = await fetch(`/api/patients/${patientId}/recip_criteria`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  const jsonResponse = await response.json();

  if (!response.ok) {
    throw new APIError(`${response.status} - ${JSON.stringify(jsonResponse)}`);
  }

  return serializeToRecipCriteria(jsonResponse);
}
